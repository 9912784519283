import { useEffect } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { ERROR_CODE } from '../../constant/errorCode';
import { ERROR_MESSAGE } from '../../constant/message';
import useError from '../../hooks/useError/useError';
import useErrorPage from '../../hooks/useErrorPage/useErrorPage';

interface IErrorBoundaryWrapperProps {
  children: JSX.Element;
}
export const ErrorBoundaryWrapper = ({ children }: IErrorBoundaryWrapperProps) => {
  const { goErrorPage } = useErrorPage();
  const { sendErrorLog } = useError();
  // FIXME 関数分離
  const ErrorFallback = ({ error }: FallbackProps) => {
    useEffect(() => {
      goErrorPage({ errorCode: ERROR_CODE.E_9000 });
    }, []);
    return <></>;
  };
  const logError = (
    error: Error,
    info: {
      componentStack: string;
    },
  ) => {
    sendErrorLog({
      error: `${error}\n'${info.componentStack}`,
      errorCode: ERROR_CODE.E_9000,
      message: ERROR_MESSAGE.E_9000.ja,
    });
  };
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
      {children}
    </ErrorBoundary>
  );
};
export const ErrorPage = ({ errorCode, error, message }: { errorCode: string; error: any; message?: string }) => {
  const { goErrorPage } = useErrorPage();
  const { sendErrorLog } = useError();
  useEffect(() => {
    const handleError = async () => {
      await sendErrorLog({
        error,
        errorCode,
        message: message ?? ERROR_MESSAGE.E_9001.ja,
      });
      await goErrorPage({ errorCode });
    };
    handleError();
  }, [errorCode]);
  return <></>;
};
