export const FormattedLessonData = (data: any) => {
  // 参加学習者を整理して付与
  for (let i = 0; i < data.length; i++) {
    let learnerNames = '';
    let learnerLength = data[i].t_lesson_learner.length;

    // レッスン日、時間を表示用に整形
    data[i]['lesson_day'] = getFormattedDate(data[i]?.start_datetime);
    data[i]['start_time'] = getFormattedTime(data[i]?.start_datetime);
    data[i]['end_time'] = getFormattedTime(data[i]?.end_datetime);

    for (let j = 0; j < learnerLength; j++) {
      if (j == 3 && learnerLength > 3) {
        // 3人以上はその他で省略
        learnerNames += ' 他';
        break;
      } else {
        learnerNames += data[i].t_lesson_learner[j].m_learner.nick_name;
      }

      if (j < 2 && j < learnerLength - 1) {
        learnerNames += ', ';
      }

      data[i].t_lesson_learner[j].feed_back =
        data[i].t_lesson_learner[j].feed_back == null ? '' : data[i].t_lesson_learner[j].feed_back;
    }
    data[i]['learner_names'] = learnerNames;
    data[i].note = data[i].note == null ? '' : data[i].note;
  }
  return data;
};

export const dayName = ['日', '月', '火', '水', '木', '金', '土'];

// 日付を調整する
export const getFormattedDate = (datetimeStr: string) => {
  if(!datetimeStr) return "";
  let datetime = new Date(datetimeStr);
  let adjustedDate = getAdjustedDate(datetime);

  return adjustedDate;
};

export const getFormattedTime = (datetimeStr: string) => {
  if(!datetimeStr) return "";
  let datetime = new Date(datetimeStr);
  let adjustedtime = getAdjustedTime(datetime);

  return adjustedtime;
};

export const getFormattedDatetime = (datetimeStr: string) => {
  if(!datetimeStr) return "";
  let datetime = new Date(datetimeStr);
  let adjustedDate = getAdjustedDate(datetime);

  let adjustedtime = getAdjustedTime(datetime);

  return adjustedDate + ' ' + adjustedtime;
};

export const getFormattedNumberWithCommas = (val: number) => {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const getAdjustedDate = (datetime: Date) => {
  let adjustedDate =
    datetime.getFullYear() +
    '-' +
    ('00' + (datetime.getMonth() + 1)).slice(-2) +
    '-' +
    ('00' + datetime.getDate()).slice(-2);

  return adjustedDate;
};

const getAdjustedTime = (datetime: Date) => {
  let adjustedTime = ('00' + datetime.getHours()).slice(-2) + ':' + ('00' + datetime.getMinutes()).slice(-2);
  return adjustedTime;
};

export const getFormattedYearMonth = (dateStr: string) => {
  var adjustedDate: string = dateStr !== undefined ? `${dateStr.substring(0, 4)}年${dateStr.substring(4, 6)}月` : '';
  return adjustedDate;
};

export const getHoursAndMinutes = (totalMinutes: number) => {
  if (totalMinutes < 60) {
    return `00 : ${totalMinutes}`;
  }
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const time = `${hours} :` + (minutes > 10 ? `${minutes}` : ` 0${minutes}`);
  return time;
};

export const getFormattedMonthDatetime = (datetimeString: string, systemLanguage: string) => {
  try {
    const date = new Date(datetimeString);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid datetime string');
    }
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    if (systemLanguage === 'ja') {
      return new Intl.DateTimeFormat('ja-JP', options).format(date);
    } else {
      return new Intl.DateTimeFormat('en-US', options).format(date);
    }
  } catch (error) {
    console.error('Error formatting datetime:', error);
    return 'Invalid datetime';
  }
};
