import './index.scss';
import { Button, Flex, Image, Stack, Select, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ASSETS_IMG } from '../../assets/assets';
import {localStorageKeys} from '../../constant/localStorage';

const SelectLang = () => {
  //assets
  const kurashi_study_logo = ASSETS_IMG.logo01_tate;
  const system_words: any = {};
  system_words['ja'] = require('../../assets/jsons/lang/ja.json');
  system_words['en'] = require('../../assets/jsons/lang/en.json');
  system_words['vn'] = require('../../assets/jsons/lang/vn.json');

  //states
  const [selectedLang, setSelectedLang] = useState<string>('ja');

  const setLang = (lng: string) => {
    console.log(lng);
    setSelectedLang(lng);
  };

  const submitToAgree = () => {
    localStorage.setItem(localStorageKeys.systemLanguage, selectedLang);
    // 翻訳アイコンで表示する言語の設定(日本語選択時は英語)
    localStorage.setItem(
      localStorageKeys.translationLanguage,
      selectedLang == 'ja' ? 'en' : selectedLang == 'vn' ? 'vn' : selectedLang,
    );
    window.location.replace('/agree');
  };

  useEffect(() => {
    if (localStorage.hasOwnProperty(localStorageKeys.systemLanguage)) {
      setSelectedLang(String(localStorage.getItem(localStorageKeys.systemLanguage)));
    }
  }, []);

  return (
    <>
      <Stack
        id="bg-image"
        pb="5px"
        m="auto"
        w="414px"
        bgSize="cover"
        boxSizing="content-box"
        bgColor="white"
        justify="center"
      >
        <Flex justifyContent="center" height="60%">
          <Image id="logo-image" src={kurashi_study_logo} px="20px" mt="60px" mb="20px" />
        </Flex>
        <Flex textAlign="center" justifyContent="center" px="50px" pb="20px">
          <Text fontSize="24px">{system_words[selectedLang]['KT_0001_01']}</Text>
        </Flex>
        <Flex id="lang_dw" justifyContent="center" margin="5px" pb="20px">
          <form>
            <Select
              id="select_lang"
              size="md"
              minWidth="300px"
              onChange={(event) => setLang(event.currentTarget.value)}
            >
              <option value="ja" selected={selectedLang == 'ja' ? true : false}>
                日本語
              </option>
              <option value="en" selected={selectedLang == 'en' ? true : false}>
                English
              </option>
              <option value="vn" selected={selectedLang == 'vn' ? true : false}>
                Tiếng Việt
              </option>
            </Select>
          </form>
        </Flex>
        <Flex justifyContent="center" margin="5px" pb="20px">
          <Button
            id="single_btn"
            className="btn-border"
            onClick={() => submitToAgree()}
            textColor="white"
            bgColor="orange"
            w="300px"
          >
            {system_words[selectedLang]['KT_0001_02']}
          </Button>
        </Flex>
        <Flex textAlign="center" justifyContent="center" px="50px" pb="20px">
          <Text fontSize="18px">{system_words[selectedLang]['KT_0001_03']}</Text>
        </Flex>
      </Stack>
    </>
  );
};

export default SelectLang;
