import { useHistory, useLocation } from 'react-router-dom';
import { BASE_URL, IS_DEV, REACT_APP_COGNITO_USER_POOL_CLIENT_ID } from '../../configs';
import { useCallback } from 'react';
import useError from '../useError/useError';
import { PATH_NAME } from '../../constant/location';
import { ERROR_CODE } from '../../constant/errorCode';
import { cognitoUtils } from '../../utils/auth/cognitoUtils';
import { cookieUtils } from '../../utils/storage/cookieUtils';
import { AxiosResponse } from 'axios';
import useSignOut from '../useSignout/useSignout';

const useErrorPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { sendErrorLog } = useError();
  const { handleSignOut } = useSignOut();
  const apiErrorHandler = useCallback(async (e: any, options: { componentName: string; functionName: string }) => {
    let errorCode = '500_Internal_Server_Error';
    let forUserDisplayErrorCode = 'E_500';
    const rtn: AxiosResponse = {
      data: [] as never,
      status: 0,
      statusText: '',
      headers: '' as never,
      config: '' as never,
    };
    if (e.message) {
      const message = e.message as string;
      if (message.match(/401/)) {
        errorCode = '401_Unauthorized';
        await handleSignOut();
      } else if (message.match(/404/)) {
        errorCode = '404_Not_Found';
      }
    }
    if (options.componentName === PATH_NAME.TOP) {
      forUserDisplayErrorCode = 'E_401';
    }
    goErrorPage({ errorCode: forUserDisplayErrorCode });
  }, []);
  const goErrorPage = useCallback(
    async ({ errorCode = 'E_9000' }: { errorCode: string }) => {
      let redirect = '';
      if (location.pathname === PATH_NAME.TOP) {
        sendErrorLog({
          error: '',
          errorCode: ERROR_CODE.HTTP_401,
          message: `${PATH_NAME.TOP}で異常発生。強制サインアウトします。`,
        });
        await handleSignOut();
      } else {
        if (!IS_DEV) {
          redirect = `${BASE_URL}?errorCode=${errorCode}`;
        }
      }

      document.location.href = redirect;
    },
    [BASE_URL],
  );
  return { goErrorPage, apiErrorHandler };
};
export default useErrorPage;
