import { Modal, ModalOverlay, ModalContent, ModalBody, VStack, Text, Heading } from '@chakra-ui/react';
import { getFormattedMonthDatetime } from '../../Format';
import { getDisplayLangData } from '../../json';
import { localStorageKeys } from '../../../constant/localStorage';

interface MaintenanceEntry {
  maintenance_title: string;
  maintenance_detail: string;
  start_datetime: string;
  end_datetime: string;
  id: string;
}

type Props = {
  maintenances: MaintenanceEntry[];
};

export default function UnderMaintenanceModal({ maintenances }: Props) {
  const closeModal = () => {};
  const Lang = getDisplayLangData();
  let systemLanguage = String(localStorage.getItem(localStorageKeys.systemLanguage));

  return (
    <>
      <Modal isOpen={true} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent minW="35rem">
          <>
            <ModalBody>
              <VStack align="center" justify="center">
                <Heading as="h2" size="xl" mb="4" marginTop={15}>
                  {Lang.under_maintenance_0001}
                </Heading>
              </VStack>
              <Text fontSize="lg">{Lang.under_maintenance_0002}</Text>
              <Text fontSize="lg" mb="4">
                {Lang.under_maintenance_0003}
              </Text>
              <Text fontSize="lg" mb="4" marginTop={'30px'}>
                {Lang.under_maintenance_0004}
              </Text>
              <Text fontSize="xl" mb="4" marginTop={'30px'}>
                {Lang.under_maintenance_0005}
              </Text>
              <Text fontSize="lg" mb="4">
                {getFormattedMonthDatetime(maintenances[0]?.start_datetime, systemLanguage)} ~{' '}
                {getFormattedMonthDatetime(maintenances[0]?.end_datetime, systemLanguage)}
              </Text>
              <Text fontSize="xl" mb="4" mt={'30px'}>
                {Lang.under_maintenance_0006}
              </Text>
              <Text fontSize="lg" mb="4">
                {maintenances[0]?.maintenance_title}
              </Text>
              <Text fontSize="lg" mb="8">
                {maintenances[0]?.maintenance_detail}
              </Text>
            </ModalBody>
          </>
        </ModalContent>
      </Modal>
    </>
  );
}
