import { useEffect } from 'react';
import './index.scss';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputLeftElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Stack,
} from '@chakra-ui/react';
import { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { ASSETS_IMG } from '../../assets/assets';
import {localStorageKeys} from '../../constant/localStorage';

//Form用
export const validationSchema = Yup.object({
  email: Yup.string().email('正しいメールアドレスを入力してください').required('メールアドレスは必須です'),
  nick_name: Yup.string().required('ニックネームは必須です'),
});

const Start = () => {
  //assets
  const kurashi_study_logo = ASSETS_IMG.logo01_tate;
  const cancel_white = ASSETS_IMG.cancel_white;
  const system_words: any = {};
  system_words['ja'] = require('../../assets/jsons/lang/ja.json');
  system_words['en'] = require('../../assets/jsons/lang/en.json');
  system_words['vn'] = require('../../assets/jsons/lang/vn.json');
  //states
  const [email, setEmail] = useState<string>('');
  const [nickName, setNickName] = useState<string>('');
  const [activationCode, setActivationCode] = useState<string>('');
  const [selectedLang, setSelectedLang] = useState<string>('ja');
  useEffect(() => {
    if (localStorage.hasOwnProperty(localStorageKeys.systemLanguage)) {
      setSelectedLang(String(localStorage.getItem(localStorageKeys.systemLanguage)));
    }
  }, []);

  //functions
  const onSubmit = (e: { preventDefault: () => void }) => {
    // modalによる分岐
    if (activationCode) {
      postGroupLearnerMailAddress();
    } else {
      postLearnerMailAddress();
    }
    e.preventDefault();
  };
  const onSignin = () => {
    window.location.replace(`/signin`);
  };

  // Modal切り分け用
  const [selectedItem, setSelectedItem] = useState<string>('');
  const message = '';
  const onCloseModal = () => {
    setSelectedItem('');
  };

  // メールアドレス登録
  const postLearnerMailAddress = async () => {};

  const postGroupLearnerMailAddress = async () => {
    // アクティベーションコードチェック
    postLearnerMailAddress();
  };

  return (
    <>
      <Modal isOpen={'resultSuccesstModal' === selectedItem} onClose={onCloseModal} isCentered>
        <ModalOverlay />
        <ModalContent userSelect="none" maxWidth="345px">
          <ModalHeader bgColor="blue" roundedTop={'md'} textColor="white" textAlign="center">
            {system_words[selectedLang]['KT_0008_11']}
            {/* 登録結果 */}
          </ModalHeader>
          <ModalBody>
            <p>{system_words[selectedLang]['KT_0008_12']}</p>
            {/* メールアドレスを登録しました。 */}
            <p>{system_words[selectedLang]['KT_0008_13']}</p>
            {/* 登録したアドレスへメールが送られますので、内容をご確認ください。 */}
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => window.location.replace('/signIn')}>サインイン画面へ</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={'resultFailModal' === selectedItem} onClose={onCloseModal} isCentered>
        <ModalOverlay />
        <ModalContent userSelect="none" maxWidth="345px">
          <ModalHeader bgColor="blue" roundedTop={'md'} textColor="white" textAlign="center">
            {system_words[selectedLang]['KT_0008_11']}
            {/* 登録結果 */}
          </ModalHeader>
          <ModalBody>
            <p>{message}</p>
          </ModalBody>
          <ModalFooter>
            <Button className="btn-border" onClick={onCloseModal} textColor="white" bgColor="gray" width="30%">
              <Image src={cancel_white} boxSize="20px"></Image>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={'abortModal' === selectedItem} onClose={onCloseModal} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent userSelect="none" maxWidth="345px">
          <ModalHeader bgColor="blue" roundedTop={'md'} textColor="white" textAlign="center">
            {/* アカウントの新規登録 */}
            {system_words[selectedLang]['KT_0008_07']}
          </ModalHeader>
          <Formik
            initialValues={{
              email: email,
              nickName: nickName,
            }}
            validationSchema={validationSchema}
            validateOnMount={true}
            onSubmit={(values) => {}}
          >
            <Form onSubmit={onSubmit}>
              <ModalBody>
                <FormControl isRequired>
                  <FormLabel>{system_words[selectedLang]['KT_0008_08']}</FormLabel>
                  {/* メールアドレス */}
                  <Flex justifyContent="center" alignItems="center" cursor="pointer" h="50px" backgroundColor="white">
                    <InputLeftElement pointerEvents="none" />
                    <Input
                      type="email"
                      placeholder="example@mail.com"
                      size="lg"
                      required
                      id="email"
                      pattern="[\w\-\._\+]+@[\w\-\._]+\.[A-Za-z]+"
                      onChange={(event) => setEmail(event.currentTarget.value)}
                    />
                  </Flex>
                  <FormLabel>{system_words[selectedLang]['KT_0008_09']}</FormLabel>
                  {/* ニックネーム */}
                  <Flex justifyContent="center" alignItems="center" cursor="pointer" h="50px" backgroundColor="white">
                    <InputLeftElement pointerEvents="none" />
                    <Input
                      type="text"
                      placeholder="nickname"
                      size="lg"
                      required
                      id="nick_name"
                      minLength={1}
                      onChange={(event) => setNickName(event.currentTarget.value)}
                    />
                  </Flex>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button className="btn-border" onClick={onCloseModal} textColor="white" bgColor="gray" width="30%">
                  <Image src={cancel_white} boxSize="20px"></Image>
                </Button>
                <Button type="submit" className="btn-border" textColor="white" bgColor="orange" ml="10px" width="70%">
                  {system_words[selectedLang]['KT_0008_10']}
                  {/* 決定 */}
                </Button>
              </ModalFooter>
            </Form>
          </Formik>
        </ModalContent>
      </Modal>
      <Modal isOpen={'groupModal' === selectedItem} onClose={onCloseModal} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent userSelect="none" maxWidth="345px">
          <ModalHeader bgColor="blue" roundedTop={'md'} textColor="white" textAlign="center">
            {/* アカウントの新規登録 */}
            {system_words[selectedLang]['KT_0008_07']}
          </ModalHeader>
          <Formik
            initialValues={{
              activationCode: activationCode,
              email: email,
              nickName: nickName,
            }}
            validationSchema={validationSchema}
            validateOnMount={true}
            onSubmit={(values) => {}}
          >
            <Form onSubmit={onSubmit}>
              <ModalBody>
                <FormControl isRequired>
                  <FormLabel>{system_words[selectedLang]['KT_0008_16']}</FormLabel>
                  {/* アクティベーションコード */}
                  <Flex justifyContent="center" alignItems="center" cursor="pointer" h="50px" backgroundColor="white">
                    <InputLeftElement pointerEvents="none" />
                    <Input
                      type="string"
                      placeholder="abcd1234"
                      size="lg"
                      required
                      id="activationCode"
                      pattern="[a-z0-9]{8}"
                      onChange={(event) => setActivationCode(event.currentTarget.value)}
                    />
                  </Flex>
                  <FormLabel>{system_words[selectedLang]['KT_0008_08']}</FormLabel>
                  {/* メールアドレス */}
                  <Flex justifyContent="center" alignItems="center" cursor="pointer" h="50px" backgroundColor="white">
                    <InputLeftElement pointerEvents="none" />
                    <Input
                      type="email"
                      placeholder="example@mail.com"
                      size="lg"
                      required
                      id="email"
                      pattern="[\w\-\._\+]+@[\w\-\._]+\.[A-Za-z]+"
                      onChange={(event) => setEmail(event.currentTarget.value)}
                    />
                  </Flex>
                  <FormLabel>{system_words[selectedLang]['KT_0008_09']}</FormLabel>
                  {/* ニックネーム */}
                  <Flex justifyContent="center" alignItems="center" cursor="pointer" h="50px" backgroundColor="white">
                    <InputLeftElement pointerEvents="none" />
                    <Input
                      type="text"
                      placeholder="nickname"
                      size="lg"
                      required
                      id="nick_name"
                      minLength={1}
                      onChange={(event) => setNickName(event.currentTarget.value)}
                    />
                  </Flex>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button className="btn-border" onClick={onCloseModal} textColor="white" bgColor="gray" width="30%">
                  <Image src={cancel_white} boxSize="20px"></Image>
                </Button>
                <Button type="submit" className="btn-border" textColor="white" bgColor="orange" ml="10px" width="70%">
                  {system_words[selectedLang]['KT_0008_10']}
                  {/* 決定 */}
                </Button>
              </ModalFooter>
            </Form>
          </Formik>
        </ModalContent>
      </Modal>
      <Stack
        id="bg-image"
        pb="5px"
        m="auto"
        w="414px"
        bgSize="cover"
        boxSizing="content-box"
        bgColor="white"
        justify="center"
      >
        <Flex justifyContent="center" height="60%">
          <Image id="logo-image" src={kurashi_study_logo} px="20px" mt="60px" mb="20px" />
        </Flex>
        <Flex textAlign="center" justifyContent="center" px="50px" fontSize="13px">
          {system_words[selectedLang]['KT_0008_03']}
        </Flex>
        <Flex justifyContent="center" margin="5px" pb="20px">
          <Box>
            <Button
              id="signin_btn"
              className="btn-border"
              onClick={onSignin}
              textColor="white"
              bgColor="orange"
              w="300px"
            >
              {system_words[selectedLang]['KT_0008_04']}
            </Button>
          </Box>
        </Flex>
      </Stack>
    </>
  );
};

export default Start;
