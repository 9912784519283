import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { OptionsType, UserEventAction } from '../../@types/userEvent/userEventAction';
import { requestServerApi, serverApi } from '../../api/api';
import { ERROR_CODE } from '../../constant/errorCode';
import useError from '../useError/useError';

/**
 * ユーザーイベントログ
 */
const useUserEvent = () => {
  const location = useLocation();
  const { sendErrorLog } = useError();

  /** イベントログ作成 */
  const sendUserEvent = useCallback(
    (action: UserEventAction, options?: OptionsType) => {
      const currentPage = location.pathname;

      let optionsStr = options ? JSON.stringify(options) : '';
      requestServerApi<any>(() => {
        serverApi.userEventControllerLoggingEvent({
          currentPage,
          action,
          timestampMillis: new Date().getTime(),
          userAgent: navigator.userAgent,
          options: optionsStr,
        });
      }).catch((err) => {
        sendErrorLog({
          error: err,
          errorCode: ERROR_CODE.E_9100,
          message: `ユーザーイベントログ作成でエラー`,
        });
      });
    },
    [location.pathname, requestServerApi, serverApi],
  );

  /** イベントログ作成(未認証) */
  const sendUserEventByNotAuth = useCallback(
    (action: UserEventAction, options?: OptionsType) => {
      const currentPage = location.pathname;

      let optionsStr = options ? JSON.stringify(options) : '';
      requestServerApi<any>(() => {
        serverApi.userEventControllerLoggingEventByNotAuth({
          currentPage,
          action,
          timestampMillis: new Date().getTime(),
          userAgent: navigator.userAgent,
          options: optionsStr,
        });
      }).catch((err) => {
        sendErrorLog({
          error: err,
          errorCode: ERROR_CODE.E_9100,
          message: `ユーザーイベントログ作成(非認証)でエラー`,
        });
      });
    },
    [location.pathname, requestServerApi, serverApi],
  );
  return { sendUserEvent, sendUserEventByNotAuth };
};
export default useUserEvent;
