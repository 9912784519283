import { useEffect } from 'react';
import './index.scss';
import { Box, Button, Checkbox, Flex, Image, Link, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { REACT_APP_PRIVACY_POLICY_URL, REACT_APP_TERMS_OF_SERVICE_URL } from '../../configs';
import { ASSETS_IMG } from '../../assets/assets';
import {localStorageKeys} from '../../constant/localStorage';

const Agree = () => {
  //assets
  const kurashi_study_logo = ASSETS_IMG.logo01_tate;
  const system_words: any = {};
  system_words['ja'] = require('../../assets/jsons/lang/ja.json');
  system_words['en'] = require('../../assets/jsons/lang/en.json');
  system_words['vn'] = require('../../assets/jsons/lang/vn.json');
  //states
  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const [policyChecked, setPolicyChecked] = useState<boolean>(false);
  const [selectedLang, setSelectedLang] = useState<string>('ja');
  //functions
  const onStart = () => {
    window.location.replace(`/start`);
    //同意したかどうかを格納する
    localStorage.setItem(localStorageKeys.agreed, 'true');
  };

  useEffect(() => {
    if (localStorage.hasOwnProperty(localStorageKeys.systemLanguage)) {
      setSelectedLang(String(localStorage.getItem(localStorageKeys.systemLanguage)));
    }
  }, []);

  return (
    <Stack
      id="bg-image"
      pb="5px"
      m="auto"
      w="414px"
      bgSize="cover"
      boxSizing="content-box"
      bgColor="white"
      justify="center"
    >
      <Flex justifyContent="center" height="60%">
        <Image id="logo-image" src={kurashi_study_logo} px="20px" mt="60px" mb="20px" />
      </Flex>
      <Flex textAlign="center" justifyContent="center" px="20px">
        <Text fontSize="16px">{system_words[selectedLang]['KT_0002_01']}</Text>
      </Flex>
      <Flex justifyContent="center" pb="20px">
        <Checkbox m="10px" size="lg" value="false" isRequired onChange={(e) => setTermsChecked(e.target.checked)} />
        {/* 利用規約 */}
        <Link
          href={REACT_APP_TERMS_OF_SERVICE_URL}
          target="_blank"
          m="10px"
          color="orange"
          fontWeight="bold"
          fontSize="13px"
        >
          {system_words[selectedLang]['KT_0002_02']}
        </Link>
        <Checkbox m="10px" size="lg" value="false" isRequired onChange={(e) => setPolicyChecked(e.target.checked)} />
        {/* プライバシーポリシー */}
        <Link
          href={REACT_APP_PRIVACY_POLICY_URL}
          target="_blank"
          m="10px"
          color="orange"
          fontWeight="bold"
          fontSize="13px"
        >
          {system_words[selectedLang]['KT_0002_03']}
        </Link>
      </Flex>
      <Flex justifyContent="center" margin="5px" pb="20px">
        <Box>
          <Button
            id="start_btn"
            className="btn-border"
            onClick={onStart}
            textColor="white"
            bgColor="orange"
            w="200px"
            disabled={!(termsChecked && policyChecked)}
          >
            {system_words[selectedLang]['KT_0002_04']}
          </Button>
        </Box>
      </Flex>
      <Flex justifyContent="center" margin="5px" pt="50px" pb="20px">
        <Box>Copyright ©SUN Co.,Ltd All Rights Reserved</Box>
      </Flex>
    </Stack>
  );
};

export default Agree;
