import { ComponentType } from 'react';

export const lazyRetry = (lazyFn: () => Promise<{ default: ComponentType<any> }>, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve: (value: Promise<{ default: ComponentType<any> }>) => void, reject) => {
    lazyFn()
      .then(resolve as any)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === -1) {
            reject(error);
            return;
          }
          lazyRetry(lazyFn, retriesLeft - 1, interval).then(resolve as any, reject);
        }, interval);
      });
  });
};
