import {localStorageKeys} from '../constant/localStorage';
export const getStoriesData = () => {
  let retStoriesData = '';
  // キャッシュがなければAPIから取得してセット
  if (!localStorage.hasOwnProperty(localStorageKeys.storiesData)) {
    retStoriesData = JSON.stringify(require('../assets/jsons/stories/list_season_chapter_section_staging.json'));
    localStorage.setItem(localStorageKeys.storiesData, retStoriesData);
  } else {
    retStoriesData = String(localStorage.getItem(localStorageKeys.storiesData));
  }
  return JSON.parse(retStoriesData);
};

export const getSectionData = (seasonId: number, episodeId: number, sceneId: number) => {
  const JsonData = [
    [
      [
        require('../assets/jsons/section/1_SEASON_ID_1_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_1_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_1_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/1_SEASON_ID_2_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_2_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_2_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/1_SEASON_ID_3_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_3_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_3_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/1_SEASON_ID_4_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_4_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_4_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/1_SEASON_ID_5_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_5_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_5_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/1_SEASON_ID_6_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_6_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_6_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/1_SEASON_ID_7_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_7_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_7_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/1_SEASON_ID_8_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_8_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_8_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/1_SEASON_ID_9_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_9_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_9_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/1_SEASON_ID_10_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_10_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_10_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/1_SEASON_ID_11_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_11_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_11_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/1_SEASON_ID_12_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_12_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_12_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/1_SEASON_ID_13_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_13_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_13_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/1_SEASON_ID_14_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_14_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_14_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/1_SEASON_ID_15_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_15_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_15_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/1_SEASON_ID_16_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_16_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/1_SEASON_ID_16_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
    ],
    [
      [
        require('../assets/jsons/section/2_SEASON_ID_1_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_1_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_1_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/2_SEASON_ID_2_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_2_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_2_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/2_SEASON_ID_3_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_3_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_3_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/2_SEASON_ID_4_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_4_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_4_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/2_SEASON_ID_5_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_5_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_5_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/2_SEASON_ID_6_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_6_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_6_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/2_SEASON_ID_7_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_7_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_7_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/2_SEASON_ID_8_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_8_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_8_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/2_SEASON_ID_9_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_9_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_9_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/2_SEASON_ID_10_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_10_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_10_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/2_SEASON_ID_11_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_11_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_11_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/2_SEASON_ID_12_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_12_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_12_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/2_SEASON_ID_13_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_13_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_13_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/2_SEASON_ID_14_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_14_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_14_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/2_SEASON_ID_15_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_15_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_15_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
      [
        require('../assets/jsons/section/2_SEASON_ID_16_CHAPTER_ID_1_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_16_CHAPTER_ID_2_SECTION_ID_STAGING.json'),
        require('../assets/jsons/section/2_SEASON_ID_16_CHAPTER_ID_3_SECTION_ID_STAGING.json'),
      ],
    ],
  ];
  localStorage.setItem(localStorageKeys.sectionData, JSON.stringify(JsonData[seasonId][episodeId][sceneId]));
  return JsonData[seasonId][episodeId][sceneId];
};

export const getEpisodeSummaryData = (seasonId: number, episodeId: number) => {
  const JsonData = [
    [
      require('../assets/jsons/pratice/1_SEASON_ID_1_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/1_SEASON_ID_2_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/1_SEASON_ID_3_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/1_SEASON_ID_4_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/1_SEASON_ID_5_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/1_SEASON_ID_6_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/1_SEASON_ID_7_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/1_SEASON_ID_8_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/1_SEASON_ID_9_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/1_SEASON_ID_10_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/1_SEASON_ID_11_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/1_SEASON_ID_12_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/1_SEASON_ID_13_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/1_SEASON_ID_14_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/1_SEASON_ID_15_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/1_SEASON_ID_16_CHAPTER_ID_STAGING.json'),
    ],
    [
      require('../assets/jsons/pratice/2_SEASON_ID_1_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/2_SEASON_ID_2_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/2_SEASON_ID_3_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/2_SEASON_ID_4_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/2_SEASON_ID_5_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/2_SEASON_ID_6_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/2_SEASON_ID_7_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/2_SEASON_ID_8_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/2_SEASON_ID_9_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/2_SEASON_ID_10_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/2_SEASON_ID_11_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/2_SEASON_ID_12_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/2_SEASON_ID_13_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/2_SEASON_ID_14_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/2_SEASON_ID_14_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/2_SEASON_ID_14_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/2_SEASON_ID_15_CHAPTER_ID_STAGING.json'),
      require('../assets/jsons/pratice/2_SEASON_ID_16_CHAPTER_ID_STAGING.json'),
    ],
  ];
  localStorage.setItem(localStorageKeys.sectionData, JSON.stringify(JsonData[seasonId][episodeId]));
  return JsonData[seasonId][episodeId];
};

// FIXME setしてる。関数名と内容が正しくない。リファクタリング
export const getSeasonSummaryData = (seasonId: number) => {
  const JsonData = [
    require('../assets/jsons/pratice/1_SEASON_ID_STAGING.json'),
    require('../assets/jsons/pratice/2_SEASON_ID_STAGING.json'),
  ];
  localStorage.setItem(localStorageKeys.sectionData, JSON.stringify(JsonData[seasonId]));
  return JsonData[seasonId];
};

export const getSummaryQuestionData = (episodeId: number) => {
  let retQuestionData = '';
  // キャッシュがなければAPIから取得してセット
  retQuestionData = JSON.stringify(summaryquestionData); //TODO バックエンドAPIから取得する仕組みに変更
  localStorage.setItem('summaryQuestionData', retQuestionData);

  return JSON.parse(retQuestionData);
};

export const getSelfStudyJson = () => {
  let retSelfStudyData;
  // キャッシュがなければAPIから取得してセット
  retSelfStudyData = require('../assets/jsons/self_study/self_study_staging.json'); //TODO バックエンドAPIから取得する仕組みに変更
  return retSelfStudyData;
};

export const getHiraganaQuestionJson = () => {
  let retHiraganaQuestionData;
  retHiraganaQuestionData = require('../assets/jsons/hiragana/questionKana_staging.json');
  return retHiraganaQuestionData;
};

export const getKatakanaQuestionJson = () => {
  let retKatakanaQuestionData;
  retKatakanaQuestionData = require('../assets/jsons/katakana/questionKana_staging.json');
  return retKatakanaQuestionData;
};

export const getQAJson = () => {
  let retQAData = '';
  // キャッシュがなければAPIから取得してセット
  if (!localStorage.hasOwnProperty(localStorageKeys.QAData)) {
    retQAData = JSON.stringify(qaData); //TODO バックエンドAPIから取得する仕組みに変更
  } else {
    retQAData = String(localStorage.getItem(localStorageKeys.QAData));
  }
  return JSON.parse(retQAData);
};

export const getDisplayLangData = () => {
  let systemLanguage = 'en';
  let retLangData = '';

  // キャッシュがなければAPIから取得してセット
  if (!localStorage.hasOwnProperty(localStorageKeys.systemLanguage)) {
    // TODO API機能実装
    localStorage.setItem(localStorageKeys.systemLanguage, 'en');
  }

  systemLanguage = String(localStorage.getItem(localStorageKeys.systemLanguage));

  switch (systemLanguage) {
    case 'ja':
      retLangData = require('../assets/jsons/lang/ja.json');
      break;
    case 'en':
      retLangData = require('../assets/jsons/lang/en.json');
      break;
    case 'vn':
      retLangData = require('../assets/jsons/lang/vn.json');
      break;
    default: {
      // キャッシュに想定外の言語がセットされていた場合は、英語をセットして英語を返す
      localStorage.setItem(localStorageKeys.systemLanguage, 'en');
      retLangData = require('../assets/jsons/lang/en.json');
    }
  }
  return Object(retLangData);
};

export const getNationsData = () => {
  let systemLanguage = 'ja';
  let nations = '';
  // キャッシュがなければAPIから取得してセット
  if (!localStorage.hasOwnProperty(localStorageKeys.systemLanguage)) {
    // TODO API機能実装
    localStorage.setItem(localStorageKeys.systemLanguage, 'en');
  } else {
    systemLanguage = String(localStorage.getItem(localStorageKeys.systemLanguage));
  }

  switch (systemLanguage) {
    case 'ja':
      nations = require('../assets/jsons/nations/nations_ja.json');
      break;
    case 'en':
      nations = require('../assets/jsons/nations/nations_en.json');
      break;
    case 'vn':
      nations = require('../assets/jsons/nations/nations_vn.json');
      break;
  }
  return Object(nations);
};

const summaryquestionData = [
  {
    delf: false,
    answer2: 'を',
    script: '',
    image: '',
    name: [['レンさんはＩＴ会社', '社員です。']],
    parent: 'chapter',
    type: '',
    furigana: '',
    sub_key: '1_SEASON_ID_1_CHAPTER_ID',
    audio: '',
    verb: '',
    created_at: '2020-12-08T06:41:37.000Z',
    list_answer: {
      sentence_answer: [],
      answer: [],
    },
    answer_correct: [1],
    answer4: 'が',
    updated_at: '2020-12-08T06:41:37.000Z',
    Id: '298034ef-9e20-4173-a2c1-09317720c0f4',
    sub_question: {
      question1: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
      question2: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
    },
    type_value: 'Choices',
    lead_sentence: '（ ） に なにが はいりますか。\n いちばん いいものを ひとつ えらんでください',
    answer1: 'の',
    answer3: 'へ',
    no: 0,
    audio_url: '',
    image_url: '',
  },
  {
    delf: false,
    answer2: 'が',
    script: '',
    image: '',
    name: [['Ａ：アンさんですか。'], ['Ｂ：はい、私', 'アンです。']],
    parent: 'chapter',
    type: '',
    furigana: '',
    sub_key: '1_SEASON_ID_1_CHAPTER_ID',
    audio: '',
    verb: '',
    created_at: '2020-12-08T06:43:01.266Z',
    list_answer: {
      sentence_answer: [],
      answer: [],
    },
    answer_correct: [2],
    answer4: 'に',
    updated_at: '2020-12-08T06:43:01.266Z',
    Id: 'b8fbc2e8-07e5-4f3a-8345-5158d0fce29e',
    sub_question: {
      question1: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
      question2: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
    },
    type_value: 'Choices',
    lead_sentence: '（ ） に なにが はいりますか。\n いちばん いいものを ひとつ えらんでください',
    answer1: 'の',
    answer3: 'で',
    no: 1,
    audio_url: '',
    image_url: '',
  },
  {
    delf: false,
    answer2: 'へ',
    script: '',
    image: '',
    name: [['9時に会社', '行きます。']],
    parent: 'chapter',
    type: '',
    furigana: '',
    sub_key: '1_SEASON_ID_1_CHAPTER_ID',
    audio: '',
    verb: '',
    created_at: '2021-01-14T08:39:31.656Z',
    list_answer: {
      sentence_answer: [],
      answer: [],
    },
    answer_correct: [2],
    answer4: 'で',
    updated_at: '2021-01-14T08:39:31.656Z',
    Id: '51e7a41e-d51e-4d1a-95e4-ead6b9cee273',
    sub_question: {
      question1: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
      question2: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
    },
    type_value: 'Choices',
    lead_sentence: '（ ） に なにが はいりますか。\n いちばん いいものを ひとつ えらんでください',
    answer1: 'が',
    answer3: 'を',
    no: 2,
    audio_url: '',
    image_url: '',
  },
  {
    created_at: '2020-12-08T06:44:44.312Z',
    answer1: 'あなた',
    lead_sentence: '正しい順番にならびかえてください。',
    verb: '',
    answer3: 'イタリア人',
    delf: false,
    answer2: 'も',
    script: '',
    image: '',
    name: [['', 'か。']],
    parent: 'chapter',
    type: '',
    furigana: '',
    sub_key: '1_SEASON_ID_1_CHAPTER_ID',
    audio: '',
    list_answer: {
      sentence_answer: [],
      answer: [],
    },
    answer_correct: [1],
    answer4: 'です',
    updated_at: '2020-12-08T06:44:44.312Z',
    Id: '2c1f316f-6e4b-4a1f-bdde-b53770b0e2d1',
    sub_question: {
      question1: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
      question2: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
    },
    type_value: 'Sort',
    no: 3,
    audio_url: '',
    image_url: '',
  },
  {
    delf: false,
    answer2: '15時',
    script: '',
    image: '',
    name: [['9時', 'です。']],
    parent: 'chapter',
    type: '',
    furigana: '',
    sub_key: '1_SEASON_ID_1_CHAPTER_ID',
    audio: '',
    verb: '',
    created_at: '2020-12-08T06:45:39.989Z',
    list_answer: {
      sentence_answer: [],
      answer: [],
    },
    answer_correct: [1],
    answer4: 'アルバイト',
    updated_at: '2020-12-08T06:45:39.989Z',
    Id: '9fde3b77-ccbb-4142-95ce-03aa54f691e2',
    sub_question: {
      question1: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
      question2: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
    },
    type_value: 'Sort',
    lead_sentence: '正しい順番にならびかえてください。',
    answer1: 'から',
    answer3: 'まで',
    no: 4,
    audio_url: '',
    image_url: '',
  },
  {
    delf: false,
    answer2: 'おながい',
    script: '',
    image: '',
    name: [['よろしく', 'お願い', 'します。']],
    parent: 'chapter',
    type: '',
    furigana: '',
    sub_key: '1_SEASON_ID_1_CHAPTER_ID',
    audio: '',
    verb: '',
    created_at: '2020-12-08T06:47:59.525Z',
    list_answer: {
      sentence_answer: [],
      answer: [],
    },
    answer_correct: [1],
    answer4: 'おえがい',
    updated_at: '2020-12-08T06:47:59.525Z',
    Id: '9c96cc35-81de-4539-8b9a-ce7a4addbdec',
    sub_question: {
      question1: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
      question2: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
    },
    type_value: 'Kanji',
    lead_sentence: '問題を読んで、いちばんいいものをえらんでください。',
    answer1: 'おねがい',
    answer3: 'おねかい',
    no: 5,
    audio_url: '',
    image_url: '',
  },
  {
    delf: false,
    answer2: 'みなさん',
    script: '',
    image: '',
    name: [['', '皆さん', 'こんにちは']],
    parent: 'chapter',
    type: '',
    furigana: '',
    sub_key: '1_SEASON_ID_1_CHAPTER_ID',
    audio: '',
    verb: '',
    created_at: '2020-12-08T06:49:17.057Z',
    list_answer: {
      sentence_answer: [],
      answer: [],
    },
    answer_correct: [2],
    answer4: 'みあさん',
    updated_at: '2020-12-08T06:49:17.057Z',
    Id: '2acd6710-299f-491b-8115-7efc595ea464',
    sub_question: {
      question1: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
      question2: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
    },
    type_value: 'Kanji',
    lead_sentence: '問題を読んで、いちばんいいものをえらんでください。',
    answer1: 'みんなさん',
    answer3: 'みなんさん',
    no: 6,
    audio_url: '',
    image_url: '',
  },
  {
    delf: false,
    answer2: '２',
    script: 'よろしくお願いします。',
    image: '',
    name: [],
    parent: 'chapter',
    type: '',
    furigana: '',
    sub_key: '1_SEASON_ID_1_CHAPTER_ID',
    audio: 'audio/1609810707092_01_01_10 (2).mp3',
    verb: '',
    created_at: '2020-12-08T06:51:04.285Z',
    list_answer: {
      sentence_answer: [],
      answer: [],
    },
    answer_correct: [3],
    answer4: '４',
    updated_at: '2020-12-08T06:51:04.285Z',
    Id: 'f0ee6734-eaca-465e-a814-ecbc622ee43d',
    sub_question: {
      question1: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
      question2: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
    },
    type_value: 'ShortSentences',
    lead_sentence: '会話を聞いて、いちばんいいものをえらんでください。',
    answer1: '１',
    answer3: '３',
    no: 7,
    audio_url: 'https://sun-resource-tokyo.s3-ap-northeast-1.amazonaws.com/audio/1609810707092_01_01_10 (2).mp3',
    audio_mobile: '1609810707092_01_01_10 (2).mp3',
    image_url: '',
  },
  {
    delf: false,
    answer2: '２',
    script: '何時に学校へ行きますか。',
    image: '',
    name: [],
    parent: 'chapter',
    type: '',
    furigana: '',
    sub_key: '1_SEASON_ID_1_CHAPTER_ID',
    audio: 'audio/1609810741380_01_03_09.mp3',
    verb: '',
    created_at: '2020-12-08T06:52:28.677Z',
    list_answer: {
      sentence_answer: [],
      answer: [],
    },
    answer_correct: [3],
    answer4: '４',
    updated_at: '2020-12-08T06:52:28.677Z',
    Id: 'c9f2ff2f-f7c9-4dd5-883a-22f8efc10356',
    sub_question: {
      question1: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
      question2: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
    },
    type_value: 'ShortSentences',
    lead_sentence: '会話を聞いて、いちばんいいものをえらんでください。',
    answer1: '１',
    answer3: '３',
    no: 8,
    audio_url: 'https://sun-resource-tokyo.s3-ap-northeast-1.amazonaws.com/audio/1609810741380_01_03_09.mp3',
    audio_mobile: '1609810741380_01_03_09.mp3',
    image_url: '',
  },
  {
    delf: false,
    answer2: 'アーサーさんはエンジニアじゃありません',
    script: '',
    image: 'image/1610610811733_dok001-003.png',
    name: [['いちばんいいものをえらんでください。']],
    parent: 'chapter',
    type: '',
    furigana: '',
    sub_key: '1_SEASON_ID_1_CHAPTER_ID',
    audio: '',
    verb: '',
    created_at: '2020-12-08T06:55:09.831Z',
    list_answer: {
      sentence_answer: [],
      answer: [],
    },
    answer_correct: [2],
    answer4: 'ホワイトさんは教師じゃありません',
    updated_at: '2020-12-08T06:55:09.831Z',
    Id: '3c4f6323-7166-4040-a60a-7a64d80c4ce6',
    sub_question: {
      question1: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
      question2: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
    },
    type_value: 'Comprehension',
    lead_sentence: '問題を読んで、いちばんいいものをえらんでください。',
    answer1: 'ブラウンさんは教師です',
    answer3: 'アメリアさんはABCスクールの学生です',
    no: 9,
    audio_url: '',
    image_url: 'https://sun-resource-tokyo.s3-ap-northeast-1.amazonaws.com/image/1610610811733_dok001-003.png',
    image_mobile: '1610610811733_dok001-003.png',
  },
  {
    delf: false,
    answer2: '12時',
    script: '',
    image: 'image/1614129121824_dok004-1-02 (1).png',
    name: [['ライブは何時までですか?']],
    parent: 'chapter',
    type: '',
    furigana: '',
    sub_key: '1_SEASON_ID_1_CHAPTER_ID',
    audio: '',
    verb: '',
    created_at: '2020-12-08T06:56:27.526Z',
    list_answer: {
      sentence_answer: [],
      answer: [],
    },
    answer_correct: [4],
    answer4: '19時',
    updated_at: '2020-12-08T06:56:27.526Z',
    Id: '03044ace-43b0-4508-a22f-d3fe4071b3c6',
    sub_question: {
      question1: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
      question2: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
    },
    type_value: 'Comprehension',
    lead_sentence: '問題を読んで、いちばんいいものをえらんでください。',
    answer1: '10時',
    answer3: '17時',
    no: 10,
    audio_url: '',
    image_url: 'https://sun-resource-tokyo.s3-ap-northeast-1.amazonaws.com/image/1614129121824_dok004-1-02 (1).png',
    image_mobile: '1614129121824_dok004-1-02 (1).png',
  },
  {
    delf: false,
    answer2: '「ハローワールド」は13時からです',
    script: '',
    image: 'image/1614129102939_dok004-1-02 (1).png',
    name: [['１・２・３・４から、いちばんいいものをえらんでください。']],
    parent: 'chapter',
    type: '',
    furigana: '',
    sub_key: '1_SEASON_ID_1_CHAPTER_ID',
    audio: '',
    verb: '',
    created_at: '2020-12-08T06:58:05.685Z',
    list_answer: {
      sentence_answer: [],
      answer: [],
    },
    answer_correct: [2],
    answer4: '「おはな」は12時からです',
    updated_at: '2020-12-08T06:58:05.685Z',
    Id: 'c093a6c6-f521-4fcd-acce-74fa485cc34d',
    sub_question: {
      question1: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
      question2: {
        answers: [
          {
            id: 1,
            name: '回答1',
            value: '',
          },
          {
            id: 2,
            name: '回答2',
            value: '',
          },
          {
            id: 3,
            name: '回答3',
            value: '',
          },
          {
            id: 4,
            name: '回答4',
            value: '',
          },
        ],
        audio: null,
        isAnsewr: 1,
        script: '',
        audio_url: '',
      },
    },
    type_value: 'Comprehension',
    lead_sentence: '問題を読んで、いちばんいいものをえらんでください。',
    answer1: '「なかむらず」は11時半までです',
    answer3: '「やまあらし」は16時からです',
    no: 11,
    audio_url: '',
    image_url: 'https://sun-resource-tokyo.s3-ap-northeast-1.amazonaws.com/image/1614129102939_dok004-1-02 (1).png',
    image_mobile: '1614129102939_dok004-1-02 (1).png',
  },
];
const qaData = [
  {
    category: {
      ja: '予約・キャンセル',
      en: 'Reservation & Cancellation',
      vn: 'Đăng ký・Hủy bỏ',
    },
    qaList: [
      {
        question: {
          ja: 'レッスンキャンセル可能時間を過ぎてしまった場合は、どうしたらいいですか。',
          en: 'What should I do if it is past the time I can cancel the lesson?',
          vn: 'Tôi nên làm thế nào trong trường hợp đã quá thời gian có thể hủy bỏ bài học?',
        },
        answer: {
          ja: '特に何かしていただく必要はありません。連絡もしなくて大丈夫です。ただし、予約したレッスン分のレッスン実施回数は消費されますのでご了承ください。',
          en: 'You do not need to do anything in particular. No need to contact us.However, please note that the number of lessons for the reserved lesson will be used up.',
          vn: 'Bạn không cần phải làm bất cứ điều gì cụ thể. Không cần liên hệ với chúng tôi. Tuy nhiên, xin lưu ý rằng số lượng bài học cho bài học dành riêng sẽ được sử dụng hết.',
        },
        isInquiry: false,
      },
      {
        question: {
          ja: '予約したレッスンを無断で欠席した場合、ペナルティはありますか。',
          en: 'Are there any penalties if I miss a lesson without notice?',
          vn: 'Trường hợp tôi vắng mặt bài học đã đăng ký mà không báo trước, thì tôi có bị phạt không?',
        },
        answer: {
          ja: 'お客様の無断欠席によるペナルティはありません。ただし、予約されたレッスン分のレッスン実施回数は消費されますのでご了承ください。',
          en: 'There is no penalty for your unannounced absence. However, please note that the number of lessons for the registered lesson will still be used.',
          vn: 'Không có hình phạt cho sự vắng mặt không báo trước của bạn. Tuy nhiên, xin lưu ý rằng số lượng bài học cho bài học đã đăng ký sẽ vẫn được sử dụng.',
        },
        isInquiry: false,
      },
      {
        question: {
          ja: 'レッスン中のチャットの履歴は後で見返せますか。',
          en: 'Can I look back at the history of the chats during the lesson later?',
          vn: 'Sau đó tôi có thể xem lại lịch sử trò chuyện trong giờ học không?',
        },
        answer: {
          ja: '申し訳ございません。レッスン中のチャットの履歴は、レッスン終了後消えてしまいます。内容を残したい場合はご自身でメモをしておいてください。',
          en: 'We are sorry. The chat history during the lesson will be deleted after the lesson is over. If you want to keep the contents, please make a note of it by yourself.',
          vn: 'Rất tiếc. Lịch sử trò chuyện trong giờ học sẽ biến mất sau khi bài học kết thúc. Trường hợp nếu muốn lưu lại nội dung, vui lòng hãy tự ghi chú lại.',
        },
        isInquiry: false,
      },
      {
        question: {
          ja: 'レッスンの途中でも退席できますか。',
          en: 'Can I leave the lesson in the middle of the lesson?',
          vn: 'Tối có thể rời khỏi vị trí vào lúc giữa chừng của bài học được không?',
        },
        answer: {
          ja: '可能です。ただし、途中でレッスンを終了した場合でもレッスン実施回数は消化されますのでご了承ください。',
          en: 'Yes, you can. However, please note that your number of lessons will be used up even if you leave the lesson in the middle.',
          vn: 'Vâng, bạn có thể. Tuy nhiên, xin lưu ý rằng số lượng bài học của bạn sẽ được sử dụng hết ngay cả khi bạn bỏ học giữa chừng.',
        },

        isInquiry: false,
      },
      {
        question: {
          ja: 'レッスンへの入室は何分前からできますか。',
          en: 'How many minutes before the lesson can I enter the room?',
          vn: 'Có thể vào phòng học trước mấy phút?',
        },
        answer: {
          ja: '開始時間5分前から待機室に入ることができます。開始時間になりましたら、そのままレッスンが始まります。',
          en: 'You can enter the waiting room 5 minutes before the start time. When it is time to start, the lesson will begin.',
          vn: 'Bạn có thể vào phòng chờ trước khi giờ học bắt đầu 5 phút. Khi đến thời gian bắt đầu, thì bài học sẽ bắt đầu đúng như dự kiến .',
        },
        isInquiry: false,
      },
      {
        question: {
          ja: 'どうやってレッスンを開始しますか。',
          en: 'How do I start the lesson?',
          vn: 'Làm thế nào để bắt đầu bài học?',
        },
        answer: {
          ja: 'レッスンは、TOP画面の一番下の「入室する」ボタンから、またはレッスン画面の「これからのレッスン」のレッスン情報内の「入室する」ボタンから始めることができます。',
          en: "You can start a lesson from the 'Enter the room' button at the bottom of the homescreen, or from the 'Enter the room' button in the applicable lesson information in the 'Upcoming lessons' section of the lesson screen.",
          vn: 'Bài học được bắt đầu bằng nút 「Vào phòng」ở phía dưới cùng của màn hình TOP, hoặc từ nút 「Vào phòng」trong phần thông tin bài học của「Bài học tiếp theo」trên màn hình bài học.',
        },
        isInquiry: false,
      },
      {
        question: {
          ja: 'どうやってレッスンを終了しますか。',
          en: 'How do I end the lesson?',
          vn: 'Làm thế nào để kết thúc bài học?',
        },
        answer: {
          ja: 'レッスン右下メニューの「Room Out」ボタンをタップすると、レッスンを終了することができます。また、レッスン終了時間から1分経つと自動で接続が切れます。',
          en: "You can end the lesson by tapping the 'Room out' button on the bottom right menu of the lesson. Also, the connection will be automatically cut off when 1 minute has passed from the lesson end time.",
          vn: 'Bạn có thể kết thúc bài học bằng cách nhấn vào nút「Room Out」phía dưới bên phải danh mục bài học. Ngoài ra, kết nối sẽ tự động ngắt sau 1 phút kể từ khi bài học hết giờ.',
        },
        isInquiry: false,
      },
    ],
  },
  {
    category: { ja: 'レッスン', en: 'Lesson', vn: 'Bài học' },
    qaList: [
      {
        question: {
          ja: 'レッスンの時に、何か用意するものはありますか。',
          en: 'Is there anything I need to prepare for the lesson?',
          vn: 'Có cần phải chuẩn bị gì cho bài học không?',
        },
        answer: {
          ja: '特にありませんが、インカムやマイク、メモを取れるものなどを用意すると、より快適にレッスンが受けられます。',
          en: "You don't need to prepare anything in particular, but it is a good idea to use a webcam, headphones, a microphone, and something to take notes.",
          vn: 'Không có gì đặc biệt, nhưng nếu bạn chuẩn bị một thiết bị nghe gọi, một chiếc micro và một thứ gì đó để ghi chép, thì bạn có thể học thoải mái hơn.',
        },
        isInquiry: false,
      },
      {
        question: {
          ja: 'レッスンの様子を撮ってもいいですか。',
          en: 'Can I film or take pictures of the lesson?',
          vn: 'Tôi có thể chụp ảnh trạng thái bài học được không?',
        },
        answer: {
          ja: '申し訳ございません。レッスン中の録音・録画、スクリーンショット撮影等は禁止です。ご了承ください。',
          en: 'We apologize for the inconvenience. It is prohibited to record or take screenshots during the lesson. Thank you for your understanding.',
          vn: 'Rất tiếc. Nghiêm cấm các hành động ghi âm ・ghi hình , chụp ảnh màn hình trong giờ học. Xin lưu ý.',
        },
        isInquiry: false,
      },
      {
        question: {
          ja: 'レッスンの様子をSNSなどに投稿してもいいですか。',
          en: 'Can I post my lessons on social media?',
          vn: 'Tôi có thể đăng tình trạng bài học lên SNS … được không?',
        },
        answer: {
          ja: '画像や映像、音声などをSNSに投稿することはご遠慮ください。文字の投稿は大丈夫です。',
          en: 'Please do not post images, video, or audio on social networking sites. Text posts are fine. Thank you for your understanding and respecting our users and instructors.',
          vn: 'Vui lòng không đăng hình ảnh, video, âm thanh, ... lên SNS. Đăng ký tự thì được.',
        },
        isInquiry: false,
      },
      {
        question: {
          ja: 'レッスン中にチャットは使えますか。',
          en: 'Can I use chat during the lesson?',
          vn: 'Trong giờ học tôi có thể sử dụng tính năng trò chuyện không?',
        },
        answer: {
          ja: 'はい。利用できます。画面上の「Chat」ボタンを押すと、チャット画面が表示されます。',
          en: "Yes, you can. Click the 'Chat' button on the screen to open the chat window.",
          vn: 'Vâng , có thể sử dụng. Khi bạn nhấn nút「Chat」trên màn hình , thì màn hình của tính năng trò chuyện sẽ xuất hiện.',
        },
        isInquiry: false,
      },
    ],
  },
  {
    category: {
      ja: '「話す」の機能',
      en: 'Talk',
      vn: 'Kỹ năng「Trò chuyện」',
    },
    qaList: [
      {
        question: {
          ja: '海外からでもレッスンが受けられますか。',
          en: 'Is it possible to take lessons from overseas?',
          vn: 'Ở nước ngoài có thể tham gia bài học không?',
        },
        answer: {
          ja: 'サービス対応エリアでしたら、日本以外の国でもご利用いただけます。',
          en: 'The service is available in countries other than Japan as long as the service area is available.',
          vn: 'Nếu đó là khu vực được hỗ trợ dịch vụ, thì cho dù bạn ở các quốc gia khác ngoài Nhật Bản thì bạn vẫn có thể tham gia.',
        },
        isInquiry: false,
      },
      {
        question: {
          ja: '先生からのフィードバックはどこで見られますか。',
          en: 'Where can I see the feedback from the teacher?',
          vn: 'Tôi có thể tìm thông tin phản hồi từ giáo viên ở đâu?',
        },
        answer: {
          ja: '先生からのフィードバックは「レッスン」メニューの「過去のレッスン」の各レッスン履歴で確認できます。',
          en: "You can check the feedback from the teacher in each lesson history of 'Past lessons' in the 'Lessons' menu.",
          vn: 'Phản hồi từ giáo viên có thể được tìm thấy trong phần lịch sử bài học của 「Bài học trước đây」ở danh mục「Bài học」.',
        },
        isInquiry: false,
      },
      {
        question: {
          ja: 'レッスン中に、画面上に数字が表示されました。これは何ですか。',
          en: 'During a lesson, a number appears on the screen. What is this?',
          vn: 'Trong giờ học, đã có con số được hiển thị trên màn hình. Con số đó là gì?',
        },
        answer: {
          ja: '画面上に表示されている数字はレッスンの残り時間です。残り時間が1分未満になると、カウントが始まります。',
          en: 'The number displayed on the screen is the remaining time of the lesson. When the time remaining is less than 1 minute, it will start counting.',
          vn: 'Con số được hiển thị trên màn hình là thời gian còn lại của giờ học. Khi thời gian còn lại của giờ học chưa đến 1 phút, thì sẽ bắt đầu đếm.',
        },
        isInquiry: false,
      },
    ],
  },
  {
    category: { ja: 'トラブル', en: 'Trouble', vn: 'Sự cố' },
    qaList: [
      {
        question: {
          ja: '操作方法がわかりません。',
          en: "I don't know how to use the service.",
          vn: 'Tôi không hiểu cách thức thao tác.',
        },
        answer: {
          ja: 'ご迷惑おかけして大変申し訳ございません。まずはこのQ＆Aを確認してください。それでも解決しない場合は、お手数ですが「設定」のお問い合わせフォームより連絡をしてください。3営業日以内にサポートチームよりお返事させていただきます。',
          en: "We apologize for any inconvenience. Please check this Q&A first. If this still does not solve the issue, please contact us through the 'Settings' contact form. Our support team will endeavor to get back to you within 3 business days.",
          vn: 'Chúng tôi xin lỗi vì sự bất tiện này. Trước hết , vui lòng kiểm tra phần Hỏi & Đáp này. Trường hợp vẫn chưa hiểu được, vui lòng liên hệ với chúng tôi bằng cách sử dụng biểu mẫu yêu cầu trong phần「Cài đặt」. Đội ngũ hỗ trợ sẽ liên hệ lại với bạn trong vòng 3 ngày làm việc.',
        },
        isInquiry: true,
      },
      {
        question: {
          ja: 'トラブルがあった時はどこに連絡をすればいいですか。',
          en: 'Who should I contact if I have a problem?',
          vn: 'Khi gặp sự cố tôi nên liên lạc với ai thì được?',
        },
        answer: {
          ja: 'ご迷惑おかけして大変申し訳ございません。お手数ですが「設定」のお問い合わせフォームより連絡をしてください。3営業日以内にサポートチームよりお返事させていただきます。',
          en: "We apologize for any inconvenience. Please contact us through the 'Settings' contact form. Our support team will endeavor to get back to you within 3 business days.",
          vn: 'Chúng tôi xin lỗi vì sự bất tiện này. Vui lòng liên hệ với chúng tôi bằng cách sử dụng biểu mẫu yêu cầu trong phần「Cài đặt」. Đội ngũ hỗ trợ sẽ liên hệ lại với bạn trong vòng 3 ngày làm việc.',
        },
        isInquiry: true,
      },
      {
        question: {
          ja: 'レッスン中、先生の声が聞こえません。',
          en: "I can't hear the teacher's voice during the lesson.",
          vn: 'Trong giờ học, tôi không thể nghe thấy giọng của giáo viên.',
        },
        answer: {
          ja: 'お手元のヘッドホンやスマートフォンの音量や接続状況、通信状況をご確認ください。併せてチャットで先生に「声が聞こえません」と伝えてください。それでも解決しない場合は、お手数ですが「設定」のお問い合わせフォームより連絡をしてください。3営業日以内にサポートチームよりお返事させていただきます。',
          en: "Please check the volume and connection of your headphones or smart phone. Please tell the teacher in the chat room that you cannot hear him/her. If this still does not solve the issue, please contact us through the 'Settings' contact form. Our support team will endeavor to get back to you within 3 business days.",
          vn: 'Vui lòng kiểm tra âm lượng, trạng thái kết nối và trạng thái liên lạc của tai nghe và điện thoại thông minh của bạn. Đồng thời, hãy truyền đạt với giáo viên rằng「Tôi không thể nghe thấy」thông qua tính năng trò chuyện. Trường hợp sự cố vẫn tiếp diễn, vui lòng liên hệ với chúng tôi bằng cách sử dụng biểu mẫu yêu cầu trong phần「Cài đặt」. Đội ngũ hỗ trợ sẽ liên hệ lại với bạn trong vòng 3 ngày làm việc.',
        },
        isInquiry: true,
      },
      {
        question: {
          ja: 'レッスン中、先生に私の声が届きません。',
          en: 'My teacher cannot hear me during the lesson.',
          vn: 'Trong giờ học, giáo viên không nghe thấy giọng của tôi.',
        },
        answer: {
          ja: 'お手元のマイクやスマートフォンの音量や接続状況、通信状況をご確認ください。声が聞こえるかの確認の際はチャットも併せてご利用ください。それでも解決しない場合は、お手数ですが「設定」のお問い合わせフォームより連絡をしてください。3営業日以内にサポートチームよりお返事させていただきます。',
          en: "Please check the volume and connection of your headphones or smart phone. You can also use chat to check if your teacher can hear you. If this still does not solve the issue, please contact us through the 'Settings' contact form. Our support team will endeavor to get back to you within 3 business days.",
          vn: 'Vui lòng kiểm tra âm lượng, trạng thái kết nối và trạng thái liên lạc của micro và điện thoại thông minh của bạn. Hãy sử dụng tính năng trò chuyện để xác nhận xem giáo viên có thể nghe thấy giọng nói của bạn không. Trường hợp sự cố vẫn tiếp diễn, vui lòng liên hệ với chúng tôi bằng cách sử dụng biểu mẫu yêu cầu trong phần「Cài đặt」. Đội ngũ hỗ trợ sẽ liên hệ lại với bạn trong vòng 3 ngày làm việc.',
        },
        isInquiry: true,
      },
      {
        question: {
          ja: 'レッスン中に通信が切れてしまいました。',
          en: 'The communication was cut off during the lesson.',
          vn: 'Tôi bị mất tín hiệu trong giờ học.',
        },
        answer: {
          ja: 'ご利用中の通信機器の通信状況を確認してください。それでも解決しない場合は、お手数ですが「設定」のお問い合わせフォームより連絡をしてください。3営業日以内にサポートチームよりお返事させていただきます。',
          en: "Please check the connection of your phone or computer. If this still does not solve the issue, please contact us through the 'Settings' contact form. Our support team will endeavor to get back to you within 3 business days.",
          vn: 'Hãy kiểm tra trạng thái liên lạc của thiết bị liên lạc mà bạn đang sử dụng. Trường hợp sự cố vẫn tiếp diễn, vui lòng liên hệ với chúng tôi bằng cách sử dụng biểu mẫu yêu cầu trong phần「Cài đặt」. Đội ngũ hỗ trợ sẽ liên hệ lại với bạn trong vòng 3 ngày làm việc.',
        },
        isInquiry: true,
      },
    ],
  },
  {
    category: { ja: 'その他', en: 'Other', vn: 'Khác' },
    qaList: [
      {
        question: {
          ja: 'レッスンを予約すると、先生にどんな情報が伝わりますか。',
          en: 'What information will be disclosed to the teacher when I reserve a lesson?',
          vn: 'Khi tôi đăng ký bài học thì giáo viên nhận được những thông tin như thế nào?',
        },
        answer: {
          ja: 'レッスン予約が完了すると、予約した時に入力した予約希望日時、お客様のニックネームが先生方に伝わります。メールアドレス等のその他の個人情報は見られませんのでご安心ください。',
          en: 'When your lesson reservation is completed, we will tell the teachers the preferred date and time of the reservation and nickname. Please be assured that no other personal information, such as your email address, will be disclosed.',
          vn: 'Khi việc bảo lưu bài học của bạn hoàn tất, chúng tôi sẽ thông báo cho giáo viên ngày giờ bảo lưu và biệt danh tiên.Hãy yên tâm rằng người khác sẽ không thể xem những thông tin khác của bạn ví dụ như địa chỉ email.',
        },
        isInquiry: false,
      },
    ],
  },
];

export const getStoryListData = () => {
  const storyListData = {
    seasons: [
      {
        seasonNo: 1,
        episodes: [
          {
            episodeNo: 1,
            scenes: [
              {
                sceneNo: 1,
                text: '1-1',
              },
              {
                sceneNo: 2,
                text: '1-2',
              },
              {
                sceneNo: 3,
                text: '1-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード1まとめ',
              },
            ],
          },
          {
            episodeNo: 2,
            scenes: [
              {
                sceneNo: 1,
                text: '2-1',
              },
              {
                sceneNo: 2,
                text: '2-2',
              },
              {
                sceneNo: 3,
                text: '2-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード2まとめ',
              },
            ],
          },
          {
            episodeNo: 3,
            scenes: [
              {
                sceneNo: 1,
                text: '3-1',
              },
              {
                sceneNo: 2,
                text: '3-2',
              },
              {
                sceneNo: 3,
                text: '3-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード3まとめ',
              },
            ],
          },
          {
            episodeNo: 4,
            scenes: [
              {
                sceneNo: 1,
                text: '4-1',
              },
              {
                sceneNo: 2,
                text: '4-2',
              },
              {
                sceneNo: 3,
                text: '4-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード4まとめ',
              },
            ],
          },
          {
            episodeNo: 5,
            scenes: [
              {
                sceneNo: 1,
                text: '5-1',
              },
              {
                sceneNo: 2,
                text: '5-2',
              },
              {
                sceneNo: 3,
                text: '5-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード5まとめ',
              },
            ],
          },
          {
            episodeNo: 6,
            scenes: [
              {
                sceneNo: 1,
                text: '6-1',
              },
              {
                sceneNo: 2,
                text: '6-2',
              },
              {
                sceneNo: 3,
                text: '6-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード6まとめ',
              },
            ],
          },
          {
            episodeNo: 7,
            scenes: [
              {
                sceneNo: 1,
                text: '7-1',
              },
              {
                sceneNo: 2,
                text: '7-2',
              },
              {
                sceneNo: 3,
                text: '7-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード7まとめ',
              },
            ],
          },
          {
            episodeNo: 8,
            scenes: [
              {
                sceneNo: 1,
                text: '8-1',
              },
              {
                sceneNo: 2,
                text: '8-2',
              },
              {
                sceneNo: 3,
                text: '8-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード8まとめ',
              },
            ],
          },
          {
            episodeNo: 9,
            scenes: [
              {
                sceneNo: 1,
                text: '9-1',
              },
              {
                sceneNo: 2,
                text: '9-2',
              },
              {
                sceneNo: 3,
                text: '9-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード9まとめ',
              },
            ],
          },
          {
            episodeNo: 10,
            scenes: [
              {
                sceneNo: 1,
                text: '10-1',
              },
              {
                sceneNo: 2,
                text: '10-2',
              },
              {
                sceneNo: 3,
                text: '10-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード10まとめ',
              },
            ],
          },
          {
            episodeNo: 11,
            scenes: [
              {
                sceneNo: 1,
                text: '11-1',
              },
              {
                sceneNo: 2,
                text: '11-2',
              },
              {
                sceneNo: 3,
                text: '11-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード11まとめ',
              },
            ],
          },
          {
            episodeNo: 12,
            scenes: [
              {
                sceneNo: 1,
                text: '12-1',
              },
              {
                sceneNo: 2,
                text: '12-2',
              },
              {
                sceneNo: 3,
                text: '12-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード12まとめ',
              },
            ],
          },
          {
            episodeNo: 13,
            scenes: [
              {
                sceneNo: 1,
                text: '13-1',
              },
              {
                sceneNo: 2,
                text: '13-2',
              },
              {
                sceneNo: 3,
                text: '13-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード13まとめ',
              },
            ],
          },
          {
            episodeNo: 14,
            scenes: [
              {
                sceneNo: 1,
                text: '14-1',
              },
              {
                sceneNo: 2,
                text: '14-2',
              },
              {
                sceneNo: 3,
                text: '14-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード14まとめ',
              },
            ],
          },
          {
            episodeNo: 15,
            scenes: [
              {
                sceneNo: 1,
                text: '15-1',
              },
              {
                sceneNo: 2,
                text: '15-2',
              },
              {
                sceneNo: 3,
                text: '15-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード15まとめ',
              },
            ],
          },
          {
            episodeNo: 16,
            scenes: [
              {
                sceneNo: 1,
                text: '16-1',
              },
              {
                sceneNo: 2,
                text: '16-2',
              },
              {
                sceneNo: 3,
                text: '16-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード16まとめ',
              },
            ],
          },
          {
            episodeNo: 0,
            scenes: [
              {
                sceneNo: 1,
                text: 'シーズン１まとめ',
              },
            ],
          },
        ],
      },
      {
        seasonNo: 2,
        episodes: [
          {
            episodeNo: 17,
            scenes: [
              {
                sceneNo: 1,
                text: '17-1',
              },
              {
                sceneNo: 2,
                text: '17-2',
              },
              {
                sceneNo: 3,
                text: '17-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード17まとめ',
              },
            ],
          },
          {
            episodeNo: 18,
            scenes: [
              {
                sceneNo: 1,
                text: '18-1',
              },
              {
                sceneNo: 2,
                text: '18-2',
              },
              {
                sceneNo: 3,
                text: '2-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード2まとめ',
              },
            ],
          },
          {
            episodeNo: 19,
            scenes: [
              {
                sceneNo: 1,
                text: '19-1',
              },
              {
                sceneNo: 2,
                text: '19-2',
              },
              {
                sceneNo: 3,
                text: '19-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード19まとめ',
              },
            ],
          },
          {
            episodeNo: 20,
            scenes: [
              {
                sceneNo: 1,
                text: '20-1',
              },
              {
                sceneNo: 2,
                text: '20-2',
              },
              {
                sceneNo: 3,
                text: '20-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード20まとめ',
              },
            ],
          },
          {
            episodeNo: 21,
            scenes: [
              {
                sceneNo: 1,
                text: '21-1',
              },
              {
                sceneNo: 2,
                text: '21-2',
              },
              {
                sceneNo: 3,
                text: '21-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード21まとめ',
              },
            ],
          },
          {
            episodeNo: 22,
            scenes: [
              {
                sceneNo: 1,
                text: '22-1',
              },
              {
                sceneNo: 2,
                text: '22-2',
              },
              {
                sceneNo: 3,
                text: '22-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード22まとめ',
              },
            ],
          },
          {
            episodeNo: 23,
            scenes: [
              {
                sceneNo: 1,
                text: '23-1',
              },
              {
                sceneNo: 2,
                text: '23-2',
              },
              {
                sceneNo: 3,
                text: '23-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード23まとめ',
              },
            ],
          },
          {
            episodeNo: 24,
            scenes: [
              {
                sceneNo: 1,
                text: '24-1',
              },
              {
                sceneNo: 2,
                text: '24-2',
              },
              {
                sceneNo: 3,
                text: '24-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード24まとめ',
              },
            ],
          },
          {
            episodeNo: 25,
            scenes: [
              {
                sceneNo: 1,
                text: '25-1',
              },
              {
                sceneNo: 2,
                text: '25-2',
              },
              {
                sceneNo: 3,
                text: '25-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード25まとめ',
              },
            ],
          },
          {
            episodeNo: 26,
            scenes: [
              {
                sceneNo: 1,
                text: '26-1',
              },
              {
                sceneNo: 2,
                text: '26-2',
              },
              {
                sceneNo: 3,
                text: '26-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード26まとめ',
              },
            ],
          },
          {
            episodeNo: 27,
            scenes: [
              {
                sceneNo: 1,
                text: '27-1',
              },
              {
                sceneNo: 2,
                text: '27-2',
              },
              {
                sceneNo: 3,
                text: '27-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード27まとめ',
              },
            ],
          },
          {
            episodeNo: 28,
            scenes: [
              {
                sceneNo: 1,
                text: '28-1',
              },
              {
                sceneNo: 2,
                text: '28-2',
              },
              {
                sceneNo: 3,
                text: '28-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード28まとめ',
              },
            ],
          },
          {
            episodeNo: 29,
            scenes: [
              {
                sceneNo: 1,
                text: '29-1',
              },
              {
                sceneNo: 2,
                text: '29-2',
              },
              {
                sceneNo: 3,
                text: '29-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード29まとめ',
              },
            ],
          },
          {
            episodeNo: 30,
            scenes: [
              {
                sceneNo: 1,
                text: '30-1',
              },
              {
                sceneNo: 2,
                text: '30-2',
              },
              {
                sceneNo: 3,
                text: '30-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード30まとめ',
              },
            ],
          },
          {
            episodeNo: 31,
            scenes: [
              {
                sceneNo: 1,
                text: '31-1',
              },
              {
                sceneNo: 2,
                text: '31-2',
              },
              {
                sceneNo: 3,
                text: '31-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード31まとめ',
              },
            ],
          },
          {
            episodeNo: 32,
            scenes: [
              {
                sceneNo: 1,
                text: '32-1',
              },
              {
                sceneNo: 2,
                text: '32-2',
              },
              {
                sceneNo: 3,
                text: '32-3',
              },
              {
                sceneNo: 4,
                text: 'エピソード32まとめ',
              },
            ],
          },
          {
            episodeNo: 0,
            scenes: [
              {
                sceneNo: 1,
                text: 'シーズン2まとめ',
              },
            ],
          },
        ],
      },
    ],
  };
  return storyListData;
};
