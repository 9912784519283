export const IS_PRD = process.env.NODE_ENV === 'production';
export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_STG = (process.env.NODE_ENV as any) === 'staging';
// TODO ポート番号　別で定義
export const BASE_URL = IS_DEV ? 'http://localhost:3100' : process.env.REACT_APP_BASE_URL ?? '';
export const REACT_APP_LEARNER_URL = IS_DEV
  ? 'http://localhost:' + process.env.REACT_APP_LEARNER_PORT
  : process.env.REACT_APP_LEARNER_URL ?? '';
export const REACT_APP_API_BASE_URL = IS_DEV
  ? `http://localhost:${process.env.REACT_APP_SERVER_APP_PORT}`
  : process.env.REACT_APP_API_BASE_URL;
export const REACT_APP_ONLINE_LESSON_LEARNER_URL = IS_DEV
  ? 'http://localhost:5000'
  : process.env.REACT_APP_ONLINE_LESSON_LEARNER_URL ?? '';
export const REACT_APP_COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
export const REACT_APP_COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID_FOR_LEARNER;
export const REACT_APP_COGNITO_USER_POOL_CLIENT_ID = process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID_FOR_LEARNER;
// TODO ログインを仮で企業アカウントからするように修正。運営アカウントから作成してメール通知でログインするフローができたら戻す
export const REACT_APP_SERVER_GMO_SHOP_ID = process.env.REACT_APP_SERVER_GMO_SHOP_ID;
export const REACT_APP_SERVER_GMO_TOKEN_URL = process.env.REACT_APP_SERVER_GMO_TOKEN_URL;
export const REACT_APP_TERMS_OF_SERVICE_URL = process.env.REACT_APP_TERMS_OF_SERVICE_URL;
export const REACT_APP_PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL;
export const REACT_APP_SPECIFIED_COMMERCIAL_URL = process.env.REACT_APP_SPECIFIED_COMMERCIAL_URL;
export const REACT_APP_CHAT_WEBSOCKET_URL = process.env.REACT_APP_CHAT_WEBSOCKET_URL ?? '';
export const COOKIE_DOMAIN = IS_DEV ? 'localhost' : process.env.REACT_APP_LEARNER_URL!.match(/(?<=https:\/\/).*/)![0];
export const DELETE_FLG = {
  // 削除
  DELETED: '1',
  // 未削除
  AVAILABLE: '0',
} as const;
export type DELETE_FLG_TYPE = typeof DELETE_FLG[keyof typeof DELETE_FLG];
export const STOP_FLG = {
  // 停止
  STOPPED: '1',
  // 未停止
  AVAILABLE: '0',
} as const;
export type STOP_FLG_TYPE = typeof STOP_FLG[keyof typeof STOP_FLG];
export type QUERY_RESULT = number & { QUERY_RESULT: never };
export const MESSAGE = {
  NO_ERROR: 'NO_ERROR',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  NOT_AUTHORIZED_USER_ACCESS: 'NOT_AUTHORIZED_USER_ACCESS',
} as const;
export type MESSAGE_TYPE = typeof MESSAGE[keyof typeof MESSAGE] | QUERY_RESULT;

export const SERVER_WEBPUSH_PUBLIC_KEY = process.env.SERVER_WEBPUSH_PUBLIC_KEY;
export const HIDDEN_PAYPAY = true;
//Push通知が活用化
export const ACTIVE_FLG = {
  // 活用
  ACTIVE: '1',
  // 未活用
  INACTIVE: '0',
} as const;
export const MAX_EMAIL_CHARS = 3000;

//Total count for season, chapter, summary
export const TOTAL_COUNT = {
  CHAPTER: 10,
  CHAPTER_SUMMARY: 12,
  SEASON_SUMMARY: 50,
};

export const PATH_NAME = {
  SELF_STUDY: '/self_study',
  HIRAGANA_A_N: '/hiragana_a-n',
  HIRAGANA_GA_PO: '/hiragana_ga-po',
  HIRAGANA_KYA_PYO: '/hiragana_kya-pyo',
  KATAKANA_A_N: '/katakana_a-n',
  KATAKANA_GA_PO: '/katakana_ga-po',
  KATAKANA_KYA_PYO: '/katakana_kya-pyo',
};

// ひらがなカタカナ
export const BASE_URL_KANA = 'https://sun-resource-tokyo.s3-ap-northeast-1.amazonaws.com/self_study/kana/audio/';
export const S3_PATH = 'https://sun-resource-tokyo.s3-ap-northeast-1.amazonaws.com/';
export const KURASHI_STUDY_API_PATH = 'https://api-manager.kurashi-study.net/cms/';

export const STORY_EPISODE_ID_SECTION_INDEX = {
  'Ep.x-1': 0,
  'Ep.x-2': 1,
  'Ep.x-3': 2,
  'Ep.x summary': 3,
} as const;
