import { createContext, ReactNode, useEffect, useState } from 'react';
import { requestServerApi, serverApi } from '../../api/api';
import { useAppSelector } from '../../hooks';
import { AuthState } from '@aws-amplify/ui-components';
import useUserEvent from '../../hooks/useUserEvent/useUserEvent';
import UnderMaintenanceModal from '../Modal/UnderMaintenanceModal/UnderMaintenanceModal';
import { AxiosResponse } from 'axios';
import { CreateActiveLearnerLogResponse, GetLearnerByIdResponse } from '../../api/server';

type RouteProvideProps = {
  children: ReactNode;
};
/**
 * 画面遷移Wrapper
 *
 * 画面遷移の前に処理を実施する
 *
 * NOTE：
 * Shell.tsxよりも上の階層で動作する共通コンポーネントがなかったため、このファイルを追加
 */
const RouteWrapper = ({ children }: RouteProvideProps) => {
  const userState = useAppSelector((state) => state.user);
  const { sendUserEvent, sendUserEventByNotAuth } = useUserEvent();
  useEffect(() => {
    // FIXME stateとAUthのcurrentSessionで取得するのではどちらが最適か？
    if (userState.authState === AuthState.SignedIn && userState.user) {
      // FIXME アクティブユーザー更新の処理もhooksとかに分けたほうがいいかも？
      const username = userState.user['cognito:username'];
      requestServerApi<Promise<AxiosResponse<CreateActiveLearnerLogResponse>>>(() => serverApi.mobileAppControllerCreateActiveLearnerLog(username));
      requestServerApi<any>(() => serverApi.organizationsControllerGetOperationalStatus()).then((result) => {
        if (result?.data?.data.length > 0 && result.data.data[0].status === true) {
          setSelectedModal('UnderMaintenanceModal');
          setSelectedItem(result.data.data[0].data);
        }
      });
      sendUserEvent('page_view');
    } else {
      sendUserEventByNotAuth('page_view');
    }
  }, [userState]);
  const [selectedModal, setSelectedModal] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<any>('');

  return (
    <>
      {children}
      {'UnderMaintenanceModal' === selectedModal && (
        <UnderMaintenanceModal maintenances={selectedItem} />
      )}
    </>
  );
};
export default RouteWrapper;
