import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { REACT_APP_COGNITO_USER_POOL_CLIENT_ID } from '../../configs';
import { ERROR_CODE } from '../../constant/errorCode';
import { PATH_NAME } from '../../constant/location';
import { useAppDispatch } from '../../hooks';
import { signOutAsync } from '../../slice/userSlice';
import { cognitoUtils } from '../../utils/auth/cognitoUtils';
import { cookieUtils } from '../../utils/storage/cookieUtils';
import useError from '../useError/useError';
const useSignOut = () => {
  const { sendErrorLog } = useError();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const handleSignOut = useCallback(async () => {
    try {
      // Cognitoのサインアウト（Cookie削除）、Stateからユーザー情報削除
      await Promise.all([
        cognitoUtils.signOut().catch((error) => {
          const cognitoCookies = cookieUtils
            .cookieAnalize(document.cookie)
            .searchCookies(`CognitoIdentityServiceProvider.${REACT_APP_COGNITO_USER_POOL_CLIENT_ID}`);
          cognitoCookies.forEach((cookie) => {
            const cookieKey = cookie.split('=')[0];
            cookieUtils.removeCookie(cookieKey);
          });
          sendErrorLog({
            error,
            errorCode: ERROR_CODE.HTTP_401,
            message: `${PATH_NAME.TOP}で異常発生。Cognitoサインアウトも失敗したため、Cookieの直接削除を実行しました。`,
          });
        }),
        dispatch(signOutAsync()),
      ]);
      history.push(`/${PATH_NAME.SIGNIN}`);
    } catch (error) {
      console.error('Error signing out:', error);
      sendErrorLog({
        error: error,
        errorCode: ERROR_CODE.E_9999,
        message: 'Error signing out:',
      });
    }
  }, [history]);
  return { handleSignOut };
};
export default useSignOut;
