import { createContext, useState } from 'react';

export type GloabalAudioManagerContextType = {
  audioInstace: HTMLAudioElement;
};
export const defaultGloabalAudioManagerContext: GloabalAudioManagerContextType = {
  audioInstace: new Audio(),
};
export const GloabalAudioManager = createContext<GloabalAudioManagerContextType>(defaultGloabalAudioManagerContext);
export const useAudioUpdate = (): GloabalAudioManagerContextType => {
  const [audio] = useState(new Audio());
  return { audioInstace: audio };
};
