/* tslint:disable */
/* eslint-disable */
/**
 * くらしスタディAPI仕様
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CancelLessonInput
 */
export interface CancelLessonInput {
    /**
     * 
     * @type {object}
     * @memberof CancelLessonInput
     */
    cancelReason: object;
}
/**
 * 
 * @export
 * @interface ConnectionId
 */
export interface ConnectionId {
    /**
     * 
     * @type {string}
     * @memberof ConnectionId
     */
    room_id: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionId
     */
    connection_id: string;
}
/**
 * 
 * @export
 * @interface CreateActiveLearnerLogResponse
 */
export interface CreateActiveLearnerLogResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateActiveLearnerLogResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof CreateActiveLearnerLogResponse
     */
    message: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateActiveLearnerLogResponse
     */
    data?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateAgreementInput
 */
export interface CreateAgreementInput {
    /**
     * 
     * @type {string}
     * @memberof CreateAgreementInput
     */
    plan_name: string;
}
/**
 * 
 * @export
 * @interface CreateCompanyInput
 */
export interface CreateCompanyInput {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyInput
     */
    company_name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyInput
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyInput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyInput
     */
    representative: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyInput
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyInput
     */
    main_bank_info: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyInput
     */
    main_bank_account: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyInput
     */
    transfer_bank_info: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyInput
     */
    transfer_bank_account: string;
}
/**
 * 
 * @export
 * @interface CreateCompanyUserInput
 */
export interface CreateCompanyUserInput {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyUserInput
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyUserInput
     */
    email: string;
}
/**
 * 
 * @export
 * @interface CreateErrorLog
 */
export interface CreateErrorLog {
    /**
     * 
     * @type {string}
     * @memberof CreateErrorLog
     */
    source: string;
    /**
     * 
     * @type {string}
     * @memberof CreateErrorLog
     */
    currentPage: string;
    /**
     * 
     * @type {string}
     * @memberof CreateErrorLog
     */
    userAgent: string;
    /**
     * 
     * @type {string}
     * @memberof CreateErrorLog
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof CreateErrorLog
     */
    errorCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateErrorLog
     */
    error: string;
}
/**
 * 
 * @export
 * @interface CreateInquiryInput
 */
export interface CreateInquiryInput {
    /**
     * 
     * @type {string}
     * @memberof CreateInquiryInput
     */
    company_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInquiryInput
     */
    company_home_page_link?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInquiryInput
     */
    identity?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInquiryInput
     */
    identity_hurigana?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInquiryInput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInquiryInput
     */
    telephone_no: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInquiryInput
     */
    inquiry_details: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInquiryInput
     */
    personal_info_permission: boolean;
}
/**
 * 
 * @export
 * @interface CreateLearnerInput
 */
export interface CreateLearnerInput {
    /**
     * 
     * @type {string}
     * @memberof CreateLearnerInput
     */
    learner_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLearnerInput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLearnerInput
     */
    full_name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLearnerInput
     */
    nick_name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLearnerInput
     */
    initial_password?: string;
}
/**
 * 
 * @export
 * @interface CreateLessonEnteringLogLearnerResponse
 */
export interface CreateLessonEnteringLogLearnerResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateLessonEnteringLogLearnerResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof CreateLessonEnteringLogLearnerResponse
     */
    message: object;
    /**
     * 
     * @type {TLessonLearnerLogs}
     * @memberof CreateLessonEnteringLogLearnerResponse
     */
    data?: TLessonLearnerLogs;
}
/**
 * 
 * @export
 * @interface CreateLessonFeeHistoryInput
 */
export interface CreateLessonFeeHistoryInput {
    /**
     * 
     * @type {string}
     * @memberof CreateLessonFeeHistoryInput
     */
    teacher_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLessonFeeHistoryInput
     */
    year_month: string;
    /**
     * 
     * @type {number}
     * @memberof CreateLessonFeeHistoryInput
     */
    billed_flg: number;
}
/**
 * 
 * @export
 * @interface CreateLessonFeeInput
 */
export interface CreateLessonFeeInput {
    /**
     * 
     * @type {string}
     * @memberof CreateLessonFeeInput
     */
    teacher_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLessonFeeInput
     */
    year_month: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLessonFeeInput
     */
    item_name: string;
    /**
     * 
     * @type {number}
     * @memberof CreateLessonFeeInput
     */
    unit_price: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLessonFeeInput
     */
    item_count: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLessonFeeInput
     */
    total_amount: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLessonFeeInput
     */
    tax_rate: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLessonFeeInput
     */
    billing_amount: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLessonFeeInput
     */
    total_option_fee: number;
}
/**
 * 
 * @export
 * @interface CreateLessonInput
 */
export interface CreateLessonInput {
    /**
     * 
     * @type {number}
     * @memberof CreateLessonInput
     */
    company_id: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLessonInput
     */
    payment_id: number;
}
/**
 * 
 * @export
 * @interface CreateMaintenanceInput
 */
export interface CreateMaintenanceInput {
    /**
     * 
     * @type {string}
     * @memberof CreateMaintenanceInput
     */
    maintenance_title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMaintenanceInput
     */
    maintenance_detail: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateMaintenanceInput
     */
    immediate_flg: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateMaintenanceInput
     */
    start_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMaintenanceInput
     */
    end_datetime: string;
}
/**
 * 
 * @export
 * @interface CreateManagerInput
 */
export interface CreateManagerInput {
    /**
     * 
     * @type {string}
     * @memberof CreateManagerInput
     */
    full_name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateManagerInput
     */
    email: string;
}
/**
 * 
 * @export
 * @interface CreateNoticeCompanyInput
 */
export interface CreateNoticeCompanyInput {
    /**
     * 
     * @type {number}
     * @memberof CreateNoticeCompanyInput
     */
    company_id: number;
    /**
     * 
     * @type {number}
     * @memberof CreateNoticeCompanyInput
     */
    payment_id?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateNoticeCompanyInput
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoticeCompanyInput
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoticeCompanyInput
     */
    start_datetime: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNoticeCompanyInput
     */
    end_datetime: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNoticeCompanyInput
     */
    target: string;
}
/**
 * 
 * @export
 * @interface CreateNoticeInput
 */
export interface CreateNoticeInput {
    /**
     * 
     * @type {string}
     * @memberof CreateNoticeInput
     */
    target_num: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoticeInput
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoticeInput
     */
    notice: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoticeInput
     */
    start_datetime: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNoticeInput
     */
    end_datetime: string | null;
}
/**
 * 
 * @export
 * @interface CreateRoomChatInput
 */
export interface CreateRoomChatInput {
    /**
     * 
     * @type {string}
     * @memberof CreateRoomChatInput
     */
    lesson_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomChatInput
     */
    sender_id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomChatInput
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoomChatInput
     */
    receiver_id: string;
}
/**
 * 
 * @export
 * @interface CreateTeacherInput
 */
export interface CreateTeacherInput {
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherInput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherInput
     */
    full_name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherInput
     */
    nick_name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherInput
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherInput
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeacherInput
     */
    memo: string;
}
/**
 * 
 * @export
 * @interface DeactivateLearnerEndPointsResponse
 */
export interface DeactivateLearnerEndPointsResponse {
    /**
     * 
     * @type {number}
     * @memberof DeactivateLearnerEndPointsResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof DeactivateLearnerEndPointsResponse
     */
    message: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeactivateLearnerEndPointsResponse
     */
    data?: Array<string>;
}
/**
 * 
 * @export
 * @interface DrillParam
 */
export interface DrillParam {
    /**
     * 
     * @type {number}
     * @memberof DrillParam
     */
    drill_id: number;
    /**
     * 
     * @type {string}
     * @memberof DrillParam
     */
    drill_name: string;
}
/**
 * 
 * @export
 * @interface ExistsLearnerInPlanInput
 */
export interface ExistsLearnerInPlanInput {
    /**
     * 
     * @type {string}
     * @memberof ExistsLearnerInPlanInput
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof ExistsLearnerInPlanInput
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof ExistsLearnerInPlanInput
     */
    paymentId: number;
}
/**
 * 
 * @export
 * @interface ExistsLearnerInput
 */
export interface ExistsLearnerInput {
    /**
     * 
     * @type {string}
     * @memberof ExistsLearnerInput
     */
    email: string;
}
/**
 * 
 * @export
 * @interface GetApprovedLessonsResponse
 */
export interface GetApprovedLessonsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetApprovedLessonsResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof GetApprovedLessonsResponse
     */
    message: object;
    /**
     * 
     * @type {LessonList}
     * @memberof GetApprovedLessonsResponse
     */
    data?: LessonList;
}
/**
 * 
 * @export
 * @interface GetLearnerActiveEndPointCountResponse
 */
export interface GetLearnerActiveEndPointCountResponse {
    /**
     * 
     * @type {number}
     * @memberof GetLearnerActiveEndPointCountResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof GetLearnerActiveEndPointCountResponse
     */
    message: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetLearnerActiveEndPointCountResponse
     */
    data?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetLearnerByEmailResponse
 */
export interface GetLearnerByEmailResponse {
    /**
     * 
     * @type {number}
     * @memberof GetLearnerByEmailResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof GetLearnerByEmailResponse
     */
    message: object;
    /**
     * 
     * @type {MLearner}
     * @memberof GetLearnerByEmailResponse
     */
    data?: MLearner;
}
/**
 * 
 * @export
 * @interface GetLearnerByIdResponse
 */
export interface GetLearnerByIdResponse {
    /**
     * 
     * @type {number}
     * @memberof GetLearnerByIdResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof GetLearnerByIdResponse
     */
    message: object;
    /**
     * 
     * @type {MLearner}
     * @memberof GetLearnerByIdResponse
     */
    data?: MLearner;
}
/**
 * 
 * @export
 * @interface GetLearnerDetailsResponse
 */
export interface GetLearnerDetailsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetLearnerDetailsResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof GetLearnerDetailsResponse
     */
    message: object;
    /**
     * 
     * @type {MLearner}
     * @memberof GetLearnerDetailsResponse
     */
    data?: MLearner;
}
/**
 * 
 * @export
 * @interface GetMostRecentScheduledLessonResponse
 */
export interface GetMostRecentScheduledLessonResponse {
    /**
     * 
     * @type {number}
     * @memberof GetMostRecentScheduledLessonResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof GetMostRecentScheduledLessonResponse
     */
    message: object;
    /**
     * 
     * @type {SingleLesson}
     * @memberof GetMostRecentScheduledLessonResponse
     */
    data?: SingleLesson;
}
/**
 * 
 * @export
 * @interface GetNotUpdatedNoteLessonsResponse
 */
export interface GetNotUpdatedNoteLessonsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetNotUpdatedNoteLessonsResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof GetNotUpdatedNoteLessonsResponse
     */
    message: object;
    /**
     * 
     * @type {LessonList}
     * @memberof GetNotUpdatedNoteLessonsResponse
     */
    data?: LessonList;
}
/**
 * 
 * @export
 * @interface GetNoticeReadResponse
 */
export interface GetNoticeReadResponse {
    /**
     * 
     * @type {number}
     * @memberof GetNoticeReadResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof GetNoticeReadResponse
     */
    message: object;
    /**
     * 
     * @type {object}
     * @memberof GetNoticeReadResponse
     */
    data?: object;
}
/**
 * 
 * @export
 * @interface GetNoticeUnreadCountResponse
 */
export interface GetNoticeUnreadCountResponse {
    /**
     * 
     * @type {number}
     * @memberof GetNoticeUnreadCountResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof GetNoticeUnreadCountResponse
     */
    message: object;
    /**
     * 
     * @type {Response}
     * @memberof GetNoticeUnreadCountResponse
     */
    data?: Response;
}
/**
 * 
 * @export
 * @interface GetNoticesResponse
 */
export interface GetNoticesResponse {
    /**
     * 
     * @type {number}
     * @memberof GetNoticesResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof GetNoticesResponse
     */
    message: object;
    /**
     * 
     * @type {Array<number | Array<object>>}
     * @memberof GetNoticesResponse
     */
    data?: Array<number | Array<object>>;
}
/**
 * 
 * @export
 * @interface GetPastLessonsResponse
 */
export interface GetPastLessonsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetPastLessonsResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof GetPastLessonsResponse
     */
    message: object;
    /**
     * 
     * @type {LessonList}
     * @memberof GetPastLessonsResponse
     */
    data?: LessonList;
}
/**
 * 
 * @export
 * @interface GetPushMsgLogResponse
 */
export interface GetPushMsgLogResponse {
    /**
     * 
     * @type {number}
     * @memberof GetPushMsgLogResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof GetPushMsgLogResponse
     */
    message: object;
    /**
     * 
     * @type {TPushMsgLog}
     * @memberof GetPushMsgLogResponse
     */
    data?: TPushMsgLog;
}
/**
 * 
 * @export
 * @interface GetPushMsgResponse
 */
export interface GetPushMsgResponse {
    /**
     * 
     * @type {number}
     * @memberof GetPushMsgResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof GetPushMsgResponse
     */
    message: object;
    /**
     * 
     * @type {TPushMsgQue}
     * @memberof GetPushMsgResponse
     */
    data?: TPushMsgQue;
}
/**
 * 
 * @export
 * @interface GetPushVapidPublicKeyResponse
 */
export interface GetPushVapidPublicKeyResponse {
    /**
     * 
     * @type {number}
     * @memberof GetPushVapidPublicKeyResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof GetPushVapidPublicKeyResponse
     */
    message: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPushVapidPublicKeyResponse
     */
    data?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetStudyRecordsResponse
 */
export interface GetStudyRecordsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetStudyRecordsResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof GetStudyRecordsResponse
     */
    message: object;
    /**
     * 
     * @type {StudyRecordsStoryList}
     * @memberof GetStudyRecordsResponse
     */
    data?: StudyRecordsStoryList;
}
/**
 * 
 * @export
 * @interface GetUnApprovedLessonsResponse
 */
export interface GetUnApprovedLessonsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetUnApprovedLessonsResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof GetUnApprovedLessonsResponse
     */
    message: object;
    /**
     * 
     * @type {LessonList}
     * @memberof GetUnApprovedLessonsResponse
     */
    data?: LessonList;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    message?: string;
    /**
     * 
     * @type {InlineResponse200Data}
     * @memberof InlineResponse200
     */
    data?: InlineResponse200Data;
}
/**
 * 
 * @export
 * @interface InlineResponse200Data
 */
export interface InlineResponse200Data {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse200Data
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200Data
     */
    message?: string;
    /**
     * 
     * @type {object}
     * @memberof InlineResponse200Data
     */
    data?: object;
}
/**
 * 
 * @export
 * @interface LearnerEndpoint
 */
export interface LearnerEndpoint {
    /**
     * 
     * @type {string}
     * @memberof LearnerEndpoint
     */
    endpoint: string;
    /**
     * 
     * @type {string}
     * @memberof LearnerEndpoint
     */
    webpush_object: string;
    /**
     * 
     * @type {string}
     * @memberof LearnerEndpoint
     */
    language_code: string;
    /**
     * 
     * @type {string}
     * @memberof LearnerEndpoint
     */
    active_flg: string;
}
/**
 * 
 * @export
 * @interface LearnerLessonLogInput
 */
export interface LearnerLessonLogInput {
    /**
     * 
     * @type {number}
     * @memberof LearnerLessonLogInput
     */
    eventTimestamp: number;
}
/**
 * 
 * @export
 * @interface LessonList
 */
export interface LessonList {
    /**
     * 
     * @type {Array<SingleLesson>}
     * @memberof LessonList
     */
    lessons: Array<SingleLesson>;
    /**
     * 
     * @type {number}
     * @memberof LessonList
     */
    total: number;
}
/**
 * 
 * @export
 * @interface LoggingInput
 */
export interface LoggingInput {
    /**
     * 
     * @type {string}
     * @memberof LoggingInput
     */
    currentPage: string;
    /**
     * 
     * @type {string}
     * @memberof LoggingInput
     */
    action: string;
    /**
     * 
     * @type {number}
     * @memberof LoggingInput
     */
    timestampMillis: number;
    /**
     * 
     * @type {string}
     * @memberof LoggingInput
     */
    userAgent: string;
    /**
     * 
     * @type {string}
     * @memberof LoggingInput
     */
    options: string;
}
/**
 * 
 * @export
 * @interface MCompany
 */
export interface MCompany {
    /**
     * 
     * @type {number}
     * @memberof MCompany
     */
    company_id: number;
    /**
     * 
     * @type {string}
     * @memberof MCompany
     */
    create_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof MCompany
     */
    create_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof MCompany
     */
    update_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof MCompany
     */
    update_user_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof MCompany
     */
    stop_flg: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MCompany
     */
    delete_flg: boolean;
    /**
     * 
     * @type {string}
     * @memberof MCompany
     */
    company_name: string;
    /**
     * 
     * @type {string}
     * @memberof MCompany
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MCompany
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MCompany
     */
    representative?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MCompany
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MCompany
     */
    main_bank_info?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MCompany
     */
    main_bank_account?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MCompany
     */
    trransfer_bank_info?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MCompany
     */
    trransfer_bank_account?: string | null;
}
/**
 * 
 * @export
 * @interface MLearner
 */
export interface MLearner {
    /**
     * 
     * @type {MCompany}
     * @memberof MLearner
     */
    m_company?: MCompany | null;
    /**
     * 
     * @type {number}
     * @memberof MLearner
     */
    company_id: number;
    /**
     * 
     * @type {string}
     * @memberof MLearner
     */
    learner_id: string;
    /**
     * 
     * @type {string}
     * @memberof MLearner
     */
    create_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof MLearner
     */
    create_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof MLearner
     */
    update_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof MLearner
     */
    update_user_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof MLearner
     */
    stop_flg: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MLearner
     */
    delete_flg: boolean;
    /**
     * 
     * @type {string}
     * @memberof MLearner
     */
    nick_name: string;
    /**
     * 
     * @type {string}
     * @memberof MLearner
     */
    full_name: string;
    /**
     * 
     * @type {string}
     * @memberof MLearner
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof MLearner
     */
    email_send_flg: boolean;
    /**
     * 
     * @type {string}
     * @memberof MLearner
     */
    last_login_datetime?: string | null;
}
/**
 * 
 * @export
 * @interface MTeacher
 */
export interface MTeacher {
    /**
     * 
     * @type {string}
     * @memberof MTeacher
     */
    teacher_id: string;
    /**
     * 
     * @type {string}
     * @memberof MTeacher
     */
    create_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof MTeacher
     */
    create_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof MTeacher
     */
    update_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof MTeacher
     */
    update_user_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof MTeacher
     */
    stop_flg: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MTeacher
     */
    delete_flg: boolean;
    /**
     * 
     * @type {string}
     * @memberof MTeacher
     */
    nick_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MTeacher
     */
    full_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MTeacher
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof MTeacher
     */
    rank_id: number;
    /**
     * 
     * @type {string}
     * @memberof MTeacher
     */
    bill_to?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MTeacher
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof MTeacher
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof MTeacher
     */
    memo: string;
}
/**
 * 
 * @export
 * @interface PostStudyRecordStoryResponse
 */
export interface PostStudyRecordStoryResponse {
    /**
     * 
     * @type {number}
     * @memberof PostStudyRecordStoryResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof PostStudyRecordStoryResponse
     */
    message: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostStudyRecordStoryResponse
     */
    data: Array<string>;
}
/**
 * 
 * @export
 * @interface PushMsg
 */
export interface PushMsg {
    /**
     * 
     * @type {number}
     * @memberof PushMsg
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PushMsg
     */
    target_type: string;
    /**
     * 
     * @type {string}
     * @memberof PushMsg
     */
    target_id: string;
    /**
     * 
     * @type {string}
     * @memberof PushMsg
     */
    language_code: string;
    /**
     * 
     * @type {string}
     * @memberof PushMsg
     */
    msg_type: string;
    /**
     * 
     * @type {string}
     * @memberof PushMsg
     */
    msg: string;
    /**
     * 
     * @type {string}
     * @memberof PushMsg
     */
    send_at: string;
    /**
     * 
     * @type {string}
     * @memberof PushMsg
     */
    sent_at: string;
}
/**
 * 
 * @export
 * @interface PushMsgLog
 */
export interface PushMsgLog {
    /**
     * 
     * @type {number}
     * @memberof PushMsgLog
     */
    push_msg_q_id: number;
    /**
     * 
     * @type {string}
     * @memberof PushMsgLog
     */
    learner_id: string;
    /**
     * 
     * @type {string}
     * @memberof PushMsgLog
     */
    endpoint: string;
    /**
     * 
     * @type {number}
     * @memberof PushMsgLog
     */
    http_result: number;
}
/**
 * 
 * @export
 * @interface PutDrillRecordResponse
 */
export interface PutDrillRecordResponse {
    /**
     * 
     * @type {number}
     * @memberof PutDrillRecordResponse
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof PutDrillRecordResponse
     */
    message: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PutDrillRecordResponse
     */
    data: Array<string>;
}
/**
 * 
 * @export
 * @interface PutNoticeReadResponse
 */
export interface PutNoticeReadResponse {
    /**
     * 
     * @type {number}
     * @memberof PutNoticeReadResponse
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof PutNoticeReadResponse
     */
    message: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PutNoticeReadResponse
     */
    data?: Array<string>;
}
/**
 * 
 * @export
 * @interface PutStudyRecordRecordResponse
 */
export interface PutStudyRecordRecordResponse {
    /**
     * 
     * @type {number}
     * @memberof PutStudyRecordRecordResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof PutStudyRecordRecordResponse
     */
    message: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof PutStudyRecordRecordResponse
     */
    data: Array<string>;
}
/**
 * 
 * @export
 * @interface Response
 */
export interface Response {
    /**
     * 
     * @type {string}
     * @memberof Response
     */
    count: string;
}
/**
 * 
 * @export
 * @interface ResponseData
 */
export interface ResponseData {
    /**
     * 
     * @type {string}
     * @memberof ResponseData
     */
    CLIENT_ID: string;
}
/**
 * 
 * @export
 * @interface SingleLesson
 */
export interface SingleLesson {
    /**
     * 
     * @type {MCompany}
     * @memberof SingleLesson
     */
    m_company: MCompany;
    /**
     * 
     * @type {TPaymentCompany}
     * @memberof SingleLesson
     */
    t_payment_company: TPaymentCompany;
    /**
     * 
     * @type {number}
     * @memberof SingleLesson
     */
    company_id: number;
    /**
     * 
     * @type {string}
     * @memberof SingleLesson
     */
    lesson_id: string;
    /**
     * 
     * @type {string}
     * @memberof SingleLesson
     */
    create_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof SingleLesson
     */
    create_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof SingleLesson
     */
    update_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof SingleLesson
     */
    update_user_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof SingleLesson
     */
    delete_flg: boolean;
    /**
     * 
     * @type {string}
     * @memberof SingleLesson
     */
    room_id: string;
    /**
     * 
     * @type {string}
     * @memberof SingleLesson
     */
    start_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof SingleLesson
     */
    end_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof SingleLesson
     */
    note: string;
    /**
     * 
     * @type {number}
     * @memberof SingleLesson
     */
    payment_id: number;
    /**
     * 
     * @type {boolean}
     * @memberof SingleLesson
     */
    approval_flg: boolean;
    /**
     * 
     * @type {number}
     * @memberof SingleLesson
     */
    cancel_flg: number;
    /**
     * 
     * @type {string}
     * @memberof SingleLesson
     */
    theme?: string | null;
    /**
     * 
     * @type {Array<TLessonLearner>}
     * @memberof SingleLesson
     */
    t_lesson_learner: Array<TLessonLearner>;
    /**
     * 
     * @type {Array<TLessonTeacher>}
     * @memberof SingleLesson
     */
    t_lesson_teacher: Array<TLessonTeacher>;
    /**
     * 
     * @type {string}
     * @memberof SingleLesson
     */
    lesson_day: string;
    /**
     * 
     * @type {string}
     * @memberof SingleLesson
     */
    start_time: string;
    /**
     * 
     * @type {string}
     * @memberof SingleLesson
     */
    end_time: string;
    /**
     * 
     * @type {string}
     * @memberof SingleLesson
     */
    learner_names: string;
}
/**
 * 
 * @export
 * @interface StudyRecordsStoryList
 */
export interface StudyRecordsStoryList {
    /**
     * 
     * @type {Array<TStudyRecordsStory>}
     * @memberof StudyRecordsStoryList
     */
    records: Array<TStudyRecordsStory>;
    /**
     * 
     * @type {number}
     * @memberof StudyRecordsStoryList
     */
    total: number;
}
/**
 * 
 * @export
 * @interface StudyResult
 */
export interface StudyResult {
    /**
     * 
     * @type {string}
     * @memberof StudyResult
     */
    chapter_id: string;
    /**
     * 
     * @type {number}
     * @memberof StudyResult
     */
    total_count: number;
}
/**
 * 
 * @export
 * @interface TLesson
 */
export interface TLesson {
    /**
     * 
     * @type {MCompany}
     * @memberof TLesson
     */
    m_company?: MCompany | null;
    /**
     * 
     * @type {TPaymentCompany}
     * @memberof TLesson
     */
    t_payment_company?: TPaymentCompany | null;
}
/**
 * 
 * @export
 * @interface TLessonLearner
 */
export interface TLessonLearner {
    /**
     * 
     * @type {MCompany}
     * @memberof TLessonLearner
     */
    m_company?: MCompany | null;
    /**
     * 
     * @type {TLesson}
     * @memberof TLessonLearner
     */
    t_lesson: TLesson;
    /**
     * 
     * @type {MLearner}
     * @memberof TLessonLearner
     */
    m_learner: MLearner;
    /**
     * 
     * @type {number}
     * @memberof TLessonLearner
     */
    company_id: number;
    /**
     * 
     * @type {string}
     * @memberof TLessonLearner
     */
    lesson_id: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonLearner
     */
    learner_id: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonLearner
     */
    create_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonLearner
     */
    create_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonLearner
     */
    update_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonLearner
     */
    update_user_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof TLessonLearner
     */
    stop_flg: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TLessonLearner
     */
    delete_flg: boolean;
    /**
     * 
     * @type {string}
     * @memberof TLessonLearner
     */
    connection_id: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonLearner
     */
    feed_back?: string | null;
}
/**
 * 
 * @export
 * @interface TLessonLearnerLogs
 */
export interface TLessonLearnerLogs {
    /**
     * 
     * @type {number}
     * @memberof TLessonLearnerLogs
     */
    company_id: number;
    /**
     * 
     * @type {string}
     * @memberof TLessonLearnerLogs
     */
    lesson_id: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonLearnerLogs
     */
    learner_id: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonLearnerLogs
     */
    lesson_log_type_id: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonLearnerLogs
     */
    event_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonLearnerLogs
     */
    create_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonLearnerLogs
     */
    create_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonLearnerLogs
     */
    update_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonLearnerLogs
     */
    update_user_id: string;
}
/**
 * 
 * @export
 * @interface TLessonTeacher
 */
export interface TLessonTeacher {
    /**
     * 
     * @type {MCompany}
     * @memberof TLessonTeacher
     */
    m_company?: MCompany | null;
    /**
     * 
     * @type {TLesson}
     * @memberof TLessonTeacher
     */
    t_lesson: TLesson;
    /**
     * 
     * @type {MTeacher}
     * @memberof TLessonTeacher
     */
    m_teacher: MTeacher;
    /**
     * 
     * @type {number}
     * @memberof TLessonTeacher
     */
    company_id: number;
    /**
     * 
     * @type {string}
     * @memberof TLessonTeacher
     */
    lesson_id: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonTeacher
     */
    teacher_id: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonTeacher
     */
    create_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonTeacher
     */
    create_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonTeacher
     */
    update_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof TLessonTeacher
     */
    update_user_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof TLessonTeacher
     */
    delete_flg: boolean;
    /**
     * 
     * @type {string}
     * @memberof TLessonTeacher
     */
    connection_id: string;
}
/**
 * 
 * @export
 * @interface TPaymentCompany
 */
export interface TPaymentCompany {
    /**
     * 
     * @type {MCompany}
     * @memberof TPaymentCompany
     */
    m_company?: MCompany | null;
}
/**
 * 
 * @export
 * @interface TPushMsgLog
 */
export interface TPushMsgLog {
    /**
     * 
     * @type {number}
     * @memberof TPushMsgLog
     */
    push_msg_que_id: number;
    /**
     * 
     * @type {number}
     * @memberof TPushMsgLog
     */
    lesson_id: number;
    /**
     * 
     * @type {number}
     * @memberof TPushMsgLog
     */
    learner_id: number;
    /**
     * 
     * @type {string}
     * @memberof TPushMsgLog
     */
    create_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof TPushMsgLog
     */
    create_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof TPushMsgLog
     */
    update_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof TPushMsgLog
     */
    update_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof TPushMsgLog
     */
    endpoint?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TPushMsgLog
     */
    http_result: string;
    /**
     * 
     * @type {number}
     * @memberof TPushMsgLog
     */
    company_id: number;
}
/**
 * 
 * @export
 * @interface TPushMsgQue
 */
export interface TPushMsgQue {
    /**
     * 
     * @type {number}
     * @memberof TPushMsgQue
     */
    push_msg_que_id: number;
    /**
     * 
     * @type {string}
     * @memberof TPushMsgQue
     */
    create_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof TPushMsgQue
     */
    create_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof TPushMsgQue
     */
    update_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof TPushMsgQue
     */
    update_user_id: string;
    /**
     * 
     * @type {number}
     * @memberof TPushMsgQue
     */
    company_id: number;
    /**
     * 
     * @type {string}
     * @memberof TPushMsgQue
     */
    target_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TPushMsgQue
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof TPushMsgQue
     */
    dend_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof TPushMsgQue
     */
    dent_datetime?: string | null;
}
/**
 * 
 * @export
 * @interface TStudyRecordsStory
 */
export interface TStudyRecordsStory {
    /**
     * 
     * @type {MCompany}
     * @memberof TStudyRecordsStory
     */
    m_company?: MCompany | null;
    /**
     * 
     * @type {MLearner}
     * @memberof TStudyRecordsStory
     */
    m_learner?: MLearner | null;
    /**
     * 
     * @type {number}
     * @memberof TStudyRecordsStory
     */
    company_id: number;
    /**
     * 
     * @type {string}
     * @memberof TStudyRecordsStory
     */
    learner_id: string;
    /**
     * 
     * @type {string}
     * @memberof TStudyRecordsStory
     */
    chapter_id: string;
    /**
     * 
     * @type {string}
     * @memberof TStudyRecordsStory
     */
    year_month: string;
    /**
     * 
     * @type {string}
     * @memberof TStudyRecordsStory
     */
    create_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof TStudyRecordsStory
     */
    create_user_id: string;
    /**
     * 
     * @type {string}
     * @memberof TStudyRecordsStory
     */
    update_datetime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TStudyRecordsStory
     */
    update_user_id: string;
    /**
     * 
     * @type {number}
     * @memberof TStudyRecordsStory
     */
    correct_count: number;
    /**
     * 
     * @type {number}
     * @memberof TStudyRecordsStory
     */
    total_count: number;
    /**
     * 
     * @type {string}
     * @memberof TStudyRecordsStory
     */
    start_datetime: string;
    /**
     * 
     * @type {string}
     * @memberof TStudyRecordsStory
     */
    end_datetime?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TStudyRecordsStory
     */
    quiz_attempt: number;
}
/**
 * 
 * @export
 * @interface UpdateAgreementInput
 */
export interface UpdateAgreementInput {
    /**
     * 
     * @type {string}
     * @memberof UpdateAgreementInput
     */
    plan_name: string;
}
/**
 * 
 * @export
 * @interface UpdateCompanyInput
 */
export interface UpdateCompanyInput {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyInput
     */
    company_name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyInput
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyInput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyInput
     */
    representative: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyInput
     */
    address: string;
}
/**
 * 
 * @export
 * @interface UpdateDrillResult
 */
export interface UpdateDrillResult {
    /**
     * 
     * @type {number}
     * @memberof UpdateDrillResult
     */
    drill_id: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDrillResult
     */
    correct_count: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDrillResult
     */
    total_count: number;
}
/**
 * 
 * @export
 * @interface UpdateLearnerConnectionIdResponse
 */
export interface UpdateLearnerConnectionIdResponse {
    /**
     * 
     * @type {number}
     * @memberof UpdateLearnerConnectionIdResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof UpdateLearnerConnectionIdResponse
     */
    message: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateLearnerConnectionIdResponse
     */
    data?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateLearnerEmailResponse
 */
export interface UpdateLearnerEmailResponse {
    /**
     * 
     * @type {number}
     * @memberof UpdateLearnerEmailResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof UpdateLearnerEmailResponse
     */
    message: object;
    /**
     * 
     * @type {ResponseData}
     * @memberof UpdateLearnerEmailResponse
     */
    data?: ResponseData;
}
/**
 * 
 * @export
 * @interface UpdateLearnerEmailSendFlgResponse
 */
export interface UpdateLearnerEmailSendFlgResponse {
    /**
     * 
     * @type {number}
     * @memberof UpdateLearnerEmailSendFlgResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof UpdateLearnerEmailSendFlgResponse
     */
    message: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateLearnerEmailSendFlgResponse
     */
    data?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateLearnerInput
 */
export interface UpdateLearnerInput {
    /**
     * 
     * @type {string}
     * @memberof UpdateLearnerInput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLearnerInput
     */
    full_name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLearnerInput
     */
    nick_name: string;
}
/**
 * 
 * @export
 * @interface UpdateLessonFeedbackInput
 */
export interface UpdateLessonFeedbackInput {
    /**
     * 
     * @type {string}
     * @memberof UpdateLessonFeedbackInput
     */
    feedback: string;
}
/**
 * 
 * @export
 * @interface UpdateStudyResult
 */
export interface UpdateStudyResult {
    /**
     * 
     * @type {string}
     * @memberof UpdateStudyResult
     */
    chapter_id: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateStudyResult
     */
    correct_count: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateStudyResult
     */
    total_count: number;
}
/**
 * 
 * @export
 * @interface UpdateTeacherInput
 */
export interface UpdateTeacherInput {
    /**
     * 
     * @type {string}
     * @memberof UpdateTeacherInput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTeacherInput
     */
    full_name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTeacherInput
     */
    nick_name: string;
}
/**
 * 
 * @export
 * @interface UpsertLearnerEndPointResponse
 */
export interface UpsertLearnerEndPointResponse {
    /**
     * 
     * @type {number}
     * @memberof UpsertLearnerEndPointResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof UpsertLearnerEndPointResponse
     */
    message: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertLearnerEndPointResponse
     */
    data?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpsertPushMsgLogResponse
 */
export interface UpsertPushMsgLogResponse {
    /**
     * 
     * @type {number}
     * @memberof UpsertPushMsgLogResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof UpsertPushMsgLogResponse
     */
    message: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertPushMsgLogResponse
     */
    data?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpsertPushMsgResponse
 */
export interface UpsertPushMsgResponse {
    /**
     * 
     * @type {number}
     * @memberof UpsertPushMsgResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof UpsertPushMsgResponse
     */
    message: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertPushMsgResponse
     */
    data?: Array<string>;
}
/**
 * 
 * @export
 * @interface VerifyCognitoUserEmailResponse
 */
export interface VerifyCognitoUserEmailResponse {
    /**
     * 
     * @type {number}
     * @memberof VerifyCognitoUserEmailResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof VerifyCognitoUserEmailResponse
     */
    message: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof VerifyCognitoUserEmailResponse
     */
    data?: Array<string>;
}
/**
 * 
 * @export
 * @interface VerifyInfo
 */
export interface VerifyInfo {
    /**
     * 
     * @type {string}
     * @memberof VerifyInfo
     */
    verifyCode: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyInfo
     */
    accessToken: string;
}
/**
 * 
 * @export
 * @interface VerifyLearnerEmailResponse
 */
export interface VerifyLearnerEmailResponse {
    /**
     * 
     * @type {number}
     * @memberof VerifyLearnerEmailResponse
     */
    code: number;
    /**
     * 
     * @type {object}
     * @memberof VerifyLearnerEmailResponse
     */
    message: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof VerifyLearnerEmailResponse
     */
    data?: Array<string>;
}

/**
 * APIApi - axios parameter creator
 * @export
 */
export const APIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateInquiryInput} createInquiryInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquiryControllerSendInquiryEmail: async (createInquiryInput: CreateInquiryInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInquiryInput' is not null or undefined
            assertParamExists('inquiryControllerSendInquiryEmail', 'createInquiryInput', createInquiryInput)
            const localVarPath = `/api/inquiry/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInquiryInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerCreateActiveLearnerLog: async (learnerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('mobileAppControllerCreateActiveLearnerLog', 'learnerId', learnerId)
            const localVarPath = `/api/appService/active/learner/{learnerId}`
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} learnerId 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {LearnerLessonLogInput} learnerLessonLogInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerCreateLessonEnteringLogLearner: async (learnerId: string, companyId: string, lessonId: string, learnerLessonLogInput: LearnerLessonLogInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('mobileAppControllerCreateLessonEnteringLogLearner', 'learnerId', learnerId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('mobileAppControllerCreateLessonEnteringLogLearner', 'companyId', companyId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('mobileAppControllerCreateLessonEnteringLogLearner', 'lessonId', lessonId)
            // verify required parameter 'learnerLessonLogInput' is not null or undefined
            assertParamExists('mobileAppControllerCreateLessonEnteringLogLearner', 'learnerLessonLogInput', learnerLessonLogInput)
            const localVarPath = `/api/appService/lesson/{lessonId}/log/{companyId}/{learnerId}/entering`
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(learnerLessonLogInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerDeactivateLearnerEndpoints: async (companyId: string, learnerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('mobileAppControllerDeactivateLearnerEndpoints', 'companyId', companyId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('mobileAppControllerDeactivateLearnerEndpoints', 'learnerId', learnerId)
            const localVarPath = `/api/appService/{companyId}/DeactivateLearnerEndpoints/{learnerId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetApprovedLessons: async (learnerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('mobileAppControllerGetApprovedLessons', 'learnerId', learnerId)
            const localVarPath = `/api/appService/lessons/{learnerId}/approved`
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetLearnerActiveEndpointCount: async (companyId: string, learnerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('mobileAppControllerGetLearnerActiveEndpointCount', 'companyId', companyId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('mobileAppControllerGetLearnerActiveEndpointCount', 'learnerId', learnerId)
            const localVarPath = `/api/appService/{companyId}/learnerActiveEndpointCount/{learnerId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetLearnerByEmail: async (companyId: string, email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('mobileAppControllerGetLearnerByEmail', 'companyId', companyId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('mobileAppControllerGetLearnerByEmail', 'email', email)
            const localVarPath = `/api/appService/{companyId}/getUserName/{email}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetLearnerEndpoint: async (companyId: string, learnerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('mobileAppControllerGetLearnerEndpoint', 'companyId', companyId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('mobileAppControllerGetLearnerEndpoint', 'learnerId', learnerId)
            const localVarPath = `/api/appService/{companyId}/learnerEndpoint/{learnerId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetLernerById: async (companyId: string, learnerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('mobileAppControllerGetLernerById', 'companyId', companyId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('mobileAppControllerGetLernerById', 'learnerId', learnerId)
            const localVarPath = `/api/appService/{companyId}/learner/{learnerId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetLoginedLearnerDetail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appService/learner/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetMostRecentScheduledLesson: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appService/most-recent-scheduled-lesson`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetNoticeRead: async (noticeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'noticeId' is not null or undefined
            assertParamExists('mobileAppControllerGetNoticeRead', 'noticeId', noticeId)
            const localVarPath = `/api/appService/noticeRead/{noticeId}`
                .replace(`{${"noticeId"}}`, encodeURIComponent(String(noticeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetNoticeUnreadCount: async (userName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('mobileAppControllerGetNoticeUnreadCount', 'userName', userName)
            const localVarPath = `/api/appService/noticeUnreadCount/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetNotices: async (limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('mobileAppControllerGetNotices', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('mobileAppControllerGetNotices', 'page', page)
            const localVarPath = `/api/appService/notices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetPastLessons: async (limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('mobileAppControllerGetPastLessons', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('mobileAppControllerGetPastLessons', 'page', page)
            const localVarPath = `/api/appService/lessons/past`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetPushMsg: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('mobileAppControllerGetPushMsg', 'companyId', companyId)
            const localVarPath = `/api/appService/{companyId}/pushmsg`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} pushMsgQId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetPushMsgLog: async (companyId: string, pushMsgQId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('mobileAppControllerGetPushMsgLog', 'companyId', companyId)
            // verify required parameter 'pushMsgQId' is not null or undefined
            assertParamExists('mobileAppControllerGetPushMsgLog', 'pushMsgQId', pushMsgQId)
            const localVarPath = `/api/appService/{companyId}/pushmsglog/{pushMsgQId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"pushMsgQId"}}`, encodeURIComponent(String(pushMsgQId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetPushVapidPublicKey: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('mobileAppControllerGetPushVapidPublicKey', 'companyId', companyId)
            const localVarPath = `/api/appService/{companyId}/pushVapidPublicKey`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetStudyRecords: async (userName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('mobileAppControllerGetStudyRecords', 'userName', userName)
            const localVarPath = `/api/appService/studyRecords/{userName}`
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DrillParam} drillParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerPostStudyRecordDrill: async (drillParam: DrillParam, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'drillParam' is not null or undefined
            assertParamExists('mobileAppControllerPostStudyRecordDrill', 'drillParam', drillParam)
            const localVarPath = `/api/appService/studyRecordDrill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(drillParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StudyResult} studyResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerPostStudyRecordStory: async (studyResult: StudyResult, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyResult' is not null or undefined
            assertParamExists('mobileAppControllerPostStudyRecordStory', 'studyResult', studyResult)
            const localVarPath = `/api/appService/studyRecordStory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(studyResult, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDrillResult} updateDrillResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerPutDrillRecord: async (updateDrillResult: UpdateDrillResult, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDrillResult' is not null or undefined
            assertParamExists('mobileAppControllerPutDrillRecord', 'updateDrillResult', updateDrillResult)
            const localVarPath = `/api/appService/studyRecordDrill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDrillResult, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} noticeId 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerPutNoticeRead: async (companyId: string, noticeId: number, userName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('mobileAppControllerPutNoticeRead', 'companyId', companyId)
            // verify required parameter 'noticeId' is not null or undefined
            assertParamExists('mobileAppControllerPutNoticeRead', 'noticeId', noticeId)
            // verify required parameter 'userName' is not null or undefined
            assertParamExists('mobileAppControllerPutNoticeRead', 'userName', userName)
            const localVarPath = `/api/appService/{companyId}/noticeRead/{noticeId}/{userName}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"noticeId"}}`, encodeURIComponent(String(noticeId)))
                .replace(`{${"userName"}}`, encodeURIComponent(String(userName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateStudyResult} updateStudyResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerPutStudyRecord: async (updateStudyResult: UpdateStudyResult, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateStudyResult' is not null or undefined
            assertParamExists('mobileAppControllerPutStudyRecord', 'updateStudyResult', updateStudyResult)
            const localVarPath = `/api/appService/studyRecordStory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStudyResult, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {ConnectionId} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerUpdateLearnerConnectionId: async (companyId: string, learnerId: string, connectionId: ConnectionId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('mobileAppControllerUpdateLearnerConnectionId', 'companyId', companyId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('mobileAppControllerUpdateLearnerConnectionId', 'learnerId', learnerId)
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('mobileAppControllerUpdateLearnerConnectionId', 'connectionId', connectionId)
            const localVarPath = `/api/appService/{companyId}/{learnerId}/connectionId`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectionId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerUpdateLearnerEmail: async (companyId: string, learnerId: string, email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('mobileAppControllerUpdateLearnerEmail', 'companyId', companyId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('mobileAppControllerUpdateLearnerEmail', 'learnerId', learnerId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('mobileAppControllerUpdateLearnerEmail', 'email', email)
            const localVarPath = `/api/appService/{companyId}/{learnerId}/changeEmail/{email}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)))
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {string} emailSendFlg 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerUpdateLearnerEmailSendFlg: async (companyId: string, learnerId: string, emailSendFlg: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('mobileAppControllerUpdateLearnerEmailSendFlg', 'companyId', companyId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('mobileAppControllerUpdateLearnerEmailSendFlg', 'learnerId', learnerId)
            // verify required parameter 'emailSendFlg' is not null or undefined
            assertParamExists('mobileAppControllerUpdateLearnerEmailSendFlg', 'emailSendFlg', emailSendFlg)
            const localVarPath = `/api/appService/{companyId}/{learnerId}/changeEmailSendFlg/{emailSendFlg}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)))
                .replace(`{${"emailSendFlg"}}`, encodeURIComponent(String(emailSendFlg)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {LearnerEndpoint} learnerEndpoint 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerUpsertLearnerEndpoint: async (companyId: string, learnerId: string, learnerEndpoint: LearnerEndpoint, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('mobileAppControllerUpsertLearnerEndpoint', 'companyId', companyId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('mobileAppControllerUpsertLearnerEndpoint', 'learnerId', learnerId)
            // verify required parameter 'learnerEndpoint' is not null or undefined
            assertParamExists('mobileAppControllerUpsertLearnerEndpoint', 'learnerEndpoint', learnerEndpoint)
            const localVarPath = `/api/appService/{companyId}/LearnerEndpoint/{learnerId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(learnerEndpoint, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {PushMsg} pushMsg 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerUpsertPushMsg: async (companyId: string, pushMsg: PushMsg, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('mobileAppControllerUpsertPushMsg', 'companyId', companyId)
            // verify required parameter 'pushMsg' is not null or undefined
            assertParamExists('mobileAppControllerUpsertPushMsg', 'pushMsg', pushMsg)
            const localVarPath = `/api/appService/{companyId}/pushmsg`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pushMsg, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {PushMsgLog} pushMsgLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerUpsertPushMsgLog: async (companyId: string, pushMsgLog: PushMsgLog, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('mobileAppControllerUpsertPushMsgLog', 'companyId', companyId)
            // verify required parameter 'pushMsgLog' is not null or undefined
            assertParamExists('mobileAppControllerUpsertPushMsgLog', 'pushMsgLog', pushMsgLog)
            const localVarPath = `/api/appService/{companyId}/pushmsglog`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pushMsgLog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerVerifyCognitoUserEmail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appService/verifyEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {string} email 
         * @param {VerifyInfo} verifyInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerVerifyLearnerEmail: async (companyId: string, learnerId: string, email: string, verifyInfo: VerifyInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('mobileAppControllerVerifyLearnerEmail', 'companyId', companyId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('mobileAppControllerVerifyLearnerEmail', 'learnerId', learnerId)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('mobileAppControllerVerifyLearnerEmail', 'email', email)
            // verify required parameter 'verifyInfo' is not null or undefined
            assertParamExists('mobileAppControllerVerifyLearnerEmail', 'verifyInfo', verifyInfo)
            const localVarPath = `/api/appService/{companyId}/{learnerId}/verifyEmail/{email}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)))
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerApprovalLessonTeacher: async (companyId: string, lessonId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerApprovalLessonTeacher', 'companyId', companyId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerApprovalLessonTeacher', 'lessonId', lessonId)
            const localVarPath = `/api/organizations/update/{companyId}/agreementLesson/{lessonId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {CancelLessonInput} cancelLessonInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCancelLesson: async (companyId: string, lessonId: string, cancelLessonInput: CancelLessonInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerCancelLesson', 'companyId', companyId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerCancelLesson', 'lessonId', lessonId)
            // verify required parameter 'cancelLessonInput' is not null or undefined
            assertParamExists('organizationsControllerCancelLesson', 'cancelLessonInput', cancelLessonInput)
            const localVarPath = `/api/organizations/delete/{companyId}/lesson/{lessonId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelLessonInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerChangeLessonTeacher: async (companyId: string, lessonId: string, teacherId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerChangeLessonTeacher', 'companyId', companyId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerChangeLessonTeacher', 'lessonId', lessonId)
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerChangeLessonTeacher', 'teacherId', teacherId)
            const localVarPath = `/api/organizations/update/{companyId}/changeLessonTeacher/{lessonId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} roomSid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerComposeTwilioVideoRecording: async (companyId: string, roomSid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerComposeTwilioVideoRecording', 'companyId', companyId)
            // verify required parameter 'roomSid' is not null or undefined
            assertParamExists('organizationsControllerComposeTwilioVideoRecording', 'roomSid', roomSid)
            const localVarPath = `/api/organizations/{company_id}/roomSid/{roomSid}/composeVideoRecording`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"roomSid"}}`, encodeURIComponent(String(roomSid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {CreateAgreementInput} createAgreementInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateAgreement: async (companyId: string, createAgreementInput: CreateAgreementInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerCreateAgreement', 'companyId', companyId)
            // verify required parameter 'createAgreementInput' is not null or undefined
            assertParamExists('organizationsControllerCreateAgreement', 'createAgreementInput', createAgreementInput)
            const localVarPath = `/api/organizations/create/{companyId}/paymentList`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAgreementInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {CreateCompanyInput} createCompanyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateCompany: async (companyId: string, createCompanyInput: CreateCompanyInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerCreateCompany', 'companyId', companyId)
            // verify required parameter 'createCompanyInput' is not null or undefined
            assertParamExists('organizationsControllerCreateCompany', 'createCompanyInput', createCompanyInput)
            const localVarPath = `/api/organizations/create/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {CreateCompanyUserInput} createCompanyUserInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateCompanyUser: async (companyId: string, createCompanyUserInput: CreateCompanyUserInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerCreateCompanyUser', 'companyId', companyId)
            // verify required parameter 'createCompanyUserInput' is not null or undefined
            assertParamExists('organizationsControllerCreateCompanyUser', 'createCompanyUserInput', createCompanyUserInput)
            const localVarPath = `/api/organizations/create/companyUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyUserInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateErrorLog} createErrorLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateErrorLog: async (createErrorLog: CreateErrorLog, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createErrorLog' is not null or undefined
            assertParamExists('organizationsControllerCreateErrorLog', 'createErrorLog', createErrorLog)
            const localVarPath = `/api/organizations/create/log/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createErrorLog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {CreateLearnerInput} createLearnerInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateLearnerUser: async (companyId: string, paymentId: number, createLearnerInput: CreateLearnerInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerCreateLearnerUser', 'companyId', companyId)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('organizationsControllerCreateLearnerUser', 'paymentId', paymentId)
            // verify required parameter 'createLearnerInput' is not null or undefined
            assertParamExists('organizationsControllerCreateLearnerUser', 'createLearnerInput', createLearnerInput)
            const localVarPath = `/api/organizations/create/learner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLearnerInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} startDatetime 
         * @param {string} endDatetime 
         * @param {CreateLessonInput} createLessonInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateLesson: async (companyId: string, teacherId: string, startDatetime: string, endDatetime: string, createLessonInput: CreateLessonInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerCreateLesson', 'companyId', companyId)
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerCreateLesson', 'teacherId', teacherId)
            // verify required parameter 'startDatetime' is not null or undefined
            assertParamExists('organizationsControllerCreateLesson', 'startDatetime', startDatetime)
            // verify required parameter 'endDatetime' is not null or undefined
            assertParamExists('organizationsControllerCreateLesson', 'endDatetime', endDatetime)
            // verify required parameter 'createLessonInput' is not null or undefined
            assertParamExists('organizationsControllerCreateLesson', 'createLessonInput', createLessonInput)
            const localVarPath = `/api/organizations/create/{companyId}/lesson`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            if (startDatetime !== undefined) {
                localVarQueryParameter['start_datetime'] = (startDatetime as any instanceof Date) ?
                    (startDatetime as any).toISOString() :
                    startDatetime;
            }

            if (endDatetime !== undefined) {
                localVarQueryParameter['end_datetime'] = (endDatetime as any instanceof Date) ?
                    (endDatetime as any).toISOString() :
                    endDatetime;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLessonInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {CreateLessonFeeInput} createLessonFeeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateLessonFee: async (teacherId: string, createLessonFeeInput: CreateLessonFeeInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerCreateLessonFee', 'teacherId', teacherId)
            // verify required parameter 'createLessonFeeInput' is not null or undefined
            assertParamExists('organizationsControllerCreateLessonFee', 'createLessonFeeInput', createLessonFeeInput)
            const localVarPath = `/api/organizations/create/lessonFeeOption`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLessonFeeInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {CreateLessonFeeHistoryInput} createLessonFeeHistoryInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateLessonFeeHistory: async (teacherId: string, createLessonFeeHistoryInput: CreateLessonFeeHistoryInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerCreateLessonFeeHistory', 'teacherId', teacherId)
            // verify required parameter 'createLessonFeeHistoryInput' is not null or undefined
            assertParamExists('organizationsControllerCreateLessonFeeHistory', 'createLessonFeeHistoryInput', createLessonFeeHistoryInput)
            const localVarPath = `/api/organizations/create/lessonFeeHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLessonFeeHistoryInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateMaintenanceInput} createMaintenanceInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateMaintenance: async (createMaintenanceInput: CreateMaintenanceInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMaintenanceInput' is not null or undefined
            assertParamExists('organizationsControllerCreateMaintenance', 'createMaintenanceInput', createMaintenanceInput)
            const localVarPath = `/api/organizations/maintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMaintenanceInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dummy 
         * @param {CreateManagerInput} createManagerInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateManagerUser: async (dummy: string, createManagerInput: CreateManagerInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dummy' is not null or undefined
            assertParamExists('organizationsControllerCreateManagerUser', 'dummy', dummy)
            // verify required parameter 'createManagerInput' is not null or undefined
            assertParamExists('organizationsControllerCreateManagerUser', 'createManagerInput', createManagerInput)
            const localVarPath = `/api/organizations/create/managerUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dummy !== undefined) {
                localVarQueryParameter['dummy'] = dummy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createManagerInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dummy 
         * @param {CreateNoticeInput} createNoticeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateNotice: async (dummy: string, createNoticeInput: CreateNoticeInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dummy' is not null or undefined
            assertParamExists('organizationsControllerCreateNotice', 'dummy', dummy)
            // verify required parameter 'createNoticeInput' is not null or undefined
            assertParamExists('organizationsControllerCreateNotice', 'createNoticeInput', createNoticeInput)
            const localVarPath = `/api/organizations/create/create/notice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dummy !== undefined) {
                localVarQueryParameter['dummy'] = dummy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNoticeInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateNoticeCompanyInput} createNoticeCompanyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateNoticeCompany: async (createNoticeCompanyInput: CreateNoticeCompanyInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNoticeCompanyInput' is not null or undefined
            assertParamExists('organizationsControllerCreateNoticeCompany', 'createNoticeCompanyInput', createNoticeCompanyInput)
            const localVarPath = `/api/organizations/notice/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNoticeCompanyInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {CreateTeacherInput} createTeacherInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateTeacherUser: async (teacherId: string, createTeacherInput: CreateTeacherInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerCreateTeacherUser', 'teacherId', teacherId)
            // verify required parameter 'createTeacherInput' is not null or undefined
            assertParamExists('organizationsControllerCreateTeacherUser', 'createTeacherInput', createTeacherInput)
            const localVarPath = `/api/organizations/create/teacher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTeacherInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteAgreement: async (companyId: string, paymentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerDeleteAgreement', 'companyId', companyId)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('organizationsControllerDeleteAgreement', 'paymentId', paymentId)
            const localVarPath = `/api/organizations/delete/{companyId}/paymentList`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteCompany: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerDeleteCompany', 'companyId', companyId)
            const localVarPath = `/api/organizations/delete/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteCompanyUser: async (companyId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerDeleteCompanyUser', 'companyId', companyId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('organizationsControllerDeleteCompanyUser', 'userId', userId)
            const localVarPath = `/api/organizations/delete/{companyId}/company/{userId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteLearnerUser: async (companyId: string, learnerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerDeleteLearnerUser', 'companyId', companyId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerDeleteLearnerUser', 'learnerId', learnerId)
            const localVarPath = `/api/organizations/delete/{companyId}/learnerUser/{learnerId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteLessonFeeOption: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsControllerDeleteLessonFeeOption', 'id', id)
            const localVarPath = `/api/organizations/delete/lessonFeeOption/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} maintenanceId 
         * @param {string} maintenanceStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteMaintenance: async (maintenanceId: number, maintenanceStatus: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'maintenanceId' is not null or undefined
            assertParamExists('organizationsControllerDeleteMaintenance', 'maintenanceId', maintenanceId)
            // verify required parameter 'maintenanceStatus' is not null or undefined
            assertParamExists('organizationsControllerDeleteMaintenance', 'maintenanceStatus', maintenanceStatus)
            const localVarPath = `/api/organizations/maintenance/{maintenance_id}`
                .replace(`{${"maintenance_id"}}`, encodeURIComponent(String(maintenanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (maintenanceStatus !== undefined) {
                localVarQueryParameter['maintenance_status'] = maintenanceStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} managerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteManagerUser: async (managerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'managerId' is not null or undefined
            assertParamExists('organizationsControllerDeleteManagerUser', 'managerId', managerId)
            const localVarPath = `/api/organizations/delete/manager/{managerId}`
                .replace(`{${"managerId"}}`, encodeURIComponent(String(managerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteMonthlyAgreement: async (companyId: string, body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerDeleteMonthlyAgreement', 'companyId', companyId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('organizationsControllerDeleteMonthlyAgreement', 'body', body)
            const localVarPath = `/api/organizations/deleteMonthlyAgreement/{companyId}/paymentList`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteNotice: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsControllerDeleteNotice', 'id', id)
            const localVarPath = `/api/organizations/delete/noticeList/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteTeacherUser: async (teacherId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerDeleteTeacherUser', 'teacherId', teacherId)
            const localVarPath = `/api/organizations/delete/teacher/{teacherId}`
                .replace(`{${"teacherId"}}`, encodeURIComponent(String(teacherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExistsLearnerInput} existsLearnerInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerExistsLearner: async (existsLearnerInput: ExistsLearnerInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'existsLearnerInput' is not null or undefined
            assertParamExists('organizationsControllerExistsLearner', 'existsLearnerInput', existsLearnerInput)
            const localVarPath = `/api/organizations/learners/exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(existsLearnerInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExistsLearnerInPlanInput} existsLearnerInPlanInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerExistsLearnerInPlan: async (existsLearnerInPlanInput: ExistsLearnerInPlanInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'existsLearnerInPlanInput' is not null or undefined
            assertParamExists('organizationsControllerExistsLearnerInPlan', 'existsLearnerInPlanInput', existsLearnerInPlanInput)
            const localVarPath = `/api/organizations/plan/learners/exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(existsLearnerInPlanInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetAvailableCompanies: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations/available-companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetCompany: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetCompany', 'companyId', companyId)
            const localVarPath = `/api/organizations/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {string} sortStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetCompanyList: async (companyId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, sortStr: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetCompanyList', 'companyId', companyId)
            // verify required parameter 'searchStatus' is not null or undefined
            assertParamExists('organizationsControllerGetCompanyList', 'searchStatus', searchStatus)
            // verify required parameter 'searchField' is not null or undefined
            assertParamExists('organizationsControllerGetCompanyList', 'searchField', searchField)
            // verify required parameter 'searchStr' is not null or undefined
            assertParamExists('organizationsControllerGetCompanyList', 'searchStr', searchStr)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationsControllerGetCompanyList', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetCompanyList', 'page', page)
            // verify required parameter 'sortStr' is not null or undefined
            assertParamExists('organizationsControllerGetCompanyList', 'sortStr', sortStr)
            const localVarPath = `/api/organizations/get/companyList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (searchStatus !== undefined) {
                localVarQueryParameter['searchStatus'] = searchStatus;
            }

            if (searchField !== undefined) {
                localVarQueryParameter['searchField'] = searchField;
            }

            if (searchStr !== undefined) {
                localVarQueryParameter['searchStr'] = searchStr;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sortStr !== undefined) {
                localVarQueryParameter['sortStr'] = sortStr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetCompanyPlans: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetCompanyPlans', 'companyId', companyId)
            const localVarPath = `/api/organizations/companies/{companyId}/plans`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetCompanyUserList: async (companyId: string, limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetCompanyUserList', 'companyId', companyId)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationsControllerGetCompanyUserList', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetCompanyUserList', 'page', page)
            const localVarPath = `/api/organizations/get/companyUser/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetConnectionIdByRoomId: async (companyId: string, roomId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetConnectionIdByRoomId', 'companyId', companyId)
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('organizationsControllerGetConnectionIdByRoomId', 'roomId', roomId)
            const localVarPath = `/api/organizations/{companyId}/{roomId}/connectionId`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetEachLearnerStudyRecordsPdf: async (companyId: string, paymentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetEachLearnerStudyRecordsPdf', 'companyId', companyId)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('organizationsControllerGetEachLearnerStudyRecordsPdf', 'paymentId', paymentId)
            const localVarPath = `/api/organizations/learnerStudyRecordsPDF/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {string} teacherId 
         * @param {string} learnerId 
         * @param {string} lessonId 
         * @param {number} approvalFlg 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} futureOnly 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetFutureLessonList: async (companyId: string, paymentId: number, teacherId: string, learnerId: string, lessonId: string, approvalFlg: number, startPeriod: string, endPeriod: string, futureOnly: number, limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonList', 'companyId', companyId)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonList', 'paymentId', paymentId)
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonList', 'teacherId', teacherId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonList', 'learnerId', learnerId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonList', 'lessonId', lessonId)
            // verify required parameter 'approvalFlg' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonList', 'approvalFlg', approvalFlg)
            // verify required parameter 'startPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonList', 'startPeriod', startPeriod)
            // verify required parameter 'endPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonList', 'endPeriod', endPeriod)
            // verify required parameter 'futureOnly' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonList', 'futureOnly', futureOnly)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonList', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonList', 'page', page)
            const localVarPath = `/api/organizations/get/futureLessonList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            if (learnerId !== undefined) {
                localVarQueryParameter['learnerId'] = learnerId;
            }

            if (lessonId !== undefined) {
                localVarQueryParameter['lessonId'] = lessonId;
            }

            if (approvalFlg !== undefined) {
                localVarQueryParameter['approvalFlg'] = approvalFlg;
            }

            if (startPeriod !== undefined) {
                localVarQueryParameter['startPeriod'] = startPeriod;
            }

            if (endPeriod !== undefined) {
                localVarQueryParameter['endPeriod'] = endPeriod;
            }

            if (futureOnly !== undefined) {
                localVarQueryParameter['futureOnly'] = futureOnly;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} learner 
         * @param {string} learnerId 
         * @param {string} company 
         * @param {string} searchStatus 
         * @param {string} lessonId 
         * @param {number} approvalFlg 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetFutureLessonListBgd: async (companyId: string, teacherId: string, learner: string, learnerId: string, company: string, searchStatus: string, lessonId: string, approvalFlg: number, startPeriod: string, endPeriod: string, limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonListBgd', 'companyId', companyId)
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonListBgd', 'teacherId', teacherId)
            // verify required parameter 'learner' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonListBgd', 'learner', learner)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonListBgd', 'learnerId', learnerId)
            // verify required parameter 'company' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonListBgd', 'company', company)
            // verify required parameter 'searchStatus' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonListBgd', 'searchStatus', searchStatus)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonListBgd', 'lessonId', lessonId)
            // verify required parameter 'approvalFlg' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonListBgd', 'approvalFlg', approvalFlg)
            // verify required parameter 'startPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonListBgd', 'startPeriod', startPeriod)
            // verify required parameter 'endPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonListBgd', 'endPeriod', endPeriod)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonListBgd', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetFutureLessonListBgd', 'page', page)
            const localVarPath = `/api/organizations/get/futureLessonList_bgd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            if (learner !== undefined) {
                localVarQueryParameter['learner'] = learner;
            }

            if (learnerId !== undefined) {
                localVarQueryParameter['learnerId'] = learnerId;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (searchStatus !== undefined) {
                localVarQueryParameter['searchStatus'] = searchStatus;
            }

            if (lessonId !== undefined) {
                localVarQueryParameter['lessonId'] = lessonId;
            }

            if (approvalFlg !== undefined) {
                localVarQueryParameter['approvalFlg'] = approvalFlg;
            }

            if (startPeriod !== undefined) {
                localVarQueryParameter['startPeriod'] = startPeriod;
            }

            if (endPeriod !== undefined) {
                localVarQueryParameter['endPeriod'] = endPeriod;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLatestPastLessonNote: async (companyId: string, learnerId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetLatestPastLessonNote', 'companyId', companyId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerGetLatestPastLessonNote', 'learnerId', learnerId)
            // verify required parameter 'startPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetLatestPastLessonNote', 'startPeriod', startPeriod)
            // verify required parameter 'endPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetLatestPastLessonNote', 'endPeriod', endPeriod)
            // verify required parameter 'searchField' is not null or undefined
            assertParamExists('organizationsControllerGetLatestPastLessonNote', 'searchField', searchField)
            // verify required parameter 'searchStr' is not null or undefined
            assertParamExists('organizationsControllerGetLatestPastLessonNote', 'searchStr', searchStr)
            const localVarPath = `/api/organizations/get/{companyId}/lessonNote/{learnerId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startPeriod !== undefined) {
                localVarQueryParameter['startPeriod'] = startPeriod;
            }

            if (endPeriod !== undefined) {
                localVarQueryParameter['endPeriod'] = endPeriod;
            }

            if (searchField !== undefined) {
                localVarQueryParameter['search_field'] = searchField;
            }

            if (searchStr !== undefined) {
                localVarQueryParameter['search_str'] = searchStr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLeanerUserListByLesson: async (companyId: string, lessonId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetLeanerUserListByLesson', 'companyId', companyId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerGetLeanerUserListByLesson', 'lessonId', lessonId)
            const localVarPath = `/api/organizations/learnerListByLesson`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (lessonId !== undefined) {
                localVarQueryParameter['lessonId'] = lessonId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLearnerStudyRecords: async (companyId: string, learnerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetLearnerStudyRecords', 'companyId', companyId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerGetLearnerStudyRecords', 'learnerId', learnerId)
            const localVarPath = `/api/organizations/get/{companyId}/{learnerId}/StudyRecords`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLearnerUserList: async (companyId: string, learnerId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetLearnerUserList', 'companyId', companyId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerGetLearnerUserList', 'learnerId', learnerId)
            // verify required parameter 'searchStatus' is not null or undefined
            assertParamExists('organizationsControllerGetLearnerUserList', 'searchStatus', searchStatus)
            // verify required parameter 'searchField' is not null or undefined
            assertParamExists('organizationsControllerGetLearnerUserList', 'searchField', searchField)
            // verify required parameter 'searchStr' is not null or undefined
            assertParamExists('organizationsControllerGetLearnerUserList', 'searchStr', searchStr)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationsControllerGetLearnerUserList', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetLearnerUserList', 'page', page)
            const localVarPath = `/api/organizations/get/learnerList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (learnerId !== undefined) {
                localVarQueryParameter['learnerId'] = learnerId;
            }

            if (searchStatus !== undefined) {
                localVarQueryParameter['search_status'] = searchStatus;
            }

            if (searchField !== undefined) {
                localVarQueryParameter['search_field'] = searchField;
            }

            if (searchStr !== undefined) {
                localVarQueryParameter['search_str'] = searchStr;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} lessonId 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLesson: async (companyId: string, teacherId: string, lessonId: string, searchField: string, searchStr: string, startPeriod: string, endPeriod: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetLesson', 'companyId', companyId)
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerGetLesson', 'teacherId', teacherId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerGetLesson', 'lessonId', lessonId)
            // verify required parameter 'searchField' is not null or undefined
            assertParamExists('organizationsControllerGetLesson', 'searchField', searchField)
            // verify required parameter 'searchStr' is not null or undefined
            assertParamExists('organizationsControllerGetLesson', 'searchStr', searchStr)
            // verify required parameter 'startPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetLesson', 'startPeriod', startPeriod)
            // verify required parameter 'endPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetLesson', 'endPeriod', endPeriod)
            const localVarPath = `/api/organizations/get/{companyId}/lesson/{lessonId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            if (searchField !== undefined) {
                localVarQueryParameter['search_field'] = searchField;
            }

            if (searchStr !== undefined) {
                localVarQueryParameter['search_str'] = searchStr;
            }

            if (startPeriod !== undefined) {
                localVarQueryParameter['startPeriod'] = startPeriod;
            }

            if (endPeriod !== undefined) {
                localVarQueryParameter['endPeriod'] = endPeriod;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonByCompanyNLessonID: async (companyId: string, lessonId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonByCompanyNLessonID', 'companyId', companyId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonByCompanyNLessonID', 'lessonId', lessonId)
            const localVarPath = `/api/organizations/{companyId}/lessons/{lessonId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonByRoomId: async (companyId: string, roomId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonByRoomId', 'companyId', companyId)
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonByRoomId', 'roomId', roomId)
            const localVarPath = `/api/organizations/{companyId}/roomId/{roomId}/lesson`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} learnerId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonFeedback: async (companyId: string, lessonId: string, learnerId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedback', 'companyId', companyId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedback', 'lessonId', lessonId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedback', 'learnerId', learnerId)
            // verify required parameter 'startPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedback', 'startPeriod', startPeriod)
            // verify required parameter 'endPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedback', 'endPeriod', endPeriod)
            // verify required parameter 'searchField' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedback', 'searchField', searchField)
            // verify required parameter 'searchStr' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedback', 'searchStr', searchStr)
            const localVarPath = `/api/organizations/{companyId}/lessonFeedback/{lessonId}/{learnerId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startPeriod !== undefined) {
                localVarQueryParameter['startPeriod'] = startPeriod;
            }

            if (endPeriod !== undefined) {
                localVarQueryParameter['endPeriod'] = endPeriod;
            }

            if (searchField !== undefined) {
                localVarQueryParameter['search_field'] = searchField;
            }

            if (searchStr !== undefined) {
                localVarQueryParameter['search_str'] = searchStr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {string} learnerId 
         * @param {number} lessonId 
         * @param {string} teacherId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} noneLessonFeedback 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonFeedbackList: async (companyId: string, paymentId: number, learnerId: string, lessonId: number, teacherId: string, startPeriod: string, endPeriod: string, noneLessonFeedback: number, limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackList', 'companyId', companyId)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackList', 'paymentId', paymentId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackList', 'learnerId', learnerId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackList', 'lessonId', lessonId)
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackList', 'teacherId', teacherId)
            // verify required parameter 'startPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackList', 'startPeriod', startPeriod)
            // verify required parameter 'endPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackList', 'endPeriod', endPeriod)
            // verify required parameter 'noneLessonFeedback' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackList', 'noneLessonFeedback', noneLessonFeedback)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackList', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackList', 'page', page)
            const localVarPath = `/api/organizations/get/lessonFeedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (learnerId !== undefined) {
                localVarQueryParameter['learnerId'] = learnerId;
            }

            if (lessonId !== undefined) {
                localVarQueryParameter['lessonId'] = lessonId;
            }

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            if (startPeriod !== undefined) {
                localVarQueryParameter['startPeriod'] = startPeriod;
            }

            if (endPeriod !== undefined) {
                localVarQueryParameter['endPeriod'] = endPeriod;
            }

            if (noneLessonFeedback !== undefined) {
                localVarQueryParameter['none_lesson_feedback'] = noneLessonFeedback;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {string} learnerId 
         * @param {string} teacherId 
         * @param {string} companyName 
         * @param {string} learnerName 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonFeedbackListBgd: async (companyId: string, paymentId: number, learnerId: string, teacherId: string, companyName: string, learnerName: string, startPeriod: string, endPeriod: string, limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackListBgd', 'companyId', companyId)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackListBgd', 'paymentId', paymentId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackListBgd', 'learnerId', learnerId)
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackListBgd', 'teacherId', teacherId)
            // verify required parameter 'companyName' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackListBgd', 'companyName', companyName)
            // verify required parameter 'learnerName' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackListBgd', 'learnerName', learnerName)
            // verify required parameter 'startPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackListBgd', 'startPeriod', startPeriod)
            // verify required parameter 'endPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackListBgd', 'endPeriod', endPeriod)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackListBgd', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetLessonFeedbackListBgd', 'page', page)
            const localVarPath = `/api/organizations/get/lessonFeedback_bgd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (learnerId !== undefined) {
                localVarQueryParameter['learnerId'] = learnerId;
            }

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            if (companyName !== undefined) {
                localVarQueryParameter['companyName'] = companyName;
            }

            if (learnerName !== undefined) {
                localVarQueryParameter['learnerName'] = learnerName;
            }

            if (startPeriod !== undefined) {
                localVarQueryParameter['startPeriod'] = startPeriod;
            }

            if (endPeriod !== undefined) {
                localVarQueryParameter['endPeriod'] = endPeriod;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonFees: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations/lessonFees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} learner 
         * @param {string} learnerId 
         * @param {string} company 
         * @param {string} searchStatus 
         * @param {string} lessonId 
         * @param {number} approvalFlg 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonListBgd: async (companyId: string, teacherId: string, learner: string, learnerId: string, company: string, searchStatus: string, lessonId: string, approvalFlg: number, startPeriod: string, endPeriod: string, limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonListBgd', 'companyId', companyId)
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonListBgd', 'teacherId', teacherId)
            // verify required parameter 'learner' is not null or undefined
            assertParamExists('organizationsControllerGetLessonListBgd', 'learner', learner)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonListBgd', 'learnerId', learnerId)
            // verify required parameter 'company' is not null or undefined
            assertParamExists('organizationsControllerGetLessonListBgd', 'company', company)
            // verify required parameter 'searchStatus' is not null or undefined
            assertParamExists('organizationsControllerGetLessonListBgd', 'searchStatus', searchStatus)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonListBgd', 'lessonId', lessonId)
            // verify required parameter 'approvalFlg' is not null or undefined
            assertParamExists('organizationsControllerGetLessonListBgd', 'approvalFlg', approvalFlg)
            // verify required parameter 'startPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetLessonListBgd', 'startPeriod', startPeriod)
            // verify required parameter 'endPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetLessonListBgd', 'endPeriod', endPeriod)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationsControllerGetLessonListBgd', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetLessonListBgd', 'page', page)
            const localVarPath = `/api/organizations/get/lessonList_bgd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            if (learner !== undefined) {
                localVarQueryParameter['learner'] = learner;
            }

            if (learnerId !== undefined) {
                localVarQueryParameter['learnerId'] = learnerId;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (searchStatus !== undefined) {
                localVarQueryParameter['searchStatus'] = searchStatus;
            }

            if (lessonId !== undefined) {
                localVarQueryParameter['lessonId'] = lessonId;
            }

            if (approvalFlg !== undefined) {
                localVarQueryParameter['approvalFlg'] = approvalFlg;
            }

            if (startPeriod !== undefined) {
                localVarQueryParameter['startPeriod'] = startPeriod;
            }

            if (endPeriod !== undefined) {
                localVarQueryParameter['endPeriod'] = endPeriod;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonNote: async (companyId: string, lessonId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonNote', 'companyId', companyId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonNote', 'lessonId', lessonId)
            // verify required parameter 'startPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetLessonNote', 'startPeriod', startPeriod)
            // verify required parameter 'endPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetLessonNote', 'endPeriod', endPeriod)
            // verify required parameter 'searchField' is not null or undefined
            assertParamExists('organizationsControllerGetLessonNote', 'searchField', searchField)
            // verify required parameter 'searchStr' is not null or undefined
            assertParamExists('organizationsControllerGetLessonNote', 'searchStr', searchStr)
            const localVarPath = `/api/organizations/{companyId}/lessonNote/{lessonId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startPeriod !== undefined) {
                localVarQueryParameter['startPeriod'] = startPeriod;
            }

            if (endPeriod !== undefined) {
                localVarQueryParameter['endPeriod'] = endPeriod;
            }

            if (searchField !== undefined) {
                localVarQueryParameter['search_field'] = searchField;
            }

            if (searchStr !== undefined) {
                localVarQueryParameter['search_str'] = searchStr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonNoteList: async (companyId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetLessonNoteList', 'companyId', companyId)
            // verify required parameter 'startPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetLessonNoteList', 'startPeriod', startPeriod)
            // verify required parameter 'endPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetLessonNoteList', 'endPeriod', endPeriod)
            // verify required parameter 'searchField' is not null or undefined
            assertParamExists('organizationsControllerGetLessonNoteList', 'searchField', searchField)
            // verify required parameter 'searchStr' is not null or undefined
            assertParamExists('organizationsControllerGetLessonNoteList', 'searchStr', searchStr)
            const localVarPath = `/api/organizations/get/{companyId}/lessonNoteList`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startPeriod !== undefined) {
                localVarQueryParameter['startPeriod'] = startPeriod;
            }

            if (endPeriod !== undefined) {
                localVarQueryParameter['endPeriod'] = endPeriod;
            }

            if (searchField !== undefined) {
                localVarQueryParameter['search_field'] = searchField;
            }

            if (searchStr !== undefined) {
                localVarQueryParameter['search_str'] = searchStr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetMaintenance: async (page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetMaintenance', 'page', page)
            const localVarPath = `/api/organizations/maintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetManagerUserList: async (limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationsControllerGetManagerUserList', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetManagerUserList', 'page', page)
            const localVarPath = `/api/organizations/managerUserList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetNotice: async (noticeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'noticeId' is not null or undefined
            assertParamExists('organizationsControllerGetNotice', 'noticeId', noticeId)
            const localVarPath = `/api/organizations/notice/{noticeId}`
                .replace(`{${"noticeId"}}`, encodeURIComponent(String(noticeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} noticeId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetNoticeCompany: async (noticeId: string, companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'noticeId' is not null or undefined
            assertParamExists('organizationsControllerGetNoticeCompany', 'noticeId', noticeId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetNoticeCompany', 'companyId', companyId)
            const localVarPath = `/api/organizations/notices/{noticeId}/company/{companyId}`
                .replace(`{${"noticeId"}}`, encodeURIComponent(String(noticeId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetNoticeCompanyForManager: async (noticeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'noticeId' is not null or undefined
            assertParamExists('organizationsControllerGetNoticeCompanyForManager', 'noticeId', noticeId)
            const localVarPath = `/api/organizations/notices/{noticeId}`
                .replace(`{${"noticeId"}}`, encodeURIComponent(String(noticeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} targetNumber 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetNoticeList: async (targetNumber: number, limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetNumber' is not null or undefined
            assertParamExists('organizationsControllerGetNoticeList', 'targetNumber', targetNumber)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationsControllerGetNoticeList', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetNoticeList', 'page', page)
            const localVarPath = `/api/organizations/get/noticeList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (targetNumber !== undefined) {
                localVarQueryParameter['targetNumber'] = targetNumber;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetOperationalStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations/operational_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {string} teacherId 
         * @param {string} learnerId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} noneLessonNote 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetPastLessonList: async (companyId: string, paymentId: number, teacherId: string, learnerId: string, startPeriod: string, endPeriod: string, noneLessonNote: number, limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonList', 'companyId', companyId)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonList', 'paymentId', paymentId)
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonList', 'teacherId', teacherId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonList', 'learnerId', learnerId)
            // verify required parameter 'startPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonList', 'startPeriod', startPeriod)
            // verify required parameter 'endPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonList', 'endPeriod', endPeriod)
            // verify required parameter 'noneLessonNote' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonList', 'noneLessonNote', noneLessonNote)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonList', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonList', 'page', page)
            const localVarPath = `/api/organizations/get/pastLessonList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            if (learnerId !== undefined) {
                localVarQueryParameter['learnerId'] = learnerId;
            }

            if (startPeriod !== undefined) {
                localVarQueryParameter['startPeriod'] = startPeriod;
            }

            if (endPeriod !== undefined) {
                localVarQueryParameter['endPeriod'] = endPeriod;
            }

            if (noneLessonNote !== undefined) {
                localVarQueryParameter['none_lesson_note'] = noneLessonNote;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} companyName 
         * @param {string} lessonId 
         * @param {string} learnerName 
         * @param {string} learnerId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetPastLessonListBgd: async (companyId: string, teacherId: string, companyName: string, lessonId: string, learnerName: string, learnerId: string, startPeriod: string, endPeriod: string, limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonListBgd', 'companyId', companyId)
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonListBgd', 'teacherId', teacherId)
            // verify required parameter 'companyName' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonListBgd', 'companyName', companyName)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonListBgd', 'lessonId', lessonId)
            // verify required parameter 'learnerName' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonListBgd', 'learnerName', learnerName)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonListBgd', 'learnerId', learnerId)
            // verify required parameter 'startPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonListBgd', 'startPeriod', startPeriod)
            // verify required parameter 'endPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonListBgd', 'endPeriod', endPeriod)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonListBgd', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetPastLessonListBgd', 'page', page)
            const localVarPath = `/api/organizations/get/pastLessonList_bgd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            if (companyName !== undefined) {
                localVarQueryParameter['companyName'] = companyName;
            }

            if (lessonId !== undefined) {
                localVarQueryParameter['lessonId'] = lessonId;
            }

            if (learnerName !== undefined) {
                localVarQueryParameter['learnerName'] = learnerName;
            }

            if (learnerId !== undefined) {
                localVarQueryParameter['learnerId'] = learnerId;
            }

            if (startPeriod !== undefined) {
                localVarQueryParameter['startPeriod'] = startPeriod;
            }

            if (endPeriod !== undefined) {
                localVarQueryParameter['endPeriod'] = endPeriod;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetPaymentCompany: async (companyId: string, paymentId: number, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentCompany', 'companyId', companyId)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentCompany', 'paymentId', paymentId)
            // verify required parameter 'searchStatus' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentCompany', 'searchStatus', searchStatus)
            // verify required parameter 'searchField' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentCompany', 'searchField', searchField)
            // verify required parameter 'searchStr' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentCompany', 'searchStr', searchStr)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentCompany', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentCompany', 'page', page)
            const localVarPath = `/api/organizations/{companyId}/paymentCompany`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (searchStatus !== undefined) {
                localVarQueryParameter['search_status'] = searchStatus;
            }

            if (searchField !== undefined) {
                localVarQueryParameter['search_field'] = searchField;
            }

            if (searchStr !== undefined) {
                localVarQueryParameter['search_str'] = searchStr;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} targetMonth 
         * @param {number} paymentId 
         * @param {string} learnerId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetPaymentCompanyList: async (companyId: string, targetMonth: string, paymentId: number, learnerId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentCompanyList', 'companyId', companyId)
            // verify required parameter 'targetMonth' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentCompanyList', 'targetMonth', targetMonth)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentCompanyList', 'paymentId', paymentId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentCompanyList', 'learnerId', learnerId)
            // verify required parameter 'searchStatus' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentCompanyList', 'searchStatus', searchStatus)
            // verify required parameter 'searchField' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentCompanyList', 'searchField', searchField)
            // verify required parameter 'searchStr' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentCompanyList', 'searchStr', searchStr)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentCompanyList', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentCompanyList', 'page', page)
            const localVarPath = `/api/organizations/get/{companyId}/paymentList`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (targetMonth !== undefined) {
                localVarQueryParameter['target_month'] = targetMonth;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (learnerId !== undefined) {
                localVarQueryParameter['learnerId'] = learnerId;
            }

            if (searchStatus !== undefined) {
                localVarQueryParameter['search_status'] = searchStatus;
            }

            if (searchField !== undefined) {
                localVarQueryParameter['search_field'] = searchField;
            }

            if (searchStr !== undefined) {
                localVarQueryParameter['search_str'] = searchStr;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetPaymentLearner: async (companyId: string, learnerId: string, yearMonth: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentLearner', 'companyId', companyId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentLearner', 'learnerId', learnerId)
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentLearner', 'yearMonth', yearMonth)
            const localVarPath = `/api/organizations/get/{companyId}/paymentLearner/{learnerId}/{yearMonth}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)))
                .replace(`{${"yearMonth"}}`, encodeURIComponent(String(yearMonth)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {string} learnerId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetPaymentLearnerList: async (companyId: string, paymentId: number, learnerId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentLearnerList', 'companyId', companyId)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentLearnerList', 'paymentId', paymentId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentLearnerList', 'learnerId', learnerId)
            // verify required parameter 'searchStatus' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentLearnerList', 'searchStatus', searchStatus)
            // verify required parameter 'searchField' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentLearnerList', 'searchField', searchField)
            // verify required parameter 'searchStr' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentLearnerList', 'searchStr', searchStr)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentLearnerList', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetPaymentLearnerList', 'page', page)
            const localVarPath = `/api/organizations/get/{companyId}/paymentLearnerList/{paymentId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (learnerId !== undefined) {
                localVarQueryParameter['learnerId'] = learnerId;
            }

            if (searchStatus !== undefined) {
                localVarQueryParameter['search_status'] = searchStatus;
            }

            if (searchField !== undefined) {
                localVarQueryParameter['search_field'] = searchField;
            }

            if (searchStr !== undefined) {
                localVarQueryParameter['search_str'] = searchStr;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} paymentId 
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetReportStudyRecords: async (companyId: string, paymentId: string, yearMonth: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetReportStudyRecords', 'companyId', companyId)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('organizationsControllerGetReportStudyRecords', 'paymentId', paymentId)
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('organizationsControllerGetReportStudyRecords', 'yearMonth', yearMonth)
            const localVarPath = `/api/organizations/get/{companyId}/{paymentId}/{yearMonth}/StudyRecordsAll`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)))
                .replace(`{${"yearMonth"}}`, encodeURIComponent(String(yearMonth)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetStudyRecords: async (companyId: string, learnerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetStudyRecords', 'companyId', companyId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerGetStudyRecords', 'learnerId', learnerId)
            const localVarPath = `/api/organizations/get/{companyId}/StudyRecords/{learnerId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetSystemLearnerNotice: async (learnerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerGetSystemLearnerNotice', 'learnerId', learnerId)
            const localVarPath = `/api/organizations/systemLearnerNotice/{learnerId}`
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetTargetMonthPaymentCompanyList: async (companyId: string, learnerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetTargetMonthPaymentCompanyList', 'companyId', companyId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerGetTargetMonthPaymentCompanyList', 'learnerId', learnerId)
            const localVarPath = `/api/organizations/get/targetMonthPaymentList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (learnerId !== undefined) {
                localVarQueryParameter['learnerId'] = learnerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetTeacherByTeacherId: async (teacherId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherByTeacherId', 'teacherId', teacherId)
            const localVarPath = `/api/organizations/teacherInfo/{teacherId}`
                .replace(`{${"teacherId"}}`, encodeURIComponent(String(teacherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} yearMonth 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetTeacherLessonFee: async (companyId: string, teacherId: string, yearMonth: string, endPeriod: string, searchField: string, searchStr: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherLessonFee', 'companyId', companyId)
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherLessonFee', 'teacherId', teacherId)
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherLessonFee', 'yearMonth', yearMonth)
            // verify required parameter 'endPeriod' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherLessonFee', 'endPeriod', endPeriod)
            // verify required parameter 'searchField' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherLessonFee', 'searchField', searchField)
            // verify required parameter 'searchStr' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherLessonFee', 'searchStr', searchStr)
            const localVarPath = `/api/organizations/get/{teacherId}/LessonFee/{yearMonth}`
                .replace(`{${"teacherId"}}`, encodeURIComponent(String(teacherId)))
                .replace(`{${"yearMonth"}}`, encodeURIComponent(String(yearMonth)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (endPeriod !== undefined) {
                localVarQueryParameter['endPeriod'] = endPeriod;
            }

            if (searchField !== undefined) {
                localVarQueryParameter['search_field'] = searchField;
            }

            if (searchStr !== undefined) {
                localVarQueryParameter['search_str'] = searchStr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {string} yearMonth 
         * @param {number} status 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetTeacherLessonFeeList: async (teacherId: string, yearMonth: string, status: number, limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherLessonFeeList', 'teacherId', teacherId)
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherLessonFeeList', 'yearMonth', yearMonth)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherLessonFeeList', 'status', status)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherLessonFeeList', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherLessonFeeList', 'page', page)
            const localVarPath = `/api/organizations/get/LessonFeeList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetTeacherUserList: async (teacherId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherUserList', 'teacherId', teacherId)
            // verify required parameter 'searchStatus' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherUserList', 'searchStatus', searchStatus)
            // verify required parameter 'searchField' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherUserList', 'searchField', searchField)
            // verify required parameter 'searchStr' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherUserList', 'searchStr', searchStr)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherUserList', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('organizationsControllerGetTeacherUserList', 'page', page)
            const localVarPath = `/api/organizations/get/teacherList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            if (searchStatus !== undefined) {
                localVarQueryParameter['searchStatus'] = searchStatus;
            }

            if (searchField !== undefined) {
                localVarQueryParameter['searchField'] = searchField;
            }

            if (searchStr !== undefined) {
                localVarQueryParameter['searchStr'] = searchStr;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} identity 
         * @param {string} id 
         * @param {string} roomName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetTwilioAccessToken: async (companyId: string, identity: string, id: string, roomName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerGetTwilioAccessToken', 'companyId', companyId)
            // verify required parameter 'identity' is not null or undefined
            assertParamExists('organizationsControllerGetTwilioAccessToken', 'identity', identity)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsControllerGetTwilioAccessToken', 'id', id)
            // verify required parameter 'roomName' is not null or undefined
            assertParamExists('organizationsControllerGetTwilioAccessToken', 'roomName', roomName)
            const localVarPath = `/api/organizations/{companyId}/user/{identity}/{id}/roomName/{roomName}/getTwilioAccessToken`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"identity"}}`, encodeURIComponent(String(identity)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"roomName"}}`, encodeURIComponent(String(roomName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} roomId 
         * @param {CreateRoomChatInput} createRoomChatInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerPutCompanyRoomChat: async (companyId: string, roomId: string, createRoomChatInput: CreateRoomChatInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerPutCompanyRoomChat', 'companyId', companyId)
            // verify required parameter 'roomId' is not null or undefined
            assertParamExists('organizationsControllerPutCompanyRoomChat', 'roomId', roomId)
            // verify required parameter 'createRoomChatInput' is not null or undefined
            assertParamExists('organizationsControllerPutCompanyRoomChat', 'createRoomChatInput', createRoomChatInput)
            const localVarPath = `/api/organizations/{companyId}/{roomId}/chat`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"roomId"}}`, encodeURIComponent(String(roomId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoomChatInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} releaseFlg 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerReleaseNotice: async (id: string, releaseFlg: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsControllerReleaseNotice', 'id', id)
            // verify required parameter 'releaseFlg' is not null or undefined
            assertParamExists('organizationsControllerReleaseNotice', 'releaseFlg', releaseFlg)
            const localVarPath = `/api/organizations/release/noticeList/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (releaseFlg !== undefined) {
                localVarQueryParameter['release_flg'] = releaseFlg;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mailSubject 
         * @param {string} mailerId 
         * @param {string} mailerName 
         * @param {string} kinds 
         * @param {string} mailBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerSendInquiryEmail: async (mailSubject: string, mailerId: string, mailerName: string, kinds: string, mailBody: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailSubject' is not null or undefined
            assertParamExists('organizationsControllerSendInquiryEmail', 'mailSubject', mailSubject)
            // verify required parameter 'mailerId' is not null or undefined
            assertParamExists('organizationsControllerSendInquiryEmail', 'mailerId', mailerId)
            // verify required parameter 'mailerName' is not null or undefined
            assertParamExists('organizationsControllerSendInquiryEmail', 'mailerName', mailerName)
            // verify required parameter 'kinds' is not null or undefined
            assertParamExists('organizationsControllerSendInquiryEmail', 'kinds', kinds)
            // verify required parameter 'mailBody' is not null or undefined
            assertParamExists('organizationsControllerSendInquiryEmail', 'mailBody', mailBody)
            const localVarPath = `/api/organizations/sendInquiryEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mailSubject !== undefined) {
                localVarQueryParameter['mailSubject'] = mailSubject;
            }

            if (mailerId !== undefined) {
                localVarQueryParameter['mailerId'] = mailerId;
            }

            if (mailerName !== undefined) {
                localVarQueryParameter['mailerName'] = mailerName;
            }

            if (kinds !== undefined) {
                localVarQueryParameter['kinds'] = kinds;
            }

            if (mailBody !== undefined) {
                localVarQueryParameter['mailBody'] = mailBody;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} sendQueryEmail 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerSendQueryEmail: async (companyId: string, sendQueryEmail: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerSendQueryEmail', 'companyId', companyId)
            // verify required parameter 'sendQueryEmail' is not null or undefined
            assertParamExists('organizationsControllerSendQueryEmail', 'sendQueryEmail', sendQueryEmail)
            // verify required parameter 'startPeriod' is not null or undefined
            assertParamExists('organizationsControllerSendQueryEmail', 'startPeriod', startPeriod)
            // verify required parameter 'endPeriod' is not null or undefined
            assertParamExists('organizationsControllerSendQueryEmail', 'endPeriod', endPeriod)
            // verify required parameter 'searchField' is not null or undefined
            assertParamExists('organizationsControllerSendQueryEmail', 'searchField', searchField)
            // verify required parameter 'searchStr' is not null or undefined
            assertParamExists('organizationsControllerSendQueryEmail', 'searchStr', searchStr)
            const localVarPath = `/api/organizations/{sendQueryEmail}`
                .replace(`{${"sendQueryEmail"}}`, encodeURIComponent(String(sendQueryEmail)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (startPeriod !== undefined) {
                localVarQueryParameter['startPeriod'] = startPeriod;
            }

            if (endPeriod !== undefined) {
                localVarQueryParameter['endPeriod'] = endPeriod;
            }

            if (searchField !== undefined) {
                localVarQueryParameter['search_field'] = searchField;
            }

            if (searchStr !== undefined) {
                localVarQueryParameter['search_str'] = searchStr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {UpdateAgreementInput} updateAgreementInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateAgreement: async (companyId: string, updateAgreementInput: UpdateAgreementInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerUpdateAgreement', 'companyId', companyId)
            // verify required parameter 'updateAgreementInput' is not null or undefined
            assertParamExists('organizationsControllerUpdateAgreement', 'updateAgreementInput', updateAgreementInput)
            const localVarPath = `/api/organizations/update/{companyId}/paymentList`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAgreementInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} paymentId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateAgreementPlan: async (companyId: string, paymentId: string, body: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerUpdateAgreementPlan', 'companyId', companyId)
            // verify required parameter 'paymentId' is not null or undefined
            assertParamExists('organizationsControllerUpdateAgreementPlan', 'paymentId', paymentId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('organizationsControllerUpdateAgreementPlan', 'body', body)
            const localVarPath = `/api/organizations/updatePlan/{companyId}/{paymentId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {string} yearMonth 
         * @param {number} billedFlg 
         * @param {number} finalFee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateBillingStatus: async (teacherId: string, yearMonth: string, billedFlg: number, finalFee: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerUpdateBillingStatus', 'teacherId', teacherId)
            // verify required parameter 'yearMonth' is not null or undefined
            assertParamExists('organizationsControllerUpdateBillingStatus', 'yearMonth', yearMonth)
            // verify required parameter 'billedFlg' is not null or undefined
            assertParamExists('organizationsControllerUpdateBillingStatus', 'billedFlg', billedFlg)
            // verify required parameter 'finalFee' is not null or undefined
            assertParamExists('organizationsControllerUpdateBillingStatus', 'finalFee', finalFee)
            const localVarPath = `/api/organizations/update/updateBillingStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (teacherId !== undefined) {
                localVarQueryParameter['teacherId'] = teacherId;
            }

            if (yearMonth !== undefined) {
                localVarQueryParameter['yearMonth'] = yearMonth;
            }

            if (billedFlg !== undefined) {
                localVarQueryParameter['billedFlg'] = billedFlg;
            }

            if (finalFee !== undefined) {
                localVarQueryParameter['finalFee'] = finalFee;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {UpdateCompanyInput} updateCompanyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateCompany: async (companyId: string, updateCompanyInput: UpdateCompanyInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerUpdateCompany', 'companyId', companyId)
            // verify required parameter 'updateCompanyInput' is not null or undefined
            assertParamExists('organizationsControllerUpdateCompany', 'updateCompanyInput', updateCompanyInput)
            const localVarPath = `/api/organizations/update/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} maintenanceId 
         * @param {boolean} immediateFlg 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateDeMaintenance: async (maintenanceId: number, immediateFlg: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'maintenanceId' is not null or undefined
            assertParamExists('organizationsControllerUpdateDeMaintenance', 'maintenanceId', maintenanceId)
            // verify required parameter 'immediateFlg' is not null or undefined
            assertParamExists('organizationsControllerUpdateDeMaintenance', 'immediateFlg', immediateFlg)
            const localVarPath = `/api/organizations/maintenances/{maintenance_id}/de_maintenance`
                .replace(`{${"maintenance_id"}}`, encodeURIComponent(String(maintenanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (immediateFlg !== undefined) {
                localVarQueryParameter['immediate_flg'] = immediateFlg;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {UpdateLearnerInput} updateLearnerInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateLearnerUser: async (companyId: string, learnerId: string, updateLearnerInput: UpdateLearnerInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerUpdateLearnerUser', 'companyId', companyId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerUpdateLearnerUser', 'learnerId', learnerId)
            // verify required parameter 'updateLearnerInput' is not null or undefined
            assertParamExists('organizationsControllerUpdateLearnerUser', 'updateLearnerInput', updateLearnerInput)
            const localVarPath = `/api/organizations/update/{companyId}/learnerUser/{learnerId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLearnerInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateLesson: async (companyId: string, lessonId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerUpdateLesson', 'companyId', companyId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerUpdateLesson', 'lessonId', lessonId)
            // verify required parameter 'startPeriod' is not null or undefined
            assertParamExists('organizationsControllerUpdateLesson', 'startPeriod', startPeriod)
            // verify required parameter 'endPeriod' is not null or undefined
            assertParamExists('organizationsControllerUpdateLesson', 'endPeriod', endPeriod)
            // verify required parameter 'searchField' is not null or undefined
            assertParamExists('organizationsControllerUpdateLesson', 'searchField', searchField)
            // verify required parameter 'searchStr' is not null or undefined
            assertParamExists('organizationsControllerUpdateLesson', 'searchStr', searchStr)
            const localVarPath = `/api/organizations/update/{companyId}/lesson/{lessonId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startPeriod !== undefined) {
                localVarQueryParameter['startPeriod'] = startPeriod;
            }

            if (endPeriod !== undefined) {
                localVarQueryParameter['endPeriod'] = endPeriod;
            }

            if (searchField !== undefined) {
                localVarQueryParameter['search_field'] = searchField;
            }

            if (searchStr !== undefined) {
                localVarQueryParameter['search_str'] = searchStr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} learnerId 
         * @param {UpdateLessonFeedbackInput} updateLessonFeedbackInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateLessonFeedback: async (companyId: string, lessonId: string, learnerId: string, updateLessonFeedbackInput: UpdateLessonFeedbackInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerUpdateLessonFeedback', 'companyId', companyId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerUpdateLessonFeedback', 'lessonId', lessonId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerUpdateLessonFeedback', 'learnerId', learnerId)
            // verify required parameter 'updateLessonFeedbackInput' is not null or undefined
            assertParamExists('organizationsControllerUpdateLessonFeedback', 'updateLessonFeedbackInput', updateLessonFeedbackInput)
            const localVarPath = `/api/organizations/lessonFeedback/{companyId}/{lessonId}/{learnerId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLessonFeedbackInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} learnerId 
         * @param {boolean} lessonLearnerStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateLessonLearnerStatus: async (companyId: string, lessonId: string, learnerId: string, lessonLearnerStatus: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerUpdateLessonLearnerStatus', 'companyId', companyId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerUpdateLessonLearnerStatus', 'lessonId', lessonId)
            // verify required parameter 'learnerId' is not null or undefined
            assertParamExists('organizationsControllerUpdateLessonLearnerStatus', 'learnerId', learnerId)
            // verify required parameter 'lessonLearnerStatus' is not null or undefined
            assertParamExists('organizationsControllerUpdateLessonLearnerStatus', 'lessonLearnerStatus', lessonLearnerStatus)
            const localVarPath = `/api/organizations/update/{companyId}/lessonLearner/{lessonLearnerStatus}/{lessonId}/{learnerId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)))
                .replace(`{${"learnerId"}}`, encodeURIComponent(String(learnerId)))
                .replace(`{${"lessonLearnerStatus"}}`, encodeURIComponent(String(lessonLearnerStatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} note 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateLessonNote: async (companyId: string, lessonId: string, note: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerUpdateLessonNote', 'companyId', companyId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerUpdateLessonNote', 'lessonId', lessonId)
            // verify required parameter 'note' is not null or undefined
            assertParamExists('organizationsControllerUpdateLessonNote', 'note', note)
            const localVarPath = `/api/organizations/update/{companyId}/lessonNote/{lessonId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (note !== undefined) {
                localVarQueryParameter['note'] = note;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateLessonStatusTeacher: async (companyId: string, lessonId: string, status: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerUpdateLessonStatusTeacher', 'companyId', companyId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerUpdateLessonStatusTeacher', 'lessonId', lessonId)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('organizationsControllerUpdateLessonStatusTeacher', 'status', status)
            const localVarPath = `/api/organizations/{companyId}/updateLessonStatus/{lessonId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} maintenanceId 
         * @param {CreateMaintenanceInput} createMaintenanceInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateMaintenance: async (maintenanceId: number, createMaintenanceInput: CreateMaintenanceInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'maintenanceId' is not null or undefined
            assertParamExists('organizationsControllerUpdateMaintenance', 'maintenanceId', maintenanceId)
            // verify required parameter 'createMaintenanceInput' is not null or undefined
            assertParamExists('organizationsControllerUpdateMaintenance', 'createMaintenanceInput', createMaintenanceInput)
            const localVarPath = `/api/organizations/maintenance/{maintenance_id}`
                .replace(`{${"maintenance_id"}}`, encodeURIComponent(String(maintenanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMaintenanceInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateNoticeInput} createNoticeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateNotice: async (id: string, createNoticeInput: CreateNoticeInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('organizationsControllerUpdateNotice', 'id', id)
            // verify required parameter 'createNoticeInput' is not null or undefined
            assertParamExists('organizationsControllerUpdateNotice', 'createNoticeInput', createNoticeInput)
            const localVarPath = `/api/organizations/update/notice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNoticeInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} noticeId 
         * @param {boolean} readStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateSystemLearnerNoticeReadStatus: async (noticeId: string, readStatus: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'noticeId' is not null or undefined
            assertParamExists('organizationsControllerUpdateSystemLearnerNoticeReadStatus', 'noticeId', noticeId)
            // verify required parameter 'readStatus' is not null or undefined
            assertParamExists('organizationsControllerUpdateSystemLearnerNoticeReadStatus', 'readStatus', readStatus)
            const localVarPath = `/api/organizations/update/systemNoticeLearner/{noticeId}`
                .replace(`{${"noticeId"}}`, encodeURIComponent(String(noticeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (readStatus !== undefined) {
                localVarQueryParameter['read_status'] = readStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {ConnectionId} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateTeacherConnectionId: async (companyId: string, teacherId: string, connectionId: ConnectionId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerUpdateTeacherConnectionId', 'companyId', companyId)
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerUpdateTeacherConnectionId', 'teacherId', teacherId)
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('organizationsControllerUpdateTeacherConnectionId', 'connectionId', connectionId)
            const localVarPath = `/api/organizations/{companyId}/{teacherId}/connectionId`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"teacherId"}}`, encodeURIComponent(String(teacherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectionId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {UpdateTeacherInput} updateTeacherInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateTeacherUser: async (teacherId: string, updateTeacherInput: UpdateTeacherInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('organizationsControllerUpdateTeacherUser', 'teacherId', teacherId)
            // verify required parameter 'updateTeacherInput' is not null or undefined
            assertParamExists('organizationsControllerUpdateTeacherUser', 'updateTeacherInput', updateTeacherInput)
            const localVarPath = `/api/organizations/update/teacher/{teacherId}`
                .replace(`{${"teacherId"}}`, encodeURIComponent(String(teacherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTeacherInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} theme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateThemeOnLesson: async (companyId: string, lessonId: string, theme: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('organizationsControllerUpdateThemeOnLesson', 'companyId', companyId)
            // verify required parameter 'lessonId' is not null or undefined
            assertParamExists('organizationsControllerUpdateThemeOnLesson', 'lessonId', lessonId)
            // verify required parameter 'theme' is not null or undefined
            assertParamExists('organizationsControllerUpdateThemeOnLesson', 'theme', theme)
            const localVarPath = `/api/organizations/updateTheme/{companyId}/{lessonId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"lessonId"}}`, encodeURIComponent(String(lessonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (theme !== undefined) {
                localVarQueryParameter['theme'] = theme;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerVerifyCognitoUserEmail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations/verifyEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sandboxControllerCognitoSandbox: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sandbox/cognito`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sandboxControllerMailSandbox: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sandbox/mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherControllerGetMostRecentScheduledLesson: async (teacherId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('teacherControllerGetMostRecentScheduledLesson', 'teacherId', teacherId)
            const localVarPath = `/api/teacher/most-recent-scheduled-lesson/{teacherId}`
                .replace(`{${"teacherId"}}`, encodeURIComponent(String(teacherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherControllerGetNotUpdatedNoteLessons: async (teacherId: string, limit: number, page: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('teacherControllerGetNotUpdatedNoteLessons', 'teacherId', teacherId)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('teacherControllerGetNotUpdatedNoteLessons', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('teacherControllerGetNotUpdatedNoteLessons', 'page', page)
            const localVarPath = `/api/teacher/lessons/not-updated-note/{teacherId}`
                .replace(`{${"teacherId"}}`, encodeURIComponent(String(teacherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherControllerGetUnaprovedLessons: async (teacherId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('teacherControllerGetUnaprovedLessons', 'teacherId', teacherId)
            const localVarPath = `/api/teacher/lessons/unaproved/{teacherId}`
                .replace(`{${"teacherId"}}`, encodeURIComponent(String(teacherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoggingInput} loggingInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userEventControllerLoggingEvent: async (loggingInput: LoggingInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loggingInput' is not null or undefined
            assertParamExists('userEventControllerLoggingEvent', 'loggingInput', loggingInput)
            const localVarPath = `/api/user-event/logging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loggingInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoggingInput} loggingInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userEventControllerLoggingEventByNotAuth: async (loggingInput: LoggingInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loggingInput' is not null or undefined
            assertParamExists('userEventControllerLoggingEventByNotAuth', 'loggingInput', loggingInput)
            const localVarPath = `/api/user-event/logging-not-auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT-auth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loggingInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * APIApi - functional programming interface
 * @export
 */
export const APIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = APIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateInquiryInput} createInquiryInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inquiryControllerSendInquiryEmail(createInquiryInput: CreateInquiryInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inquiryControllerSendInquiryEmail(createInquiryInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerCreateActiveLearnerLog(learnerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateActiveLearnerLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerCreateActiveLearnerLog(learnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} learnerId 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {LearnerLessonLogInput} learnerLessonLogInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerCreateLessonEnteringLogLearner(learnerId: string, companyId: string, lessonId: string, learnerLessonLogInput: LearnerLessonLogInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateLessonEnteringLogLearnerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerCreateLessonEnteringLogLearner(learnerId, companyId, lessonId, learnerLessonLogInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerDeactivateLearnerEndpoints(companyId: string, learnerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeactivateLearnerEndPointsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerDeactivateLearnerEndpoints(companyId, learnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerGetApprovedLessons(learnerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetApprovedLessonsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetApprovedLessons(learnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerGetLearnerActiveEndpointCount(companyId: string, learnerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLearnerActiveEndPointCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetLearnerActiveEndpointCount(companyId, learnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerGetLearnerByEmail(companyId: string, email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLearnerByEmailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetLearnerByEmail(companyId, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerGetLearnerEndpoint(companyId: string, learnerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLearnerDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetLearnerEndpoint(companyId, learnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerGetLernerById(companyId: string, learnerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLearnerByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetLernerById(companyId, learnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerGetLoginedLearnerDetail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLearnerDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetLoginedLearnerDetail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerGetMostRecentScheduledLesson(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMostRecentScheduledLessonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetMostRecentScheduledLesson(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerGetNoticeRead(noticeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNoticeReadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetNoticeRead(noticeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerGetNoticeUnreadCount(userName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNoticeUnreadCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetNoticeUnreadCount(userName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerGetNotices(limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNoticesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetNotices(limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerGetPastLessons(limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPastLessonsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetPastLessons(limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerGetPushMsg(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPushMsgResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetPushMsg(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} pushMsgQId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerGetPushMsgLog(companyId: string, pushMsgQId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPushMsgLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetPushMsgLog(companyId, pushMsgQId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerGetPushVapidPublicKey(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPushVapidPublicKeyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetPushVapidPublicKey(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerGetStudyRecords(userName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStudyRecordsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetStudyRecords(userName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DrillParam} drillParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerPostStudyRecordDrill(drillParam: DrillParam, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostStudyRecordStoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerPostStudyRecordDrill(drillParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StudyResult} studyResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerPostStudyRecordStory(studyResult: StudyResult, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostStudyRecordStoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerPostStudyRecordStory(studyResult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDrillResult} updateDrillResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerPutDrillRecord(updateDrillResult: UpdateDrillResult, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutDrillRecordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerPutDrillRecord(updateDrillResult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} noticeId 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerPutNoticeRead(companyId: string, noticeId: number, userName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutNoticeReadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerPutNoticeRead(companyId, noticeId, userName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateStudyResult} updateStudyResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerPutStudyRecord(updateStudyResult: UpdateStudyResult, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutStudyRecordRecordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerPutStudyRecord(updateStudyResult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {ConnectionId} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerUpdateLearnerConnectionId(companyId: string, learnerId: string, connectionId: ConnectionId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateLearnerConnectionIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerUpdateLearnerConnectionId(companyId, learnerId, connectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerUpdateLearnerEmail(companyId: string, learnerId: string, email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateLearnerEmailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerUpdateLearnerEmail(companyId, learnerId, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {string} emailSendFlg 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerUpdateLearnerEmailSendFlg(companyId: string, learnerId: string, emailSendFlg: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateLearnerEmailSendFlgResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerUpdateLearnerEmailSendFlg(companyId, learnerId, emailSendFlg, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {LearnerEndpoint} learnerEndpoint 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerUpsertLearnerEndpoint(companyId: string, learnerId: string, learnerEndpoint: LearnerEndpoint, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsertLearnerEndPointResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerUpsertLearnerEndpoint(companyId, learnerId, learnerEndpoint, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {PushMsg} pushMsg 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerUpsertPushMsg(companyId: string, pushMsg: PushMsg, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsertPushMsgResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerUpsertPushMsg(companyId, pushMsg, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {PushMsgLog} pushMsgLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerUpsertPushMsgLog(companyId: string, pushMsgLog: PushMsgLog, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpsertPushMsgLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerUpsertPushMsgLog(companyId, pushMsgLog, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerVerifyCognitoUserEmail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyCognitoUserEmailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerVerifyCognitoUserEmail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {string} email 
         * @param {VerifyInfo} verifyInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileAppControllerVerifyLearnerEmail(companyId: string, learnerId: string, email: string, verifyInfo: VerifyInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyLearnerEmailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerVerifyLearnerEmail(companyId, learnerId, email, verifyInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerApprovalLessonTeacher(companyId: string, lessonId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerApprovalLessonTeacher(companyId, lessonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {CancelLessonInput} cancelLessonInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerCancelLesson(companyId: string, lessonId: string, cancelLessonInput: CancelLessonInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCancelLesson(companyId, lessonId, cancelLessonInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerChangeLessonTeacher(companyId: string, lessonId: string, teacherId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerChangeLessonTeacher(companyId, lessonId, teacherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} roomSid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerComposeTwilioVideoRecording(companyId: string, roomSid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerComposeTwilioVideoRecording(companyId, roomSid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {CreateAgreementInput} createAgreementInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerCreateAgreement(companyId: string, createAgreementInput: CreateAgreementInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateAgreement(companyId, createAgreementInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {CreateCompanyInput} createCompanyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerCreateCompany(companyId: string, createCompanyInput: CreateCompanyInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateCompany(companyId, createCompanyInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {CreateCompanyUserInput} createCompanyUserInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerCreateCompanyUser(companyId: string, createCompanyUserInput: CreateCompanyUserInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateCompanyUser(companyId, createCompanyUserInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateErrorLog} createErrorLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerCreateErrorLog(createErrorLog: CreateErrorLog, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateErrorLog(createErrorLog, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {CreateLearnerInput} createLearnerInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerCreateLearnerUser(companyId: string, paymentId: number, createLearnerInput: CreateLearnerInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateLearnerUser(companyId, paymentId, createLearnerInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} startDatetime 
         * @param {string} endDatetime 
         * @param {CreateLessonInput} createLessonInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerCreateLesson(companyId: string, teacherId: string, startDatetime: string, endDatetime: string, createLessonInput: CreateLessonInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateLesson(companyId, teacherId, startDatetime, endDatetime, createLessonInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {CreateLessonFeeInput} createLessonFeeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerCreateLessonFee(teacherId: string, createLessonFeeInput: CreateLessonFeeInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateLessonFee(teacherId, createLessonFeeInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {CreateLessonFeeHistoryInput} createLessonFeeHistoryInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerCreateLessonFeeHistory(teacherId: string, createLessonFeeHistoryInput: CreateLessonFeeHistoryInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateLessonFeeHistory(teacherId, createLessonFeeHistoryInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateMaintenanceInput} createMaintenanceInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerCreateMaintenance(createMaintenanceInput: CreateMaintenanceInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateMaintenance(createMaintenanceInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dummy 
         * @param {CreateManagerInput} createManagerInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerCreateManagerUser(dummy: string, createManagerInput: CreateManagerInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateManagerUser(dummy, createManagerInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dummy 
         * @param {CreateNoticeInput} createNoticeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerCreateNotice(dummy: string, createNoticeInput: CreateNoticeInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateNotice(dummy, createNoticeInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateNoticeCompanyInput} createNoticeCompanyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerCreateNoticeCompany(createNoticeCompanyInput: CreateNoticeCompanyInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateNoticeCompany(createNoticeCompanyInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {CreateTeacherInput} createTeacherInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerCreateTeacherUser(teacherId: string, createTeacherInput: CreateTeacherInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateTeacherUser(teacherId, createTeacherInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerDeleteAgreement(companyId: string, paymentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteAgreement(companyId, paymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerDeleteCompany(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteCompany(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerDeleteCompanyUser(companyId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteCompanyUser(companyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerDeleteLearnerUser(companyId: string, learnerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteLearnerUser(companyId, learnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerDeleteLessonFeeOption(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteLessonFeeOption(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} maintenanceId 
         * @param {string} maintenanceStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerDeleteMaintenance(maintenanceId: number, maintenanceStatus: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteMaintenance(maintenanceId, maintenanceStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} managerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerDeleteManagerUser(managerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteManagerUser(managerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerDeleteMonthlyAgreement(companyId: string, body: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteMonthlyAgreement(companyId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerDeleteNotice(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteNotice(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerDeleteTeacherUser(teacherId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteTeacherUser(teacherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExistsLearnerInput} existsLearnerInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerExistsLearner(existsLearnerInput: ExistsLearnerInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerExistsLearner(existsLearnerInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExistsLearnerInPlanInput} existsLearnerInPlanInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerExistsLearnerInPlan(existsLearnerInPlanInput: ExistsLearnerInPlanInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerExistsLearnerInPlan(existsLearnerInPlanInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetAvailableCompanies(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetAvailableCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetCompany(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetCompany(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {string} sortStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetCompanyList(companyId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, sortStr: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetCompanyList(companyId, searchStatus, searchField, searchStr, limit, page, sortStr, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetCompanyPlans(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetCompanyPlans(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetCompanyUserList(companyId: string, limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetCompanyUserList(companyId, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetConnectionIdByRoomId(companyId: string, roomId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetConnectionIdByRoomId(companyId, roomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetEachLearnerStudyRecordsPdf(companyId: string, paymentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetEachLearnerStudyRecordsPdf(companyId, paymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {string} teacherId 
         * @param {string} learnerId 
         * @param {string} lessonId 
         * @param {number} approvalFlg 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} futureOnly 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetFutureLessonList(companyId: string, paymentId: number, teacherId: string, learnerId: string, lessonId: string, approvalFlg: number, startPeriod: string, endPeriod: string, futureOnly: number, limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetFutureLessonList(companyId, paymentId, teacherId, learnerId, lessonId, approvalFlg, startPeriod, endPeriod, futureOnly, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} learner 
         * @param {string} learnerId 
         * @param {string} company 
         * @param {string} searchStatus 
         * @param {string} lessonId 
         * @param {number} approvalFlg 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetFutureLessonListBgd(companyId: string, teacherId: string, learner: string, learnerId: string, company: string, searchStatus: string, lessonId: string, approvalFlg: number, startPeriod: string, endPeriod: string, limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetFutureLessonListBgd(companyId, teacherId, learner, learnerId, company, searchStatus, lessonId, approvalFlg, startPeriod, endPeriod, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetLatestPastLessonNote(companyId: string, learnerId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLatestPastLessonNote(companyId, learnerId, startPeriod, endPeriod, searchField, searchStr, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetLeanerUserListByLesson(companyId: string, lessonId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLeanerUserListByLesson(companyId, lessonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetLearnerStudyRecords(companyId: string, learnerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLearnerStudyRecords(companyId, learnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetLearnerUserList(companyId: string, learnerId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLearnerUserList(companyId, learnerId, searchStatus, searchField, searchStr, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} lessonId 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetLesson(companyId: string, teacherId: string, lessonId: string, searchField: string, searchStr: string, startPeriod: string, endPeriod: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLesson(companyId, teacherId, lessonId, searchField, searchStr, startPeriod, endPeriod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetLessonByCompanyNLessonID(companyId: string, lessonId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessonByCompanyNLessonID(companyId, lessonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetLessonByRoomId(companyId: string, roomId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessonByRoomId(companyId, roomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} learnerId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetLessonFeedback(companyId: string, lessonId: string, learnerId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessonFeedback(companyId, lessonId, learnerId, startPeriod, endPeriod, searchField, searchStr, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {string} learnerId 
         * @param {number} lessonId 
         * @param {string} teacherId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} noneLessonFeedback 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetLessonFeedbackList(companyId: string, paymentId: number, learnerId: string, lessonId: number, teacherId: string, startPeriod: string, endPeriod: string, noneLessonFeedback: number, limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessonFeedbackList(companyId, paymentId, learnerId, lessonId, teacherId, startPeriod, endPeriod, noneLessonFeedback, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {string} learnerId 
         * @param {string} teacherId 
         * @param {string} companyName 
         * @param {string} learnerName 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetLessonFeedbackListBgd(companyId: string, paymentId: number, learnerId: string, teacherId: string, companyName: string, learnerName: string, startPeriod: string, endPeriod: string, limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessonFeedbackListBgd(companyId, paymentId, learnerId, teacherId, companyName, learnerName, startPeriod, endPeriod, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetLessonFees(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessonFees(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} learner 
         * @param {string} learnerId 
         * @param {string} company 
         * @param {string} searchStatus 
         * @param {string} lessonId 
         * @param {number} approvalFlg 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetLessonListBgd(companyId: string, teacherId: string, learner: string, learnerId: string, company: string, searchStatus: string, lessonId: string, approvalFlg: number, startPeriod: string, endPeriod: string, limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessonListBgd(companyId, teacherId, learner, learnerId, company, searchStatus, lessonId, approvalFlg, startPeriod, endPeriod, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetLessonNote(companyId: string, lessonId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessonNote(companyId, lessonId, startPeriod, endPeriod, searchField, searchStr, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetLessonNoteList(companyId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessonNoteList(companyId, startPeriod, endPeriod, searchField, searchStr, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetMaintenance(page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetMaintenance(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetManagerUserList(limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetManagerUserList(limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetNotice(noticeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetNotice(noticeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} noticeId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetNoticeCompany(noticeId: string, companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetNoticeCompany(noticeId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetNoticeCompanyForManager(noticeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetNoticeCompanyForManager(noticeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} targetNumber 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetNoticeList(targetNumber: number, limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetNoticeList(targetNumber, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetOperationalStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetOperationalStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {string} teacherId 
         * @param {string} learnerId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} noneLessonNote 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetPastLessonList(companyId: string, paymentId: number, teacherId: string, learnerId: string, startPeriod: string, endPeriod: string, noneLessonNote: number, limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetPastLessonList(companyId, paymentId, teacherId, learnerId, startPeriod, endPeriod, noneLessonNote, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} companyName 
         * @param {string} lessonId 
         * @param {string} learnerName 
         * @param {string} learnerId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetPastLessonListBgd(companyId: string, teacherId: string, companyName: string, lessonId: string, learnerName: string, learnerId: string, startPeriod: string, endPeriod: string, limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetPastLessonListBgd(companyId, teacherId, companyName, lessonId, learnerName, learnerId, startPeriod, endPeriod, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetPaymentCompany(companyId: string, paymentId: number, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetPaymentCompany(companyId, paymentId, searchStatus, searchField, searchStr, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} targetMonth 
         * @param {number} paymentId 
         * @param {string} learnerId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetPaymentCompanyList(companyId: string, targetMonth: string, paymentId: number, learnerId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetPaymentCompanyList(companyId, targetMonth, paymentId, learnerId, searchStatus, searchField, searchStr, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetPaymentLearner(companyId: string, learnerId: string, yearMonth: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetPaymentLearner(companyId, learnerId, yearMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {string} learnerId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetPaymentLearnerList(companyId: string, paymentId: number, learnerId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetPaymentLearnerList(companyId, paymentId, learnerId, searchStatus, searchField, searchStr, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} paymentId 
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetReportStudyRecords(companyId: string, paymentId: string, yearMonth: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetReportStudyRecords(companyId, paymentId, yearMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetStudyRecords(companyId: string, learnerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetStudyRecords(companyId, learnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetSystemLearnerNotice(learnerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetSystemLearnerNotice(learnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetTargetMonthPaymentCompanyList(companyId: string, learnerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetTargetMonthPaymentCompanyList(companyId, learnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetTeacherByTeacherId(teacherId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetTeacherByTeacherId(teacherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} yearMonth 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetTeacherLessonFee(companyId: string, teacherId: string, yearMonth: string, endPeriod: string, searchField: string, searchStr: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetTeacherLessonFee(companyId, teacherId, yearMonth, endPeriod, searchField, searchStr, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {string} yearMonth 
         * @param {number} status 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetTeacherLessonFeeList(teacherId: string, yearMonth: string, status: number, limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetTeacherLessonFeeList(teacherId, yearMonth, status, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetTeacherUserList(teacherId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetTeacherUserList(teacherId, searchStatus, searchField, searchStr, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} identity 
         * @param {string} id 
         * @param {string} roomName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetTwilioAccessToken(companyId: string, identity: string, id: string, roomName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetTwilioAccessToken(companyId, identity, id, roomName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} roomId 
         * @param {CreateRoomChatInput} createRoomChatInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerPutCompanyRoomChat(companyId: string, roomId: string, createRoomChatInput: CreateRoomChatInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerPutCompanyRoomChat(companyId, roomId, createRoomChatInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} releaseFlg 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerReleaseNotice(id: string, releaseFlg: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerReleaseNotice(id, releaseFlg, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mailSubject 
         * @param {string} mailerId 
         * @param {string} mailerName 
         * @param {string} kinds 
         * @param {string} mailBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerSendInquiryEmail(mailSubject: string, mailerId: string, mailerName: string, kinds: string, mailBody: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerSendInquiryEmail(mailSubject, mailerId, mailerName, kinds, mailBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} sendQueryEmail 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerSendQueryEmail(companyId: string, sendQueryEmail: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerSendQueryEmail(companyId, sendQueryEmail, startPeriod, endPeriod, searchField, searchStr, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {UpdateAgreementInput} updateAgreementInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateAgreement(companyId: string, updateAgreementInput: UpdateAgreementInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateAgreement(companyId, updateAgreementInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} paymentId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateAgreementPlan(companyId: string, paymentId: string, body: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateAgreementPlan(companyId, paymentId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {string} yearMonth 
         * @param {number} billedFlg 
         * @param {number} finalFee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateBillingStatus(teacherId: string, yearMonth: string, billedFlg: number, finalFee: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateBillingStatus(teacherId, yearMonth, billedFlg, finalFee, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {UpdateCompanyInput} updateCompanyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateCompany(companyId: string, updateCompanyInput: UpdateCompanyInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateCompany(companyId, updateCompanyInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} maintenanceId 
         * @param {boolean} immediateFlg 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateDeMaintenance(maintenanceId: number, immediateFlg: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateDeMaintenance(maintenanceId, immediateFlg, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {UpdateLearnerInput} updateLearnerInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateLearnerUser(companyId: string, learnerId: string, updateLearnerInput: UpdateLearnerInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateLearnerUser(companyId, learnerId, updateLearnerInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateLesson(companyId: string, lessonId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateLesson(companyId, lessonId, startPeriod, endPeriod, searchField, searchStr, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} learnerId 
         * @param {UpdateLessonFeedbackInput} updateLessonFeedbackInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateLessonFeedback(companyId: string, lessonId: string, learnerId: string, updateLessonFeedbackInput: UpdateLessonFeedbackInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateLessonFeedback(companyId, lessonId, learnerId, updateLessonFeedbackInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} learnerId 
         * @param {boolean} lessonLearnerStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateLessonLearnerStatus(companyId: string, lessonId: string, learnerId: string, lessonLearnerStatus: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateLessonLearnerStatus(companyId, lessonId, learnerId, lessonLearnerStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} note 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateLessonNote(companyId: string, lessonId: string, note: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateLessonNote(companyId, lessonId, note, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateLessonStatusTeacher(companyId: string, lessonId: string, status: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateLessonStatusTeacher(companyId, lessonId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} maintenanceId 
         * @param {CreateMaintenanceInput} createMaintenanceInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateMaintenance(maintenanceId: number, createMaintenanceInput: CreateMaintenanceInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateMaintenance(maintenanceId, createMaintenanceInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateNoticeInput} createNoticeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateNotice(id: string, createNoticeInput: CreateNoticeInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateNotice(id, createNoticeInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} noticeId 
         * @param {boolean} readStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateSystemLearnerNoticeReadStatus(noticeId: string, readStatus: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateSystemLearnerNoticeReadStatus(noticeId, readStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {ConnectionId} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateTeacherConnectionId(companyId: string, teacherId: string, connectionId: ConnectionId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateTeacherConnectionId(companyId, teacherId, connectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {UpdateTeacherInput} updateTeacherInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateTeacherUser(teacherId: string, updateTeacherInput: UpdateTeacherInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateTeacherUser(teacherId, updateTeacherInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} theme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateThemeOnLesson(companyId: string, lessonId: string, theme: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateThemeOnLesson(companyId, lessonId, theme, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerVerifyCognitoUserEmail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerVerifyCognitoUserEmail(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sandboxControllerCognitoSandbox(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sandboxControllerCognitoSandbox(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sandboxControllerMailSandbox(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sandboxControllerMailSandbox(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherControllerGetMostRecentScheduledLesson(teacherId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMostRecentScheduledLessonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherControllerGetMostRecentScheduledLesson(teacherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherControllerGetNotUpdatedNoteLessons(teacherId: string, limit: number, page: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNotUpdatedNoteLessonsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherControllerGetNotUpdatedNoteLessons(teacherId, limit, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async teacherControllerGetUnaprovedLessons(teacherId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUnApprovedLessonsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.teacherControllerGetUnaprovedLessons(teacherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoggingInput} loggingInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userEventControllerLoggingEvent(loggingInput: LoggingInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userEventControllerLoggingEvent(loggingInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoggingInput} loggingInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userEventControllerLoggingEventByNotAuth(loggingInput: LoggingInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userEventControllerLoggingEventByNotAuth(loggingInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * APIApi - factory interface
 * @export
 */
export const APIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = APIApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateInquiryInput} createInquiryInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquiryControllerSendInquiryEmail(createInquiryInput: CreateInquiryInput, options?: any): AxiosPromise<object> {
            return localVarFp.inquiryControllerSendInquiryEmail(createInquiryInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerCreateActiveLearnerLog(learnerId: string, options?: any): AxiosPromise<CreateActiveLearnerLogResponse> {
            return localVarFp.mobileAppControllerCreateActiveLearnerLog(learnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} learnerId 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {LearnerLessonLogInput} learnerLessonLogInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerCreateLessonEnteringLogLearner(learnerId: string, companyId: string, lessonId: string, learnerLessonLogInput: LearnerLessonLogInput, options?: any): AxiosPromise<CreateLessonEnteringLogLearnerResponse> {
            return localVarFp.mobileAppControllerCreateLessonEnteringLogLearner(learnerId, companyId, lessonId, learnerLessonLogInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerDeactivateLearnerEndpoints(companyId: string, learnerId: string, options?: any): AxiosPromise<DeactivateLearnerEndPointsResponse> {
            return localVarFp.mobileAppControllerDeactivateLearnerEndpoints(companyId, learnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetApprovedLessons(learnerId: string, options?: any): AxiosPromise<GetApprovedLessonsResponse> {
            return localVarFp.mobileAppControllerGetApprovedLessons(learnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetLearnerActiveEndpointCount(companyId: string, learnerId: string, options?: any): AxiosPromise<GetLearnerActiveEndPointCountResponse> {
            return localVarFp.mobileAppControllerGetLearnerActiveEndpointCount(companyId, learnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetLearnerByEmail(companyId: string, email: string, options?: any): AxiosPromise<GetLearnerByEmailResponse> {
            return localVarFp.mobileAppControllerGetLearnerByEmail(companyId, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetLearnerEndpoint(companyId: string, learnerId: string, options?: any): AxiosPromise<GetLearnerDetailsResponse> {
            return localVarFp.mobileAppControllerGetLearnerEndpoint(companyId, learnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetLernerById(companyId: string, learnerId: string, options?: any): AxiosPromise<GetLearnerByIdResponse> {
            return localVarFp.mobileAppControllerGetLernerById(companyId, learnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetLoginedLearnerDetail(options?: any): AxiosPromise<GetLearnerDetailsResponse> {
            return localVarFp.mobileAppControllerGetLoginedLearnerDetail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetMostRecentScheduledLesson(options?: any): AxiosPromise<GetMostRecentScheduledLessonResponse> {
            return localVarFp.mobileAppControllerGetMostRecentScheduledLesson(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetNoticeRead(noticeId: number, options?: any): AxiosPromise<GetNoticeReadResponse> {
            return localVarFp.mobileAppControllerGetNoticeRead(noticeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetNoticeUnreadCount(userName: string, options?: any): AxiosPromise<GetNoticeUnreadCountResponse> {
            return localVarFp.mobileAppControllerGetNoticeUnreadCount(userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetNotices(limit: number, page: number, options?: any): AxiosPromise<GetNoticesResponse> {
            return localVarFp.mobileAppControllerGetNotices(limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetPastLessons(limit: number, page: number, options?: any): AxiosPromise<GetPastLessonsResponse> {
            return localVarFp.mobileAppControllerGetPastLessons(limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetPushMsg(companyId: string, options?: any): AxiosPromise<GetPushMsgResponse> {
            return localVarFp.mobileAppControllerGetPushMsg(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} pushMsgQId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetPushMsgLog(companyId: string, pushMsgQId: number, options?: any): AxiosPromise<GetPushMsgLogResponse> {
            return localVarFp.mobileAppControllerGetPushMsgLog(companyId, pushMsgQId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetPushVapidPublicKey(companyId: string, options?: any): AxiosPromise<GetPushVapidPublicKeyResponse> {
            return localVarFp.mobileAppControllerGetPushVapidPublicKey(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerGetStudyRecords(userName: string, options?: any): AxiosPromise<GetStudyRecordsResponse> {
            return localVarFp.mobileAppControllerGetStudyRecords(userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DrillParam} drillParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerPostStudyRecordDrill(drillParam: DrillParam, options?: any): AxiosPromise<PostStudyRecordStoryResponse> {
            return localVarFp.mobileAppControllerPostStudyRecordDrill(drillParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StudyResult} studyResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerPostStudyRecordStory(studyResult: StudyResult, options?: any): AxiosPromise<PostStudyRecordStoryResponse> {
            return localVarFp.mobileAppControllerPostStudyRecordStory(studyResult, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDrillResult} updateDrillResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerPutDrillRecord(updateDrillResult: UpdateDrillResult, options?: any): AxiosPromise<PutDrillRecordResponse> {
            return localVarFp.mobileAppControllerPutDrillRecord(updateDrillResult, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} noticeId 
         * @param {string} userName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerPutNoticeRead(companyId: string, noticeId: number, userName: string, options?: any): AxiosPromise<PutNoticeReadResponse> {
            return localVarFp.mobileAppControllerPutNoticeRead(companyId, noticeId, userName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateStudyResult} updateStudyResult 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerPutStudyRecord(updateStudyResult: UpdateStudyResult, options?: any): AxiosPromise<PutStudyRecordRecordResponse> {
            return localVarFp.mobileAppControllerPutStudyRecord(updateStudyResult, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {ConnectionId} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerUpdateLearnerConnectionId(companyId: string, learnerId: string, connectionId: ConnectionId, options?: any): AxiosPromise<UpdateLearnerConnectionIdResponse> {
            return localVarFp.mobileAppControllerUpdateLearnerConnectionId(companyId, learnerId, connectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerUpdateLearnerEmail(companyId: string, learnerId: string, email: string, options?: any): AxiosPromise<UpdateLearnerEmailResponse> {
            return localVarFp.mobileAppControllerUpdateLearnerEmail(companyId, learnerId, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {string} emailSendFlg 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerUpdateLearnerEmailSendFlg(companyId: string, learnerId: string, emailSendFlg: string, options?: any): AxiosPromise<UpdateLearnerEmailSendFlgResponse> {
            return localVarFp.mobileAppControllerUpdateLearnerEmailSendFlg(companyId, learnerId, emailSendFlg, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {LearnerEndpoint} learnerEndpoint 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerUpsertLearnerEndpoint(companyId: string, learnerId: string, learnerEndpoint: LearnerEndpoint, options?: any): AxiosPromise<UpsertLearnerEndPointResponse> {
            return localVarFp.mobileAppControllerUpsertLearnerEndpoint(companyId, learnerId, learnerEndpoint, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {PushMsg} pushMsg 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerUpsertPushMsg(companyId: string, pushMsg: PushMsg, options?: any): AxiosPromise<UpsertPushMsgResponse> {
            return localVarFp.mobileAppControllerUpsertPushMsg(companyId, pushMsg, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {PushMsgLog} pushMsgLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerUpsertPushMsgLog(companyId: string, pushMsgLog: PushMsgLog, options?: any): AxiosPromise<UpsertPushMsgLogResponse> {
            return localVarFp.mobileAppControllerUpsertPushMsgLog(companyId, pushMsgLog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerVerifyCognitoUserEmail(options?: any): AxiosPromise<VerifyCognitoUserEmailResponse> {
            return localVarFp.mobileAppControllerVerifyCognitoUserEmail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {string} email 
         * @param {VerifyInfo} verifyInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileAppControllerVerifyLearnerEmail(companyId: string, learnerId: string, email: string, verifyInfo: VerifyInfo, options?: any): AxiosPromise<VerifyLearnerEmailResponse> {
            return localVarFp.mobileAppControllerVerifyLearnerEmail(companyId, learnerId, email, verifyInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerApprovalLessonTeacher(companyId: string, lessonId: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerApprovalLessonTeacher(companyId, lessonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {CancelLessonInput} cancelLessonInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCancelLesson(companyId: string, lessonId: string, cancelLessonInput: CancelLessonInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerCancelLesson(companyId, lessonId, cancelLessonInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerChangeLessonTeacher(companyId: string, lessonId: string, teacherId: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerChangeLessonTeacher(companyId, lessonId, teacherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} roomSid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerComposeTwilioVideoRecording(companyId: string, roomSid: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerComposeTwilioVideoRecording(companyId, roomSid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {CreateAgreementInput} createAgreementInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateAgreement(companyId: string, createAgreementInput: CreateAgreementInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerCreateAgreement(companyId, createAgreementInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {CreateCompanyInput} createCompanyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateCompany(companyId: string, createCompanyInput: CreateCompanyInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerCreateCompany(companyId, createCompanyInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {CreateCompanyUserInput} createCompanyUserInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateCompanyUser(companyId: string, createCompanyUserInput: CreateCompanyUserInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerCreateCompanyUser(companyId, createCompanyUserInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateErrorLog} createErrorLog 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateErrorLog(createErrorLog: CreateErrorLog, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerCreateErrorLog(createErrorLog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {CreateLearnerInput} createLearnerInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateLearnerUser(companyId: string, paymentId: number, createLearnerInput: CreateLearnerInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerCreateLearnerUser(companyId, paymentId, createLearnerInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} startDatetime 
         * @param {string} endDatetime 
         * @param {CreateLessonInput} createLessonInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateLesson(companyId: string, teacherId: string, startDatetime: string, endDatetime: string, createLessonInput: CreateLessonInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerCreateLesson(companyId, teacherId, startDatetime, endDatetime, createLessonInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {CreateLessonFeeInput} createLessonFeeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateLessonFee(teacherId: string, createLessonFeeInput: CreateLessonFeeInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerCreateLessonFee(teacherId, createLessonFeeInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {CreateLessonFeeHistoryInput} createLessonFeeHistoryInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateLessonFeeHistory(teacherId: string, createLessonFeeHistoryInput: CreateLessonFeeHistoryInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerCreateLessonFeeHistory(teacherId, createLessonFeeHistoryInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateMaintenanceInput} createMaintenanceInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateMaintenance(createMaintenanceInput: CreateMaintenanceInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerCreateMaintenance(createMaintenanceInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dummy 
         * @param {CreateManagerInput} createManagerInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateManagerUser(dummy: string, createManagerInput: CreateManagerInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerCreateManagerUser(dummy, createManagerInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dummy 
         * @param {CreateNoticeInput} createNoticeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateNotice(dummy: string, createNoticeInput: CreateNoticeInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerCreateNotice(dummy, createNoticeInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateNoticeCompanyInput} createNoticeCompanyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateNoticeCompany(createNoticeCompanyInput: CreateNoticeCompanyInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerCreateNoticeCompany(createNoticeCompanyInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {CreateTeacherInput} createTeacherInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateTeacherUser(teacherId: string, createTeacherInput: CreateTeacherInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerCreateTeacherUser(teacherId, createTeacherInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteAgreement(companyId: string, paymentId: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerDeleteAgreement(companyId, paymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteCompany(companyId: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerDeleteCompany(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteCompanyUser(companyId: string, userId: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerDeleteCompanyUser(companyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteLearnerUser(companyId: string, learnerId: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerDeleteLearnerUser(companyId, learnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteLessonFeeOption(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerDeleteLessonFeeOption(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} maintenanceId 
         * @param {string} maintenanceStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteMaintenance(maintenanceId: number, maintenanceStatus: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerDeleteMaintenance(maintenanceId, maintenanceStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} managerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteManagerUser(managerId: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerDeleteManagerUser(managerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteMonthlyAgreement(companyId: string, body: object, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerDeleteMonthlyAgreement(companyId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteNotice(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerDeleteNotice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteTeacherUser(teacherId: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerDeleteTeacherUser(teacherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExistsLearnerInput} existsLearnerInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerExistsLearner(existsLearnerInput: ExistsLearnerInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerExistsLearner(existsLearnerInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExistsLearnerInPlanInput} existsLearnerInPlanInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerExistsLearnerInPlan(existsLearnerInPlanInput: ExistsLearnerInPlanInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerExistsLearnerInPlan(existsLearnerInPlanInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetAvailableCompanies(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetAvailableCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetCompany(companyId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetCompany(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {string} sortStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetCompanyList(companyId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, sortStr: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetCompanyList(companyId, searchStatus, searchField, searchStr, limit, page, sortStr, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetCompanyPlans(companyId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetCompanyPlans(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetCompanyUserList(companyId: string, limit: number, page: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetCompanyUserList(companyId, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetConnectionIdByRoomId(companyId: string, roomId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetConnectionIdByRoomId(companyId, roomId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetEachLearnerStudyRecordsPdf(companyId: string, paymentId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetEachLearnerStudyRecordsPdf(companyId, paymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {string} teacherId 
         * @param {string} learnerId 
         * @param {string} lessonId 
         * @param {number} approvalFlg 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} futureOnly 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetFutureLessonList(companyId: string, paymentId: number, teacherId: string, learnerId: string, lessonId: string, approvalFlg: number, startPeriod: string, endPeriod: string, futureOnly: number, limit: number, page: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetFutureLessonList(companyId, paymentId, teacherId, learnerId, lessonId, approvalFlg, startPeriod, endPeriod, futureOnly, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} learner 
         * @param {string} learnerId 
         * @param {string} company 
         * @param {string} searchStatus 
         * @param {string} lessonId 
         * @param {number} approvalFlg 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetFutureLessonListBgd(companyId: string, teacherId: string, learner: string, learnerId: string, company: string, searchStatus: string, lessonId: string, approvalFlg: number, startPeriod: string, endPeriod: string, limit: number, page: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetFutureLessonListBgd(companyId, teacherId, learner, learnerId, company, searchStatus, lessonId, approvalFlg, startPeriod, endPeriod, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLatestPastLessonNote(companyId: string, learnerId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetLatestPastLessonNote(companyId, learnerId, startPeriod, endPeriod, searchField, searchStr, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLeanerUserListByLesson(companyId: string, lessonId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetLeanerUserListByLesson(companyId, lessonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLearnerStudyRecords(companyId: string, learnerId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetLearnerStudyRecords(companyId, learnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLearnerUserList(companyId: string, learnerId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetLearnerUserList(companyId, learnerId, searchStatus, searchField, searchStr, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} lessonId 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLesson(companyId: string, teacherId: string, lessonId: string, searchField: string, searchStr: string, startPeriod: string, endPeriod: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetLesson(companyId, teacherId, lessonId, searchField, searchStr, startPeriod, endPeriod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonByCompanyNLessonID(companyId: string, lessonId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetLessonByCompanyNLessonID(companyId, lessonId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} roomId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonByRoomId(companyId: string, roomId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetLessonByRoomId(companyId, roomId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} learnerId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonFeedback(companyId: string, lessonId: string, learnerId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetLessonFeedback(companyId, lessonId, learnerId, startPeriod, endPeriod, searchField, searchStr, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {string} learnerId 
         * @param {number} lessonId 
         * @param {string} teacherId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} noneLessonFeedback 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonFeedbackList(companyId: string, paymentId: number, learnerId: string, lessonId: number, teacherId: string, startPeriod: string, endPeriod: string, noneLessonFeedback: number, limit: number, page: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetLessonFeedbackList(companyId, paymentId, learnerId, lessonId, teacherId, startPeriod, endPeriod, noneLessonFeedback, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {string} learnerId 
         * @param {string} teacherId 
         * @param {string} companyName 
         * @param {string} learnerName 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonFeedbackListBgd(companyId: string, paymentId: number, learnerId: string, teacherId: string, companyName: string, learnerName: string, startPeriod: string, endPeriod: string, limit: number, page: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetLessonFeedbackListBgd(companyId, paymentId, learnerId, teacherId, companyName, learnerName, startPeriod, endPeriod, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonFees(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetLessonFees(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} learner 
         * @param {string} learnerId 
         * @param {string} company 
         * @param {string} searchStatus 
         * @param {string} lessonId 
         * @param {number} approvalFlg 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonListBgd(companyId: string, teacherId: string, learner: string, learnerId: string, company: string, searchStatus: string, lessonId: string, approvalFlg: number, startPeriod: string, endPeriod: string, limit: number, page: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetLessonListBgd(companyId, teacherId, learner, learnerId, company, searchStatus, lessonId, approvalFlg, startPeriod, endPeriod, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonNote(companyId: string, lessonId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetLessonNote(companyId, lessonId, startPeriod, endPeriod, searchField, searchStr, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetLessonNoteList(companyId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetLessonNoteList(companyId, startPeriod, endPeriod, searchField, searchStr, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetMaintenance(page: number, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerGetMaintenance(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetManagerUserList(limit: number, page: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetManagerUserList(limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetNotice(noticeId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetNotice(noticeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} noticeId 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetNoticeCompany(noticeId: string, companyId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetNoticeCompany(noticeId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetNoticeCompanyForManager(noticeId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetNoticeCompanyForManager(noticeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} targetNumber 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetNoticeList(targetNumber: number, limit: number, page: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetNoticeList(targetNumber, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetOperationalStatus(options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerGetOperationalStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {string} teacherId 
         * @param {string} learnerId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} noneLessonNote 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetPastLessonList(companyId: string, paymentId: number, teacherId: string, learnerId: string, startPeriod: string, endPeriod: string, noneLessonNote: number, limit: number, page: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetPastLessonList(companyId, paymentId, teacherId, learnerId, startPeriod, endPeriod, noneLessonNote, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} companyName 
         * @param {string} lessonId 
         * @param {string} learnerName 
         * @param {string} learnerId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetPastLessonListBgd(companyId: string, teacherId: string, companyName: string, lessonId: string, learnerName: string, learnerId: string, startPeriod: string, endPeriod: string, limit: number, page: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetPastLessonListBgd(companyId, teacherId, companyName, lessonId, learnerName, learnerId, startPeriod, endPeriod, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetPaymentCompany(companyId: string, paymentId: number, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetPaymentCompany(companyId, paymentId, searchStatus, searchField, searchStr, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} targetMonth 
         * @param {number} paymentId 
         * @param {string} learnerId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetPaymentCompanyList(companyId: string, targetMonth: string, paymentId: number, learnerId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetPaymentCompanyList(companyId, targetMonth, paymentId, learnerId, searchStatus, searchField, searchStr, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetPaymentLearner(companyId: string, learnerId: string, yearMonth: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetPaymentLearner(companyId, learnerId, yearMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {number} paymentId 
         * @param {string} learnerId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetPaymentLearnerList(companyId: string, paymentId: number, learnerId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetPaymentLearnerList(companyId, paymentId, learnerId, searchStatus, searchField, searchStr, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} paymentId 
         * @param {string} yearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetReportStudyRecords(companyId: string, paymentId: string, yearMonth: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetReportStudyRecords(companyId, paymentId, yearMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetStudyRecords(companyId: string, learnerId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetStudyRecords(companyId, learnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetSystemLearnerNotice(learnerId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetSystemLearnerNotice(learnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetTargetMonthPaymentCompanyList(companyId: string, learnerId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetTargetMonthPaymentCompanyList(companyId, learnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetTeacherByTeacherId(teacherId: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetTeacherByTeacherId(teacherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {string} yearMonth 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetTeacherLessonFee(companyId: string, teacherId: string, yearMonth: string, endPeriod: string, searchField: string, searchStr: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetTeacherLessonFee(companyId, teacherId, yearMonth, endPeriod, searchField, searchStr, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {string} yearMonth 
         * @param {number} status 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetTeacherLessonFeeList(teacherId: string, yearMonth: string, status: number, limit: number, page: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetTeacherLessonFeeList(teacherId, yearMonth, status, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {number} searchStatus 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetTeacherUserList(teacherId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetTeacherUserList(teacherId, searchStatus, searchField, searchStr, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} identity 
         * @param {string} id 
         * @param {string} roomName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetTwilioAccessToken(companyId: string, identity: string, id: string, roomName: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.organizationsControllerGetTwilioAccessToken(companyId, identity, id, roomName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} roomId 
         * @param {CreateRoomChatInput} createRoomChatInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerPutCompanyRoomChat(companyId: string, roomId: string, createRoomChatInput: CreateRoomChatInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerPutCompanyRoomChat(companyId, roomId, createRoomChatInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} releaseFlg 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerReleaseNotice(id: string, releaseFlg: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerReleaseNotice(id, releaseFlg, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mailSubject 
         * @param {string} mailerId 
         * @param {string} mailerName 
         * @param {string} kinds 
         * @param {string} mailBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerSendInquiryEmail(mailSubject: string, mailerId: string, mailerName: string, kinds: string, mailBody: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerSendInquiryEmail(mailSubject, mailerId, mailerName, kinds, mailBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} sendQueryEmail 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerSendQueryEmail(companyId: string, sendQueryEmail: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerSendQueryEmail(companyId, sendQueryEmail, startPeriod, endPeriod, searchField, searchStr, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {UpdateAgreementInput} updateAgreementInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateAgreement(companyId: string, updateAgreementInput: UpdateAgreementInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerUpdateAgreement(companyId, updateAgreementInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} paymentId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateAgreementPlan(companyId: string, paymentId: string, body: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerUpdateAgreementPlan(companyId, paymentId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {string} yearMonth 
         * @param {number} billedFlg 
         * @param {number} finalFee 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateBillingStatus(teacherId: string, yearMonth: string, billedFlg: number, finalFee: number, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerUpdateBillingStatus(teacherId, yearMonth, billedFlg, finalFee, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {UpdateCompanyInput} updateCompanyInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateCompany(companyId: string, updateCompanyInput: UpdateCompanyInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerUpdateCompany(companyId, updateCompanyInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} maintenanceId 
         * @param {boolean} immediateFlg 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateDeMaintenance(maintenanceId: number, immediateFlg: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerUpdateDeMaintenance(maintenanceId, immediateFlg, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} learnerId 
         * @param {UpdateLearnerInput} updateLearnerInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateLearnerUser(companyId: string, learnerId: string, updateLearnerInput: UpdateLearnerInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerUpdateLearnerUser(companyId, learnerId, updateLearnerInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} startPeriod 
         * @param {string} endPeriod 
         * @param {string} searchField 
         * @param {string} searchStr 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateLesson(companyId: string, lessonId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerUpdateLesson(companyId, lessonId, startPeriod, endPeriod, searchField, searchStr, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} learnerId 
         * @param {UpdateLessonFeedbackInput} updateLessonFeedbackInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateLessonFeedback(companyId: string, lessonId: string, learnerId: string, updateLessonFeedbackInput: UpdateLessonFeedbackInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerUpdateLessonFeedback(companyId, lessonId, learnerId, updateLessonFeedbackInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} learnerId 
         * @param {boolean} lessonLearnerStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateLessonLearnerStatus(companyId: string, lessonId: string, learnerId: string, lessonLearnerStatus: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerUpdateLessonLearnerStatus(companyId, lessonId, learnerId, lessonLearnerStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} note 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateLessonNote(companyId: string, lessonId: string, note: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerUpdateLessonNote(companyId, lessonId, note, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateLessonStatusTeacher(companyId: string, lessonId: string, status: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerUpdateLessonStatusTeacher(companyId, lessonId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} maintenanceId 
         * @param {CreateMaintenanceInput} createMaintenanceInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateMaintenance(maintenanceId: number, createMaintenanceInput: CreateMaintenanceInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerUpdateMaintenance(maintenanceId, createMaintenanceInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateNoticeInput} createNoticeInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateNotice(id: string, createNoticeInput: CreateNoticeInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerUpdateNotice(id, createNoticeInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} noticeId 
         * @param {boolean} readStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateSystemLearnerNoticeReadStatus(noticeId: string, readStatus: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerUpdateSystemLearnerNoticeReadStatus(noticeId, readStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} teacherId 
         * @param {ConnectionId} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateTeacherConnectionId(companyId: string, teacherId: string, connectionId: ConnectionId, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerUpdateTeacherConnectionId(companyId, teacherId, connectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {UpdateTeacherInput} updateTeacherInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateTeacherUser(teacherId: string, updateTeacherInput: UpdateTeacherInput, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerUpdateTeacherUser(teacherId, updateTeacherInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} lessonId 
         * @param {string} theme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateThemeOnLesson(companyId: string, lessonId: string, theme: string, options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerUpdateThemeOnLesson(companyId, lessonId, theme, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerVerifyCognitoUserEmail(options?: any): AxiosPromise<object> {
            return localVarFp.organizationsControllerVerifyCognitoUserEmail(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sandboxControllerCognitoSandbox(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.sandboxControllerCognitoSandbox(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sandboxControllerMailSandbox(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.sandboxControllerMailSandbox(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherControllerGetMostRecentScheduledLesson(teacherId: string, options?: any): AxiosPromise<GetMostRecentScheduledLessonResponse> {
            return localVarFp.teacherControllerGetMostRecentScheduledLesson(teacherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {number} limit 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherControllerGetNotUpdatedNoteLessons(teacherId: string, limit: number, page: number, options?: any): AxiosPromise<GetNotUpdatedNoteLessonsResponse> {
            return localVarFp.teacherControllerGetNotUpdatedNoteLessons(teacherId, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        teacherControllerGetUnaprovedLessons(teacherId: string, options?: any): AxiosPromise<GetUnApprovedLessonsResponse> {
            return localVarFp.teacherControllerGetUnaprovedLessons(teacherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoggingInput} loggingInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userEventControllerLoggingEvent(loggingInput: LoggingInput, options?: any): AxiosPromise<object> {
            return localVarFp.userEventControllerLoggingEvent(loggingInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoggingInput} loggingInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userEventControllerLoggingEventByNotAuth(loggingInput: LoggingInput, options?: any): AxiosPromise<object> {
            return localVarFp.userEventControllerLoggingEventByNotAuth(loggingInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * APIApi - object-oriented interface
 * @export
 * @class APIApi
 * @extends {BaseAPI}
 */
export class APIApi extends BaseAPI {
    /**
     * 
     * @param {CreateInquiryInput} createInquiryInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public inquiryControllerSendInquiryEmail(createInquiryInput: CreateInquiryInput, options?: any) {
        return APIApiFp(this.configuration).inquiryControllerSendInquiryEmail(createInquiryInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} learnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerCreateActiveLearnerLog(learnerId: string, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerCreateActiveLearnerLog(learnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} learnerId 
     * @param {string} companyId 
     * @param {string} lessonId 
     * @param {LearnerLessonLogInput} learnerLessonLogInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerCreateLessonEnteringLogLearner(learnerId: string, companyId: string, lessonId: string, learnerLessonLogInput: LearnerLessonLogInput, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerCreateLessonEnteringLogLearner(learnerId, companyId, lessonId, learnerLessonLogInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} learnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerDeactivateLearnerEndpoints(companyId: string, learnerId: string, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerDeactivateLearnerEndpoints(companyId, learnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} learnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerGetApprovedLessons(learnerId: string, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerGetApprovedLessons(learnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} learnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerGetLearnerActiveEndpointCount(companyId: string, learnerId: string, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerGetLearnerActiveEndpointCount(companyId, learnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerGetLearnerByEmail(companyId: string, email: string, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerGetLearnerByEmail(companyId, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} learnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerGetLearnerEndpoint(companyId: string, learnerId: string, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerGetLearnerEndpoint(companyId, learnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} learnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerGetLernerById(companyId: string, learnerId: string, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerGetLernerById(companyId, learnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerGetLoginedLearnerDetail(options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerGetLoginedLearnerDetail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerGetMostRecentScheduledLesson(options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerGetMostRecentScheduledLesson(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} noticeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerGetNoticeRead(noticeId: number, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerGetNoticeRead(noticeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerGetNoticeUnreadCount(userName: string, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerGetNoticeUnreadCount(userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerGetNotices(limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerGetNotices(limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerGetPastLessons(limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerGetPastLessons(limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerGetPushMsg(companyId: string, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerGetPushMsg(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {number} pushMsgQId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerGetPushMsgLog(companyId: string, pushMsgQId: number, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerGetPushMsgLog(companyId, pushMsgQId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerGetPushVapidPublicKey(companyId: string, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerGetPushVapidPublicKey(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerGetStudyRecords(userName: string, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerGetStudyRecords(userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DrillParam} drillParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerPostStudyRecordDrill(drillParam: DrillParam, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerPostStudyRecordDrill(drillParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StudyResult} studyResult 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerPostStudyRecordStory(studyResult: StudyResult, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerPostStudyRecordStory(studyResult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDrillResult} updateDrillResult 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerPutDrillRecord(updateDrillResult: UpdateDrillResult, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerPutDrillRecord(updateDrillResult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {number} noticeId 
     * @param {string} userName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerPutNoticeRead(companyId: string, noticeId: number, userName: string, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerPutNoticeRead(companyId, noticeId, userName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateStudyResult} updateStudyResult 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerPutStudyRecord(updateStudyResult: UpdateStudyResult, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerPutStudyRecord(updateStudyResult, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} learnerId 
     * @param {ConnectionId} connectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerUpdateLearnerConnectionId(companyId: string, learnerId: string, connectionId: ConnectionId, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerUpdateLearnerConnectionId(companyId, learnerId, connectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} learnerId 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerUpdateLearnerEmail(companyId: string, learnerId: string, email: string, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerUpdateLearnerEmail(companyId, learnerId, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} learnerId 
     * @param {string} emailSendFlg 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerUpdateLearnerEmailSendFlg(companyId: string, learnerId: string, emailSendFlg: string, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerUpdateLearnerEmailSendFlg(companyId, learnerId, emailSendFlg, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} learnerId 
     * @param {LearnerEndpoint} learnerEndpoint 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerUpsertLearnerEndpoint(companyId: string, learnerId: string, learnerEndpoint: LearnerEndpoint, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerUpsertLearnerEndpoint(companyId, learnerId, learnerEndpoint, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {PushMsg} pushMsg 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerUpsertPushMsg(companyId: string, pushMsg: PushMsg, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerUpsertPushMsg(companyId, pushMsg, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {PushMsgLog} pushMsgLog 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerUpsertPushMsgLog(companyId: string, pushMsgLog: PushMsgLog, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerUpsertPushMsgLog(companyId, pushMsgLog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerVerifyCognitoUserEmail(options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerVerifyCognitoUserEmail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} learnerId 
     * @param {string} email 
     * @param {VerifyInfo} verifyInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public mobileAppControllerVerifyLearnerEmail(companyId: string, learnerId: string, email: string, verifyInfo: VerifyInfo, options?: any) {
        return APIApiFp(this.configuration).mobileAppControllerVerifyLearnerEmail(companyId, learnerId, email, verifyInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} lessonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerApprovalLessonTeacher(companyId: string, lessonId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerApprovalLessonTeacher(companyId, lessonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} lessonId 
     * @param {CancelLessonInput} cancelLessonInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerCancelLesson(companyId: string, lessonId: string, cancelLessonInput: CancelLessonInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerCancelLesson(companyId, lessonId, cancelLessonInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} lessonId 
     * @param {string} teacherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerChangeLessonTeacher(companyId: string, lessonId: string, teacherId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerChangeLessonTeacher(companyId, lessonId, teacherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} roomSid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerComposeTwilioVideoRecording(companyId: string, roomSid: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerComposeTwilioVideoRecording(companyId, roomSid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {CreateAgreementInput} createAgreementInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerCreateAgreement(companyId: string, createAgreementInput: CreateAgreementInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerCreateAgreement(companyId, createAgreementInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {CreateCompanyInput} createCompanyInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerCreateCompany(companyId: string, createCompanyInput: CreateCompanyInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerCreateCompany(companyId, createCompanyInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {CreateCompanyUserInput} createCompanyUserInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerCreateCompanyUser(companyId: string, createCompanyUserInput: CreateCompanyUserInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerCreateCompanyUser(companyId, createCompanyUserInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateErrorLog} createErrorLog 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerCreateErrorLog(createErrorLog: CreateErrorLog, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerCreateErrorLog(createErrorLog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {number} paymentId 
     * @param {CreateLearnerInput} createLearnerInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerCreateLearnerUser(companyId: string, paymentId: number, createLearnerInput: CreateLearnerInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerCreateLearnerUser(companyId, paymentId, createLearnerInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} teacherId 
     * @param {string} startDatetime 
     * @param {string} endDatetime 
     * @param {CreateLessonInput} createLessonInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerCreateLesson(companyId: string, teacherId: string, startDatetime: string, endDatetime: string, createLessonInput: CreateLessonInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerCreateLesson(companyId, teacherId, startDatetime, endDatetime, createLessonInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherId 
     * @param {CreateLessonFeeInput} createLessonFeeInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerCreateLessonFee(teacherId: string, createLessonFeeInput: CreateLessonFeeInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerCreateLessonFee(teacherId, createLessonFeeInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherId 
     * @param {CreateLessonFeeHistoryInput} createLessonFeeHistoryInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerCreateLessonFeeHistory(teacherId: string, createLessonFeeHistoryInput: CreateLessonFeeHistoryInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerCreateLessonFeeHistory(teacherId, createLessonFeeHistoryInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateMaintenanceInput} createMaintenanceInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerCreateMaintenance(createMaintenanceInput: CreateMaintenanceInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerCreateMaintenance(createMaintenanceInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dummy 
     * @param {CreateManagerInput} createManagerInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerCreateManagerUser(dummy: string, createManagerInput: CreateManagerInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerCreateManagerUser(dummy, createManagerInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dummy 
     * @param {CreateNoticeInput} createNoticeInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerCreateNotice(dummy: string, createNoticeInput: CreateNoticeInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerCreateNotice(dummy, createNoticeInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateNoticeCompanyInput} createNoticeCompanyInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerCreateNoticeCompany(createNoticeCompanyInput: CreateNoticeCompanyInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerCreateNoticeCompany(createNoticeCompanyInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherId 
     * @param {CreateTeacherInput} createTeacherInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerCreateTeacherUser(teacherId: string, createTeacherInput: CreateTeacherInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerCreateTeacherUser(teacherId, createTeacherInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} paymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerDeleteAgreement(companyId: string, paymentId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerDeleteAgreement(companyId, paymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerDeleteCompany(companyId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerDeleteCompany(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerDeleteCompanyUser(companyId: string, userId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerDeleteCompanyUser(companyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} learnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerDeleteLearnerUser(companyId: string, learnerId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerDeleteLearnerUser(companyId, learnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerDeleteLessonFeeOption(id: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerDeleteLessonFeeOption(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} maintenanceId 
     * @param {string} maintenanceStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerDeleteMaintenance(maintenanceId: number, maintenanceStatus: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerDeleteMaintenance(maintenanceId, maintenanceStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} managerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerDeleteManagerUser(managerId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerDeleteManagerUser(managerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerDeleteMonthlyAgreement(companyId: string, body: object, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerDeleteMonthlyAgreement(companyId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerDeleteNotice(id: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerDeleteNotice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerDeleteTeacherUser(teacherId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerDeleteTeacherUser(teacherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExistsLearnerInput} existsLearnerInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerExistsLearner(existsLearnerInput: ExistsLearnerInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerExistsLearner(existsLearnerInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExistsLearnerInPlanInput} existsLearnerInPlanInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerExistsLearnerInPlan(existsLearnerInPlanInput: ExistsLearnerInPlanInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerExistsLearnerInPlan(existsLearnerInPlanInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetAvailableCompanies(options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetAvailableCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetCompany(companyId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetCompany(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {number} searchStatus 
     * @param {string} searchField 
     * @param {string} searchStr 
     * @param {number} limit 
     * @param {number} page 
     * @param {string} sortStr 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetCompanyList(companyId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, sortStr: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetCompanyList(companyId, searchStatus, searchField, searchStr, limit, page, sortStr, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetCompanyPlans(companyId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetCompanyPlans(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetCompanyUserList(companyId: string, limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetCompanyUserList(companyId, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} roomId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetConnectionIdByRoomId(companyId: string, roomId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetConnectionIdByRoomId(companyId, roomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} paymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetEachLearnerStudyRecordsPdf(companyId: string, paymentId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetEachLearnerStudyRecordsPdf(companyId, paymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {number} paymentId 
     * @param {string} teacherId 
     * @param {string} learnerId 
     * @param {string} lessonId 
     * @param {number} approvalFlg 
     * @param {string} startPeriod 
     * @param {string} endPeriod 
     * @param {number} futureOnly 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetFutureLessonList(companyId: string, paymentId: number, teacherId: string, learnerId: string, lessonId: string, approvalFlg: number, startPeriod: string, endPeriod: string, futureOnly: number, limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetFutureLessonList(companyId, paymentId, teacherId, learnerId, lessonId, approvalFlg, startPeriod, endPeriod, futureOnly, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} teacherId 
     * @param {string} learner 
     * @param {string} learnerId 
     * @param {string} company 
     * @param {string} searchStatus 
     * @param {string} lessonId 
     * @param {number} approvalFlg 
     * @param {string} startPeriod 
     * @param {string} endPeriod 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetFutureLessonListBgd(companyId: string, teacherId: string, learner: string, learnerId: string, company: string, searchStatus: string, lessonId: string, approvalFlg: number, startPeriod: string, endPeriod: string, limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetFutureLessonListBgd(companyId, teacherId, learner, learnerId, company, searchStatus, lessonId, approvalFlg, startPeriod, endPeriod, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} learnerId 
     * @param {string} startPeriod 
     * @param {string} endPeriod 
     * @param {string} searchField 
     * @param {string} searchStr 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetLatestPastLessonNote(companyId: string, learnerId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetLatestPastLessonNote(companyId, learnerId, startPeriod, endPeriod, searchField, searchStr, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} lessonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetLeanerUserListByLesson(companyId: string, lessonId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetLeanerUserListByLesson(companyId, lessonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} learnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetLearnerStudyRecords(companyId: string, learnerId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetLearnerStudyRecords(companyId, learnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} learnerId 
     * @param {number} searchStatus 
     * @param {string} searchField 
     * @param {string} searchStr 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetLearnerUserList(companyId: string, learnerId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetLearnerUserList(companyId, learnerId, searchStatus, searchField, searchStr, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} teacherId 
     * @param {string} lessonId 
     * @param {string} searchField 
     * @param {string} searchStr 
     * @param {string} startPeriod 
     * @param {string} endPeriod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetLesson(companyId: string, teacherId: string, lessonId: string, searchField: string, searchStr: string, startPeriod: string, endPeriod: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetLesson(companyId, teacherId, lessonId, searchField, searchStr, startPeriod, endPeriod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} lessonId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetLessonByCompanyNLessonID(companyId: string, lessonId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetLessonByCompanyNLessonID(companyId, lessonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} roomId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetLessonByRoomId(companyId: string, roomId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetLessonByRoomId(companyId, roomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} lessonId 
     * @param {string} learnerId 
     * @param {string} startPeriod 
     * @param {string} endPeriod 
     * @param {string} searchField 
     * @param {string} searchStr 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetLessonFeedback(companyId: string, lessonId: string, learnerId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetLessonFeedback(companyId, lessonId, learnerId, startPeriod, endPeriod, searchField, searchStr, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {number} paymentId 
     * @param {string} learnerId 
     * @param {number} lessonId 
     * @param {string} teacherId 
     * @param {string} startPeriod 
     * @param {string} endPeriod 
     * @param {number} noneLessonFeedback 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetLessonFeedbackList(companyId: string, paymentId: number, learnerId: string, lessonId: number, teacherId: string, startPeriod: string, endPeriod: string, noneLessonFeedback: number, limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetLessonFeedbackList(companyId, paymentId, learnerId, lessonId, teacherId, startPeriod, endPeriod, noneLessonFeedback, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {number} paymentId 
     * @param {string} learnerId 
     * @param {string} teacherId 
     * @param {string} companyName 
     * @param {string} learnerName 
     * @param {string} startPeriod 
     * @param {string} endPeriod 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetLessonFeedbackListBgd(companyId: string, paymentId: number, learnerId: string, teacherId: string, companyName: string, learnerName: string, startPeriod: string, endPeriod: string, limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetLessonFeedbackListBgd(companyId, paymentId, learnerId, teacherId, companyName, learnerName, startPeriod, endPeriod, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetLessonFees(options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetLessonFees(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} teacherId 
     * @param {string} learner 
     * @param {string} learnerId 
     * @param {string} company 
     * @param {string} searchStatus 
     * @param {string} lessonId 
     * @param {number} approvalFlg 
     * @param {string} startPeriod 
     * @param {string} endPeriod 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetLessonListBgd(companyId: string, teacherId: string, learner: string, learnerId: string, company: string, searchStatus: string, lessonId: string, approvalFlg: number, startPeriod: string, endPeriod: string, limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetLessonListBgd(companyId, teacherId, learner, learnerId, company, searchStatus, lessonId, approvalFlg, startPeriod, endPeriod, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} lessonId 
     * @param {string} startPeriod 
     * @param {string} endPeriod 
     * @param {string} searchField 
     * @param {string} searchStr 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetLessonNote(companyId: string, lessonId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetLessonNote(companyId, lessonId, startPeriod, endPeriod, searchField, searchStr, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} startPeriod 
     * @param {string} endPeriod 
     * @param {string} searchField 
     * @param {string} searchStr 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetLessonNoteList(companyId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetLessonNoteList(companyId, startPeriod, endPeriod, searchField, searchStr, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetMaintenance(page: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetMaintenance(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetManagerUserList(limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetManagerUserList(limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} noticeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetNotice(noticeId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetNotice(noticeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} noticeId 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetNoticeCompany(noticeId: string, companyId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetNoticeCompany(noticeId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} noticeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetNoticeCompanyForManager(noticeId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetNoticeCompanyForManager(noticeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} targetNumber 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetNoticeList(targetNumber: number, limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetNoticeList(targetNumber, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetOperationalStatus(options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetOperationalStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {number} paymentId 
     * @param {string} teacherId 
     * @param {string} learnerId 
     * @param {string} startPeriod 
     * @param {string} endPeriod 
     * @param {number} noneLessonNote 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetPastLessonList(companyId: string, paymentId: number, teacherId: string, learnerId: string, startPeriod: string, endPeriod: string, noneLessonNote: number, limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetPastLessonList(companyId, paymentId, teacherId, learnerId, startPeriod, endPeriod, noneLessonNote, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} teacherId 
     * @param {string} companyName 
     * @param {string} lessonId 
     * @param {string} learnerName 
     * @param {string} learnerId 
     * @param {string} startPeriod 
     * @param {string} endPeriod 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetPastLessonListBgd(companyId: string, teacherId: string, companyName: string, lessonId: string, learnerName: string, learnerId: string, startPeriod: string, endPeriod: string, limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetPastLessonListBgd(companyId, teacherId, companyName, lessonId, learnerName, learnerId, startPeriod, endPeriod, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {number} paymentId 
     * @param {number} searchStatus 
     * @param {string} searchField 
     * @param {string} searchStr 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetPaymentCompany(companyId: string, paymentId: number, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetPaymentCompany(companyId, paymentId, searchStatus, searchField, searchStr, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} targetMonth 
     * @param {number} paymentId 
     * @param {string} learnerId 
     * @param {number} searchStatus 
     * @param {string} searchField 
     * @param {string} searchStr 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetPaymentCompanyList(companyId: string, targetMonth: string, paymentId: number, learnerId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetPaymentCompanyList(companyId, targetMonth, paymentId, learnerId, searchStatus, searchField, searchStr, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} learnerId 
     * @param {string} yearMonth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetPaymentLearner(companyId: string, learnerId: string, yearMonth: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetPaymentLearner(companyId, learnerId, yearMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {number} paymentId 
     * @param {string} learnerId 
     * @param {number} searchStatus 
     * @param {string} searchField 
     * @param {string} searchStr 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetPaymentLearnerList(companyId: string, paymentId: number, learnerId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetPaymentLearnerList(companyId, paymentId, learnerId, searchStatus, searchField, searchStr, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} paymentId 
     * @param {string} yearMonth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetReportStudyRecords(companyId: string, paymentId: string, yearMonth: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetReportStudyRecords(companyId, paymentId, yearMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} learnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetStudyRecords(companyId: string, learnerId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetStudyRecords(companyId, learnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} learnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetSystemLearnerNotice(learnerId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetSystemLearnerNotice(learnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} learnerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetTargetMonthPaymentCompanyList(companyId: string, learnerId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetTargetMonthPaymentCompanyList(companyId, learnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetTeacherByTeacherId(teacherId: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetTeacherByTeacherId(teacherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} teacherId 
     * @param {string} yearMonth 
     * @param {string} endPeriod 
     * @param {string} searchField 
     * @param {string} searchStr 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetTeacherLessonFee(companyId: string, teacherId: string, yearMonth: string, endPeriod: string, searchField: string, searchStr: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetTeacherLessonFee(companyId, teacherId, yearMonth, endPeriod, searchField, searchStr, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherId 
     * @param {string} yearMonth 
     * @param {number} status 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetTeacherLessonFeeList(teacherId: string, yearMonth: string, status: number, limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetTeacherLessonFeeList(teacherId, yearMonth, status, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherId 
     * @param {number} searchStatus 
     * @param {string} searchField 
     * @param {string} searchStr 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetTeacherUserList(teacherId: string, searchStatus: number, searchField: string, searchStr: string, limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetTeacherUserList(teacherId, searchStatus, searchField, searchStr, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} identity 
     * @param {string} id 
     * @param {string} roomName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerGetTwilioAccessToken(companyId: string, identity: string, id: string, roomName: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerGetTwilioAccessToken(companyId, identity, id, roomName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} roomId 
     * @param {CreateRoomChatInput} createRoomChatInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerPutCompanyRoomChat(companyId: string, roomId: string, createRoomChatInput: CreateRoomChatInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerPutCompanyRoomChat(companyId, roomId, createRoomChatInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} releaseFlg 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerReleaseNotice(id: string, releaseFlg: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerReleaseNotice(id, releaseFlg, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mailSubject 
     * @param {string} mailerId 
     * @param {string} mailerName 
     * @param {string} kinds 
     * @param {string} mailBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerSendInquiryEmail(mailSubject: string, mailerId: string, mailerName: string, kinds: string, mailBody: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerSendInquiryEmail(mailSubject, mailerId, mailerName, kinds, mailBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} sendQueryEmail 
     * @param {string} startPeriod 
     * @param {string} endPeriod 
     * @param {string} searchField 
     * @param {string} searchStr 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerSendQueryEmail(companyId: string, sendQueryEmail: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerSendQueryEmail(companyId, sendQueryEmail, startPeriod, endPeriod, searchField, searchStr, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {UpdateAgreementInput} updateAgreementInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerUpdateAgreement(companyId: string, updateAgreementInput: UpdateAgreementInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerUpdateAgreement(companyId, updateAgreementInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} paymentId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerUpdateAgreementPlan(companyId: string, paymentId: string, body: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerUpdateAgreementPlan(companyId, paymentId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherId 
     * @param {string} yearMonth 
     * @param {number} billedFlg 
     * @param {number} finalFee 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerUpdateBillingStatus(teacherId: string, yearMonth: string, billedFlg: number, finalFee: number, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerUpdateBillingStatus(teacherId, yearMonth, billedFlg, finalFee, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {UpdateCompanyInput} updateCompanyInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerUpdateCompany(companyId: string, updateCompanyInput: UpdateCompanyInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerUpdateCompany(companyId, updateCompanyInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} maintenanceId 
     * @param {boolean} immediateFlg 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerUpdateDeMaintenance(maintenanceId: number, immediateFlg: boolean, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerUpdateDeMaintenance(maintenanceId, immediateFlg, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} learnerId 
     * @param {UpdateLearnerInput} updateLearnerInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerUpdateLearnerUser(companyId: string, learnerId: string, updateLearnerInput: UpdateLearnerInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerUpdateLearnerUser(companyId, learnerId, updateLearnerInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} lessonId 
     * @param {string} startPeriod 
     * @param {string} endPeriod 
     * @param {string} searchField 
     * @param {string} searchStr 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerUpdateLesson(companyId: string, lessonId: string, startPeriod: string, endPeriod: string, searchField: string, searchStr: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerUpdateLesson(companyId, lessonId, startPeriod, endPeriod, searchField, searchStr, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} lessonId 
     * @param {string} learnerId 
     * @param {UpdateLessonFeedbackInput} updateLessonFeedbackInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerUpdateLessonFeedback(companyId: string, lessonId: string, learnerId: string, updateLessonFeedbackInput: UpdateLessonFeedbackInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerUpdateLessonFeedback(companyId, lessonId, learnerId, updateLessonFeedbackInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} lessonId 
     * @param {string} learnerId 
     * @param {boolean} lessonLearnerStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerUpdateLessonLearnerStatus(companyId: string, lessonId: string, learnerId: string, lessonLearnerStatus: boolean, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerUpdateLessonLearnerStatus(companyId, lessonId, learnerId, lessonLearnerStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} lessonId 
     * @param {string} note 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerUpdateLessonNote(companyId: string, lessonId: string, note: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerUpdateLessonNote(companyId, lessonId, note, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} lessonId 
     * @param {string} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerUpdateLessonStatusTeacher(companyId: string, lessonId: string, status: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerUpdateLessonStatusTeacher(companyId, lessonId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} maintenanceId 
     * @param {CreateMaintenanceInput} createMaintenanceInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerUpdateMaintenance(maintenanceId: number, createMaintenanceInput: CreateMaintenanceInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerUpdateMaintenance(maintenanceId, createMaintenanceInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CreateNoticeInput} createNoticeInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerUpdateNotice(id: string, createNoticeInput: CreateNoticeInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerUpdateNotice(id, createNoticeInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} noticeId 
     * @param {boolean} readStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerUpdateSystemLearnerNoticeReadStatus(noticeId: string, readStatus: boolean, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerUpdateSystemLearnerNoticeReadStatus(noticeId, readStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} teacherId 
     * @param {ConnectionId} connectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerUpdateTeacherConnectionId(companyId: string, teacherId: string, connectionId: ConnectionId, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerUpdateTeacherConnectionId(companyId, teacherId, connectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherId 
     * @param {UpdateTeacherInput} updateTeacherInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerUpdateTeacherUser(teacherId: string, updateTeacherInput: UpdateTeacherInput, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerUpdateTeacherUser(teacherId, updateTeacherInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} lessonId 
     * @param {string} theme 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerUpdateThemeOnLesson(companyId: string, lessonId: string, theme: string, options?: any) {
        return APIApiFp(this.configuration).organizationsControllerUpdateThemeOnLesson(companyId, lessonId, theme, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public organizationsControllerVerifyCognitoUserEmail(options?: any) {
        return APIApiFp(this.configuration).organizationsControllerVerifyCognitoUserEmail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public sandboxControllerCognitoSandbox(options?: any) {
        return APIApiFp(this.configuration).sandboxControllerCognitoSandbox(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public sandboxControllerMailSandbox(options?: any) {
        return APIApiFp(this.configuration).sandboxControllerMailSandbox(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public teacherControllerGetMostRecentScheduledLesson(teacherId: string, options?: any) {
        return APIApiFp(this.configuration).teacherControllerGetMostRecentScheduledLesson(teacherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherId 
     * @param {number} limit 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public teacherControllerGetNotUpdatedNoteLessons(teacherId: string, limit: number, page: number, options?: any) {
        return APIApiFp(this.configuration).teacherControllerGetNotUpdatedNoteLessons(teacherId, limit, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} teacherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public teacherControllerGetUnaprovedLessons(teacherId: string, options?: any) {
        return APIApiFp(this.configuration).teacherControllerGetUnaprovedLessons(teacherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoggingInput} loggingInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public userEventControllerLoggingEvent(loggingInput: LoggingInput, options?: any) {
        return APIApiFp(this.configuration).userEventControllerLoggingEvent(loggingInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoggingInput} loggingInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    public userEventControllerLoggingEventByNotAuth(loggingInput: LoggingInput, options?: any) {
        return APIApiFp(this.configuration).userEventControllerLoggingEventByNotAuth(loggingInput, options).then((request) => request(this.axios, this.basePath));
    }
}


